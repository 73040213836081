import { SwitchLayout } from "../custom/SwitchLayout"
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import useConnectionMode from "../../hooks/useConnectionMode";
import { Autocomplete } from "../fields";
import { useGetLocationsQuery } from "../../services/dashboardService";
import { FieldArray } from "formik";
import { Text } from '../custom/text';
import { Card, Divider } from "antd";
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';

export const FloorMapping = ({ formik }) => {

    const { multipleFloor, floorList } = useSelector(state => state.dashboard)
    const { splitRevenueRecordByFloor } = formik?.values;
    const portal = useSelector(state => state.auth.portal)

    const { t } = useTranslation()
    const [name, swName] = useConnectionMode()
    const isQbo = name === "Online"

    const { data: classList, isFetching: fetchingClass, isLoading: loadingClass } = useGetLocationsQuery({ class: true }, { skip: !splitRevenueRecordByFloor ?? true })
    const { data: locationsList, isFetching: fetchingLocation, isLoading: loadingLocation } = useGetLocationsQuery({ class: false }, { skip: !splitRevenueRecordByFloor ?? true })

    const isLoading = fetchingClass || fetchingLocation || loadingClass || loadingLocation

    if (!multipleFloor) {
        return <></>
    }

    return (
        <>
            <SwitchLayout
                heading={t('Class And Floors')}
                description={t('floordiscription', { swName })}
                switchFieldName="splitRevenueRecordByFloor"
                isSwitchOn={splitRevenueRecordByFloor}
                isLoading={isLoading}
            >
                <FieldArray name="floorMapping">
                    {
                        array => {
                            const width = `${100 / 3}%`
                            return (
                                <Card>
                                    <>
                                        <>
                                            <Flex width={"100%"} mb=".6rem">
                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                    <Text fontWeight="bold">{`${portal} Floors`}</Text>
                                                </Box>
                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                    <Text fontWeight="bold">Select a location</Text>
                                                </Box>
                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                    <Text fontWeight="bold">Select a class</Text>
                                                </Box>
                                            </Flex>
                                            <Divider />
                                        </>
                                        <>
                                            {
                                                floorList?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <Flex alignItems={"center"} width={"100%"} mb=".5rem">
                                                                <Box alignItems={"center"} width={width} pl="0.8rem" pr="0.8rem">
                                                                    <Text>{row?.label}</Text>
                                                                </Box>
                                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                                    <Autocomplete
                                                                        name={`floorMapping[${index}].location`}
                                                                        formik={formik}
                                                                        options={locationsList}
                                                                        placeholder={"Select location"}
                                                                    />
                                                                </Box>
                                                                <Box width={width} pl="0.8rem" pr="0.8rem">
                                                                    <Autocomplete
                                                                        name={`floorMapping[${index}].class`}
                                                                        formik={formik}
                                                                        options={classList}
                                                                        placeholder={"Select class"}
                                                                    />
                                                                </Box>
                                                            </Flex>
                                                            <Divider />
                                                        </>
                                                    )
                                                })
                                            }
                                        </>
                                    </>
                                </Card>
                            )
                        }
                    }
                </FieldArray>
                <Flex mt="1rem" ml=".2rem">
                    <><Text fontWeight={"bold"} mr=".3rem">Required : </Text> {isQbo && "Either Location mapping or"} Class Mapping.</>
                </Flex>
            </SwitchLayout>
        </>
    )
}