import React from "react"
import { Card, Skeleton } from 'antd';
import { Flex } from '@react-css/flex';

export const RowLoader = ({ rows = 5, box = false }) => {

    let Comp = React.Fragment

    if (box) {
        Comp = Card
    }

    return (
        <>
            <Flex flexDirection={"column"}>
                <Comp bordered={false} style={{ padding: "10px", paddingTop: "0px", boxShadow: "none" }}>
                    {
                        [...Array(rows).keys()]?.map(() => (
                            <Flex style={{ padding: ".5rem" }} justifyContent="space-between">
                                <Flex width={"50%"}  style={{ padding: "0 .8rem" }} justifyContent={"center"}>
                                    <Skeleton.Input style={{ height: "1.5rem", width: "15rem" }} />
                                </Flex>
                                <Flex width={"50%"}  style={{ padding: "0 .8rem" }} justifyContent={"center"}>
                                    <Skeleton.Input style={{ height: "1.5rem", width: "15rem" }} />
                                </Flex>
                            </Flex>
                        ))
                    }
                </Comp>
            </Flex>
        </>
    )
}