import { Autocomplete } from '../fields';
import { LabelRequired } from '../custom/LabelRequired';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { Text } from '../custom/text';
import { Card } from 'antd';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';

export const MapDefaultTax = () => {
  const [, softwareName] = useConnectionMode();
  const [isLoadingAccounts, accountsList] = useAccounts('allAccounts');
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <Card type="inner" title={
    <Text fontSize={"large"} fontWeight="bolder">Tax Rates</Text>
  } style={{ marginBottom: "1rem" }}>
    <Text color="gray-600" size="small">
        Default tax rates must be mapped to a tax code or a G/L account in {softwareName}. Ensure that Sales Tax is enabled in {softwareName}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultTaxAccount">
          Default tax account
        </LabelRequired>
        <Autocomplete
          id="defaultTaxAccount"
          name="defaultTaxAccount"
          isLoading={isLoadingAccounts}
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter tax account' : 'Select an account')
          }
        />
      </Box>
    </Flex>
  </Card>;
};