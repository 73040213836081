import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { Text } from '../custom/text';
import { Card } from 'antd';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import amIVisible from '../../componentControl/registry';

export const InventoryAccount = ({ formik }) => {
    const [isLoading, accountsList] = useAccounts('inventory');
    const [, inventoryCogs] = useAccounts('inventoryCogs');
    const { t } = useTranslation();
    const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

    let listOfAccounts = accountsList

    if (amIVisible("show expense account in inventory")) {
      listOfAccounts = inventoryCogs
    }

    const { POInventoryCogs } = formik.values;

    if (POInventoryCogs) {
        return (
            <></>
        )
    }

    return <Card type="inner" title={
        <Text fontSize={"large"} fontWeight="bolder">Inventory Account</Text>
    } style={{ marginTop: "1rem", marginLeft: "1rem" }}>
        <Text color="gray-600" mr="3.25rem" size="small">
            Select a default inventory account. All unassigned and new categories and products will be assigned to this default account.
        </Text>
        <Flex>
            <Box width="49%" pr="1.1rem">
                <LabelRequired htmlFor="Tips">
                    {
                        t(isInputsOnlyLayout ? 'Enter Inventory Account account' : 'Select a Inventory Account account')
                    }
                </LabelRequired>
                <Autocomplete
                    isLoadingOptions={isLoading}
                    id="defaultInventoryAccountName"
                    name="defaultInventoryAccountName"
                    options={listOfAccounts}
                    placeholder={
                        t(isInputsOnlyLayout ? 'Enter Inventory Account account' : 'Select an account')
                    }
                />
            </Box>
        </Flex>
    </Card>;
};
