export const Box = ({
    children = "",
    color,
    link,
    p = "",
    pb = "",
    pl = "",
    pr = "",
    pt = "",
    m = "",
    mt = "",
    mb = "",
    ml = "",
    mr = "",
    width = "auto",
    height = "auto",
    style,
    onClick
}) => {

    let McStyle = {
        color,
        width,
        height,
        padding: p,
        margin: m,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        ...style
    }

    return <div onClick={onClick} style={{ ...McStyle }} >{children}</div>
}