import { Card } from 'antd';
import { SwitchField } from '../fields';
import { Text } from './text';

export const SwitchLayout = ({ children, heading, isSwitchOn, description, switchFieldName, isLoading, noLeftMargin }) => {
  return <>
    <Card
      type="inner"  
      title={<Text fontSize={"large"} fontWeight="bolder">{heading}</Text>}
      extra={
        <SwitchField id={switchFieldName} name={switchFieldName} disabled={isLoading} />
      }
      style={{ marginLeft: noLeftMargin ? 0 : "1rem", marginTop: "1.125rem" }}
      bodyStyle={{ display: (!description && !isSwitchOn) ? "none" : "" }}
    >
      {description && <Text color="gray-600" mr="3.25rem"
        size="small"
      >
        {description}
      </Text>}
      {(isSwitchOn === true) && <>{children}</>}
    </Card>
  </>;
};