import './text.css'
import { Flex } from '@react-css/flex';
import { Typography } from 'antd';

export const Text = ({
    fontSize = "x-small",
    fontWeight = "",
    children = "",
    color,
    link,
    p = "",
    pb = "",
    pl = "",
    pr = "",
    pt = "",
    m = "",
    mt = "",
    mb = "",
    ml = "",
    mr = "",
    style,
    onClick
}) => { 

    let McStyle = {
        color,
        padding: p,
        margin: m,
        paddingTop: pt,
        paddingBottom: pb,
        paddingLeft: pl,
        paddingRight: pr,
        marginTop: mt,
        marginBottom: mb,
        marginLeft: ml,
        marginRight: mr,
        ...style
    }

    return (
        <p
            className={`mc-text mc-text-${fontSize} mc-text-${fontWeight} mc-text-${link ? "link" : ''}`}
            style={McStyle}
            onClick={onClick}
        >
            <Flex>
                <Flex.Item>
                    {children}
                </Flex.Item>
            </Flex>
        </p>
    )
}

export const McHeading = (props) => {

    const { Title } = Typography

    return (
        <Title {...props} className="mcHeading">{props?.children ?? ""}</Title>
    )
}