import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../hooks/useAccounts';
import useConnectionMode from '../../hooks/useConnectionMode';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';

export const OverShort = ({  formik }) => {
  const [isLoading, accountsList] = useAccounts('overShort');
  const {
    isSentOverShort
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <SwitchLayout
    heading={t('OverShort')}
    description={t('OverShortDesc', { swName })}
    switchFieldName="isSentOverShort"
    isSwitchOn={isSentOverShort}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultOverShortAccountName">
          {t('OverShortInputLabel')}
        </LabelRequired>
        <Autocomplete
          id="defaultOverShortAccountName"
          name="defaultOverShortAccountName"
          isLoadingOptions={isLoading}
          options={accountsList}
          placeholder={isInputsOnlyLayout ? "Enter an account" : "Select an account"}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};