import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete, SwitchField } from '../fields';
import { useTranslation } from 'react-i18next';
import useAppSubmitting from '../../hooks/useAppSubmitting';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { Text } from '../custom/text';
import { Card, Skeleton } from 'antd';
import { Flex } from '@react-css/flex';
import { useNotification } from '../../utils/helpers/notifications';
import { useDispatch } from 'react-redux';
import { setCurrentComponent } from '../../slices/dashboardSlice';

export const MapDiscounts = ({ fetchedConfig, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const formik = props.formik;
  const [, softwareName] = useConnectionMode();
  const { showNotification } = useNotification();
  const [isLoadingAccounts, accountsList] = useAccounts('discount');
  const {
    isSentDiscount,
    defaultDiscountAccountName: defaultAccount
  } = formik.values;
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;

  const dispatch = useDispatch()


  const gotoPaymentMapping = () => {
    if (formik.dirty) {
      showNotification("Save the current changes to proceed", "error")
    } else {
      dispatch(setCurrentComponent("discount mapping"))
    }
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      showNotification(t('Please wait for data to save'), 'error');
    } else if (defaultAccount) {
      gotoPaymentMapping();
    } else {
      showNotification(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'SelectDiscountFirst'), 'error');
    }
  };

  return <Card type="inner" title={
    <Text fontSize={"large"} fontWeight="bolder">{t('Discounts')}</Text>
  } style={{ marginBottom: "1rem" }} extra={
    <Flex justifyContent="space-between">
      <SwitchField id="isSentDiscount" name="isSentDiscount" disabled={isLoading} />
    </Flex>
  }>
    <Text color="gray-600"
      mr="3.25rem"
      size="small"
    >
      {t('MapDiscountDescription', { swName: softwareName })}
    </Text>
    {isSentDiscount &&
      <Flex>
        <div style={{ width: "49%", paddingRight: "1.1rem", marginTop: ".5rem" }}>
          <LabelRequired htmlFor="defaultDiscountAccountName">
            {t('Default Discount Account')}
          </LabelRequired>
          <Autocomplete
            id="defaultDiscountAccountName"
            name="defaultDiscountAccountName"
            isLoading={isLoading}
            options={accountsList}
            placeholder={
              t(isInputsOnlyLayout ? 'Enter default account' : 'Select an account')
            }
          />
        </div>
        {amIVisible("discount individual") && <Flex alignItems="center" style={{ marginTop: "1.3rem" }} >
          {isLoading ?
            <Skeleton.Input size="small" /> :
            <Text link size="small" fontWeight="bolder"
              onClick={handleNavigation}
            >
              {t('Map individual discounts')}
            </Text>
          }
        </Flex>}
      </Flex>
    }
  </Card>;
};