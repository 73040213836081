import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import { Text } from '../custom/text';
import { Card } from 'antd';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';

export const Alteration = () => {
  const [, softwareName] = useConnectionMode();
  const [isLoading, accountsList] = useAccounts('bagFee');
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <Card type="inner"   title={
    <Text fontSize={"large"} fontWeight="bolder">Alteration</Text>
  } style={{ marginBottom: "1rem" }}>
    <Text color="gray-600" mr="3.25rem" size="small">
      Alteration can be mapped to an account in {softwareName}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired>
          {
            t(isInputsOnlyLayout ? 'Enter Alteration account' : 'Select a Alteration account')
          } 
        </LabelRequired>
        <Autocomplete
          isLoadingOptions={isLoading}
          id="alterationAccount"
          name="alterationAccount"
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter Alteration account' : 'Select an account')
          }
        />
      </Box>
    </Flex>
  </Card>;
};
