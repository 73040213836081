import React from 'react';
import { useTranslation } from 'react-i18next';
import { Card } from 'antd';
import { Text } from '../custom/text';
import { Dropdown } from '../fields/dropdown';

export const SplitPayment = ({ formik }) => {
    const { t } = useTranslation();
    
    const options = [
        {
            value: "0",
            label: "No"
        },
        {
            value: "1",
            label: "Yes"
        }
    ]

    const handleOnChange = (value) => {
        formik.setFieldValue('PaymentMethodTypeBreakdown', value)
    }

    return (
        <>
            {
                <Card type="inner"   title={
                    <Text fontSize={"large"} fontWeight="bolder">{t('SplitCardPayment')}</Text>
                } style={{ marginBottom: "1rem" }}>
                    <Dropdown
                        id={"PaymentMethodTypeBreakdown"}
                        name={"PaymentMethodTypeBreakdown"}
                        options={options}
                        allowClear={false}
                        onChange={handleOnChange}
                    />
                </Card>
            }
        </>
    )
}