import { useDispatch } from 'react-redux';
import { MdRefresh } from "react-icons/md/index"
import { setRefetchAccount } from '../../slices/dashboardSlice';
import { useSelector } from 'react-redux';
import { Button } from 'antd';
import { useNotification } from '../../utils/helpers/notifications';

export const RefreshAccountsSkeleton = ({ handleClick }) => {
  const { isRefreshAccount } = useSelector(state => state.dashboard)

  return <Button style={{ margin: "10px 0" }} loading={isRefreshAccount} className='btn-with-icon'  onClick={handleClick}>
    <MdRefresh className="mc-btn-icon" />
    Refresh Accounts
  </Button>;
};

export const RefreshAccounts = ({ dirty: isDirty = false }) => {
  const dispatch = useDispatch();

  const { showNotification } = useNotification()

  const handleClick = () => {
    if (!isDirty) {
      dispatch(setRefetchAccount(true))
    } else {
      showNotification("Save current changes to refresh the accounts.", 'error')
    }
  };

  return <RefreshAccountsSkeleton handleClick={handleClick} />;
};
