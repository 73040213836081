import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import { useState } from 'react';
import { useEffect } from 'react';
import useAccounts from '../../hooks/useAccounts';
import { useGetCategoriesQuery } from '../../services/dashboardService';
import { FullPageWrapper } from '../custom/IndividualMappingLayout';
import amIVisible from '../../componentControl/registry';
import radioOptions from "./productLevelOptions.json"

export const IndividualProductIDsMapping = ({ formik }) => {

    const { t } = useTranslation()

    const [isLoadingAccounts, accountsList] = useAccounts('items');

    const { currentSw, itemsLevelLabels } = useSelector(state => state.dashboard)

    const { itemsLevel, isParentCategory } = formik.values;

    const [parentCategory, setCategory] = useState(isParentCategory);

    const [isCategoryChanged, setChanged] = useState(false);

    const isDynamicMapping = amIVisible("enter field for product and categories individual")

    const [isEmptyLabels, setIfEmptyLabels] = useState(false)

    const { isLoading, isFetching, refetch } = useGetCategoriesQuery({
        recordItems: false,
        productLevel: true,
        superParentCategory: Number(parentCategory)
    }, {
        refetchOnMountOrArgChange: false,
        selectFromResult: ({ data, ...rest }) => {
            let labels = data?.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            labels = _.filter(labels, item => {
                return !_.keys(_.pickBy(itemsLevel, (items) => items !== '')).includes(item?.value + '')
            })

            return {
                data: labels,
                ...rest
            }
        }
    })

    
    useEffect(() => {
        setIfEmptyLabels(itemsLevelLabels?.length === 0)
    }, [itemsLevelLabels])

    useEffect(() => {

        const labels = itemsLevelLabels ?? []

        if (labels?.length === 0 && !isEmptyLabels) {
            refetch()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsLevelLabels])

    useEffect(() => {
        if (isCategoryChanged) {
            setChanged(false)
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCategoryChanged])

    const refresh = (value) => {
        setCategory(value)
        setChanged(true)
    }

    return (
        <>
            <FullPageWrapper
                title={"Map Type/sub-type"}
                fullPageDesp={t(`Individual Type/sub-type can be mapped to unique accounts in ${currentSw}. All unassigned and new categories will be assigned to the default category account.`, { swName: currentSw })}
                formik={formik}
                formikFieldName={"itemsLevelMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Type/sub-type"}
                parentCategory={parentCategory}
                isDynamicMapping={isDynamicMapping}
                options={accountsList}
                labelOption={itemsLevelLabels}
                radioOptions={radioOptions}
                refetch={refresh}
                advanceMapping
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}