import { useTranslation } from 'react-i18next';
import { Tag } from 'antd';
import { Tooltip } from 'antd';

export const SyncStatusBadge = ({ type: typeRaw }) => {
  const { t } = useTranslation();

  let type = 3;
  if ([1, true].includes(typeRaw)) {
    type = 1;
  } else if ([0, false].includes(typeRaw)) {
    type = 0;
  } else if (typeRaw === 2) {
    type = 2;
  } else if (typeRaw === 4) {
    type = 4
  }
  
  const typeString = ['red', 'green', 'blue', 'grey', 'orange'][type];
  const label = [t('Failed'), t('Success'), t('Queued'), t('No Data'), t('CC')][type];
  return (
    <>
      {type === 4 ?
        <Tooltip title="Only Customer Credit transactions are available">
          <Tag color={typeString}>
            {label}
          </Tag>
        </Tooltip>
        :
        <Tag color={typeString}>
          {label}
        </Tag>
      }
    </>
  );
};