import { useSelector } from 'react-redux';
import { isAccountsLoading } from '../slices/dashboardSlice';
import { filterAccountsByKeywords } from '../utils/helpers/commonHelpers';
import useConnectionMode from './useConnectionMode';

const keywordsLists = {
  'Online': {
    items: ['income', 'liability', 'sales', 'revenue', 'liabilities'],
    pettyCash: ['asset', 'expense', 'income', 'liability', 'sales', 'revenue', 'liabilities'],
    discount: ['expense', 'income', 'sales', 'revenue'],
    giftCard: ['liability', 'liabilities', 'income'],
    payment: ['payments'],
    paymentLiability: ['liabilities', 'liability'],
    tips: ['liability', 'liabilities'],
    serviceCharge: ['liability', 'liabilities', 'income'],
    overShort: ['asset', 'expense', 'income', 'liability', 'sales', 'revenue', 'liabilities'],
    shipping: ['expense'],
    inventory: ['asset', 'inventory'],
    cogs: ['expense', "Cost of Goods Sold", "Direct Costs"],
    inventoryCogs: ['asset', 'inventory', 'expense', "Cost of Goods Sold", "Direct Costs"],
    adjustment: ['asset', 'expense'],
    bagFee: ['income', 'liability', 'liabilities'],
    allAccounts: ['current', 'payments', 'income', 'asset', 'inventory', 'liability', 'sales', 'revenue', 'liabilities', 'expense', "Cost of Goods Sold", "Direct Costs"]
  },
  'Xero': {
    items: ['Revenue', 'Current', 'Income', 'Sales', 'Other income'],
  },
};

const getKeywords = (accountsType, connectionMode) => {
  let keywords = keywordsLists[connectionMode] ?
    keywordsLists[connectionMode][accountsType]
    :
    keywordsLists['Online'][accountsType];

  if (!keywords) {
    keywords = keywordsLists['Online'][accountsType];
  }

  return keywords ? keywords.map(keyword => keyword.toLowerCase()) : [];
};

export const useAccounts = (accountsType) => {
  const entities = useSelector((state) => state.dashboard.accounts);
  const isJe = useSelector((state) => state.dashboard.currentPostingMethod) === "journalEntry";

  const isLoading = useSelector(isAccountsLoading);
  const [connectionMode] = useConnectionMode();
  const keywords = getKeywords(accountsType, connectionMode);

  let filteredAccounts = filterAccountsByKeywords(entities, keywords)

  let allAccountList = []

  // do filtering again when there are duplicate labels
  if (!isJe) {

    let accountLabels = []

    for (let i = 0; i < filteredAccounts?.length; i++) {
      if (!accountLabels?.includes(filteredAccounts[i]?.value)) {
        allAccountList?.push(filteredAccounts[i])
        accountLabels?.push(filteredAccounts[i]?.value)
      }
    }

  } else {
    let accountCodes = []
    allAccountList = []

    // do filtering using account code, find for duplicates
    for (let i = 0; i < entities?.length; i++) {
      if (!accountCodes?.includes(entities[i]?.id)) {
        allAccountList?.push(entities[i])
        Boolean(entities[i]?.id) && accountCodes?.push(entities[i]?.id)
      }
    }

    let accountLabels = []
    let filterByLabels = []

    // do filtering when account code is null or missing on data
    if (accountCodes?.length === 0) {
      for (let i = 0; i < allAccountList?.length; i++) {
        if (!accountLabels?.includes(allAccountList[i]?.value)) {
          filterByLabels?.push(allAccountList[i])
          accountLabels?.push(allAccountList[i]?.value)
        }
      }

      allAccountList = filterByLabels
    }

  }

  return [isLoading, allAccountList];
};

export default useAccounts;
