import { Alert, Spin, Tooltip } from "antd";
import useConnectionMode from "../../hooks/useConnectionMode";
import { Text } from '../custom/text';
import { useSelector, useDispatch } from 'react-redux';
import { Flex } from '@react-css/flex';
import { useNotification } from '../../utils/helpers/notifications';
import { setCurrentComponent, setRefetchSyncCount } from "../../slices/dashboardSlice";
import { BiRefresh } from "react-icons/bi";
import { BsTable } from "react-icons/bs";
import { colors } from "../../style/color";

const InfoSyncStatus = ({ formik }) => {

    const [, swName] = useConnectionMode()

    const { isSynced: isConnected, isFetchingSyncCount } = useSelector(state => state.dashboard)
    // const isPaused = fetchedConfig.syncEnabled ?? false
    const { syncSuccessCount, syncFailedCount } = useSelector(state => state.dashboard.syncCounts);
    const { showNotification } = useNotification()
    const dispatch = useDispatch()

    const handleNavigation = (comp) => {
        if (formik?.dirty) {
            showNotification("Save the current changes to proceed", "error")
        } else {
            dispatch(setCurrentComponent(comp))
        }
    }

    const unsuccessfulPosting = (
        <>
            <span style={{ fontWeight: "700", fontSize: "" }} pr=".3rem" pl=".3rem" fontWeight="bolder">{syncFailedCount}</span> Unsuccessful
        </>
    )

    const successfulPosting = (
        <>
            <span style={{ fontWeight: "700" }} fontWeight="bolder" pr=".3rem" pl=".3rem">{syncSuccessCount}</span> Successful
        </>
    )

    const SyncSuccess = () => (
        <>
            <Alert style={{ height: "240px", backgroundColor: colors?.alertGreen }} type='success' showIcon message={
                <Flex>
                    <Text fontWeight="bolder" mt="1px">Sync Success</Text>
                    <Flex mb="5px" pl="5px">
                        {isFetchingSyncCount ? <Spin spinning size="small" style={{ paddingTop: "4px", paddingLeft: "8px" }} /> :
                            <Tooltip title="Refresh Sync Counts" placement="right">
                                <BiRefresh style={{ marginLeft: "4px", marginTop: "3px", cursor: 'pointer' }} onClick={() => { dispatch(setRefetchSyncCount(true)) }} size={"22"} />
                            </Tooltip>
                        }
                    </Flex>
                </Flex>
            }
                description={
                    <>
                        <Flex style={{ display: "flex", marginTop: "20px" }}>
                            <Text>{syncFailedCount > 0 ? unsuccessfulPosting : successfulPosting} posting to {swName ?? ""}</Text>
                        </Flex>
                        <SyncLinks />
                    </>
                }
            />
        </>
    )

    // const SyncPaused = () => (
    //     <>
    //         <Alert style={{ height: "240px", backgroundColor: colors?.alertYellow }} type='warning' showIcon message={<Text fontWeight="bolder" mt="1px">Sync Paused</Text>}
    //             description={
    //                 <>
    //                     <div style={{ display: "flex", marginTop: "20px" }}>
    //                         <div style={{ marginTop: "1px" }} /><Text fontWeight="bold">Automatic syncing has been paused. Change the "Sync schedule" to resume.</Text>
    //                     </div>
    //                     <SyncLinks />
    //                 </>
    //             }
    //         />
    //     </>
    // )

    const SyncError = () => (
        <>
            <Alert style={{ height: "260px", backgroundColor: colors?.alertRed }} type='error' showIcon message={
                <Flex>
                    <Text fontWeight="bolder" mt="1px">Sync Error</Text>
                    <Flex mb="5px" pl="5px" pb="5px">
                        {isFetchingSyncCount ? <Spin spinning size="small" style={{ paddingTop: "4px", paddingLeft: "8px" }} /> :
                            <Tooltip title="Refresh Sync Counts" placement="right">
                                <BiRefresh style={{ marginLeft: "4px", marginTop: "3px", cursor: 'pointer' }} onClick={() => { dispatch(setRefetchSyncCount(true)) }} size={"22"} />
                            </Tooltip>
                        }
                    </Flex>
                </Flex>
            }
                description={
                    <>
                        <div style={{ display: "flex", marginTop: "20px" }}>
                            <Text>{syncFailedCount > 0 ? unsuccessfulPosting : successfulPosting} posting to {swName ?? ""}</Text>
                        </div>
                        <SyncLinks />
                    </>
                }
            />
        </>
    )

    const SyncLinks = () => (
        <>
            <div className="info-sync-links">
                <div style={{ display: 'flex' }} onClick={() => handleNavigation("sync history")}>
                    <BsTable style={{ marginRight: "0.35rem", marginTop: "4px", color: colors.primary }} />
                    <Text link fontWeight="bolder">View Sync History</Text>
                </div>
                <div style={{ display: 'flex', marginTop: "8px" }} onClick={() => handleNavigation("po sync history")}>
                    <BsTable style={{ marginRight: "0.35rem", marginTop: "4px", color: colors.primary }} />
                    <Text link fontWeight="bolder">View PO Sync History</Text>
                </div>
            </div>
        </>
    )

    return (
        <>
            {
                (isConnected && (syncFailedCount ?? 0) === 0) ?
                    <>
                        <SyncSuccess />
                        {/* {isPaused ?
                            :
                            <SyncPaused /> */}
                    </>
                    :
                    <SyncError />
            }
        </>
    )
}

export default InfoSyncStatus;