import { useState } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import { useLocationData } from "../../hooks/useLocation";
import useRole from '../../hooks/useRole';
import { useLocale } from '../../hooks/useLocale';
import { useHistoricalSyncMutation } from '../../services/syncService';
import { useNotification } from '../../utils/helpers/notifications';
import { Text } from '../custom/text';
import { Button, Card, DatePicker } from 'antd';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import { FaHistory } from "react-icons/fa"
import moment from 'moment/moment';

const toDateString = (locale, dateObj) => {
  return dateObj?.toISOString()?.slice(0, 10);
};

const createPayload = (startDate, endDate, userType, locale, isTypePo) => {
  return {
    startDate: toDateString(locale, startDate),
    endDate: toDateString(locale, endDate),
    userType,
    syncPO: isTypePo,
  }
};

export const HistoricalSync = ({ isTypePo }) => {
  const [endDate, setEndDate] = useState(new Date(new Date().getTime() - 24 * 60 * 60 * 1000));
  const [isLoading, setIsLoading] = useState(false);
  const [userRole] = useRole();
  const locale = useLocale();
  const { currentLocation } = useLocationData()
  const [startDate, setStartDate] = useState(moment());
  const [startHistoricalSync] = useHistoricalSyncMutation()
  const { showNotification } = useNotification()

  const startSync = () => {
    setIsLoading(true);

    const payload = createPayload(startDate, endDate, userRole.type, locale, isTypePo);

    startHistoricalSync(payload).unwrap().then(() => {
      showNotification("Request has been queued up for processing.", "success")
    }).catch((err) => {
      if (err?.data?.error?.key === "incompleteConfiguration") {
        showNotification("Complete Configuration First", "error")
      } else if (err?.data?.error?.key === "NoDataToQueue") {
        showNotification(`No EOD performed in the specific date range from ${new Date(startDate)?.toISOString()?.split('T')[0]} to ${new Date(endDate)?.toISOString()?.split('T')[0]}`, "error")
      } else if (err?.data?.error?.key === "PONotEnabled") {
        showNotification("Enable PO To Sync", "error")
      } else {
        showNotification(err?.data?.error?.key ?? "Something went wrong", "error")
      }
    }).then(() => {
      setIsLoading(false);
    });
  };

  const validateBeforeSync = () => {
    let isValid = false;

    if (startDate.$y < endDate.$y) {
      isValid = true;
    } else if (startDate.getFullYear() === endDate.getFullYear()) {
      if (startDate.getMonth() < endDate.getMonth()) {
        isValid = true;
      } else if (startDate.getMonth() === endDate.getMonth()) {
        isValid = startDate.getDate() <= endDate.getDate();
      }
    }

    if (isValid) {
      startSync();
    } else {
      showNotification('Invalid date range', 'error');
    }
  };

  return <Card type="inner" title={
    <Flex alignItems={"center"}>
      <FaHistory size={18} style={{}} />
      <Text fontWeight='bolder' pl="6px" fontSize='large'>Historical Sync</Text>
    </Flex>
  } style={{ marginBottom: "2rem" }}>
    <>
      <Flex alignItems="flex-end">
        <Box>
          <Text mb=".2rem" fontWeight='bolder'>Start</Text>
          <DatePicker dateFormat={"yyyy-MM-dd"} onChange={date => setStartDate(date?.toDate())} disabledDate={(current) => {
            return current && (current < moment(currentLocation?.appInstalledOn, "YYYY-MM-DD") || current > moment());
          }} />
        </Box>
        <Box ml="1rem" mr="1rem">
          <Text mb=".2rem" fontWeight='bolder'>End</Text>
          <DatePicker dateFormat={"yyyy-MM-dd"} onChange={date => setEndDate(date?.toDate())} disabledDate={(current) => {
            return current && (current < moment(currentLocation?.appInstalledOn, "YYYY-MM-DD") || current > moment());
          }} />
        </Box>
        <Button
          loading={isLoading}
          type="primary"
          ml="1rem"
          onClick={validateBeforeSync}
        >
          Start Sync
        </Button>
      </Flex>
    </>
  </Card>;
};
