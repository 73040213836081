import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { Autocomplete } from '../fields';
import useConnectionMode from '../../hooks/useConnectionMode';
import { useGetPoTaxesQuery } from '../../services/dashboardService';
import { useEffect } from 'react';
import { RowLoader } from '../custom/rowLoader';
import { Text } from '../custom/text';
import amIVisible from '../../componentControl/registry';
import DynamicFields from '../custom/DynamicFields';
import { Card, Divider } from 'antd';
import { Flex } from '@react-css/flex';
import { TbTrash } from 'react-icons/tb';
import useAccounts from '../../hooks/useAccounts';

export const PoTaxMapping = (props) => {

    const { formik } = props

    const { poTaxes: taxes, isPoTaxFetching, fetchedConfig, isPostingMethodChanged, currentPostingMethod } = useSelector(state => state.dashboard)

    const { isLoading, isFetching, isSuccess } = useGetPoTaxesQuery({ journalEntry: currentPostingMethod === 'journalEntry' }, { refetchOnMountOrArgChange: true })

    const [, swName] = useConnectionMode()

    const [, accountsList] = useAccounts('allAccounts');

    let accounts = currentPostingMethod === 'journalEntry' ? accountsList : taxes?.taxAccounts

    useEffect(() => {
        if ((taxes?.taxLabels?.length > 0 && !fetchedConfig.POInventoryCogs) || isPostingMethodChanged) {
            formik.setFieldValue('poTaxMapping', taxes?.taxLabels)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPostingMethodChanged, taxes, isSuccess])

    const { poTaxMapping: taxMapping } = formik?.values

    const isDynamic = amIVisible("dynamic po taxes")

    return (
        <div style={{ marginBottom: "1rem" }}>
            <Text color="gray-600" mb="1.125rem" mt="1.75rem">
                {`Purchase tax rates can be mapped to different ${swName} accounts.`}
            </Text>
            <FieldArray name="poTaxMapping">
                {
                    (array) => {
                        const width = `${100 / 2}%`
                        return (
                            <Card style={{ marginTop: "1rem" }} title={
                                <>
                                    <Flex>
                                        <Flex style={{ width: '60%' }}>
                                            <Text fontSize='medium' fontWeight="bolder">Purchase Tax Rate</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"} style={{ width: '40%' }}>
                                            <Text fontSize='medium' fontWeight="bolder">Tax Accounts</Text>
                                            <Text fontSize='medium' fontWeight="bold" color="grey">Required</Text>
                                        </Flex>
                                    </Flex>
                                </>
                            }>
                                <>
                                    {isPoTaxFetching ? <RowLoader box={false} rows={6} /> :
                                        <>
                                            {
                                                taxMapping?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <Flex alignItems={"center"} style={{ width: "100%" }}>
                                                                <div style={{ width, padding: "0.2rem", textAlign: "center" }}>
                                                                    <Text fontSize='medium' fontWeight="bolder">{row?.id}</Text>
                                                                </div>
                                                                <div style={{ width, padding: "0.2rem" }}>
                                                                        <Autocomplete
                                                                            name={`poTaxMapping[${index}].account`}
                                                                            formik={formik}
                                                                            options={accounts}
                                                                            placeholder={"Select Tax Account"}
                                                                            isLoading={isLoading || isFetching}
                                                                        />
                                                                </div>
                                                                {isDynamic && <TbTrash
                                                                    pointer="cursor"
                                                                    style={{ marginLeft: '0.75rem', fontSize: "18px", pointer: 'cursor', color: "red" }}
                                                                    onClick={() => {
                                                                        array.remove(index)
                                                                    }}
                                                                />}
                                                            </Flex>
                                                            <Divider style={{ margin: "12px 0" }} />
                                                        </>
                                                    )
                                                })
                                            }
                                            {isDynamic && <DynamicFields labels={taxMapping} title={"Purchase Tax Rate"} options={taxes?.taxAccounts} onFieldAdd={(id = '', account = '') => {
                                                formik.setFieldValue('poTaxMapping', [...taxMapping, { id, account }])
                                            }} />}
                                        </>}
                                </>
                            </Card>
                        )
                    }
                }
            </FieldArray>
        </div>
    )
}