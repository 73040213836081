import { useTranslation } from 'react-i18next';
import { Text } from './text';
import { Flex } from '@react-css/flex';
import { colors } from '../../style/color';

export const LabelRequired = ({ children, notRequired, ...props }) => {
  const { t } = useTranslation();

  return <div {...props}>
    <Flex
      flexDirection="row"
      justifyContent="space-between"
      alignItems="center"
      style={{ marginTop: ".8rem", width: "92%", marginBottom: ".3rem" }}
    >
      <Text size="large" fontWeight="bolder">{children}</Text>
      {!notRequired && <Text fontWeight="bold" style={{ color: colors.secondary}}>{t('Required')}</Text>}
    </Flex>
  </div>;
};
