import { parse as parseQuery } from 'query-string';
import { useAuthorizeAccountIdQuery } from '../services/authService';
import AuthLoading from './components/authLoading';
import { useState } from 'react';
import AuthCredMsg from './components/authCredMsg';
import AuthRetry from './components/authRetry';
import { useEffect } from 'react';
import { moveToNextScreen } from '../utils/helpers/commonHelpers';
import { useDispatch } from 'react-redux';

export const LSAuthorize = (props) => {

    const {
        path
    } = props

    const params = parseQuery(props.location.search);
    const [key] = useState(Math.random());
    const dispatch = useDispatch();

    let appToken = ''
    
    try {
        appToken = atob(params.accountId)
    } catch {
        appToken = params.accountId
    }

    let payloadParams = {
        type: params.type || '',
        appToken,
        token: params.token,
        source: params.source || 1,
        locationId: params.locationId || '',
        key
    }

    if (path === "authorize-new") {
        payloadParams = {
            prefix: 'company/',
            code: params?.code ?? '',
            state: params?.state ?? ''
        }
    }

    const { data, isSuccess, isLoading, isFetching, isError, error, refetch } = useAuthorizeAccountIdQuery(payloadParams, { refetchOnMountOrArgChange: true })
    let credentialsmsg = '', redirecturl = '';

    // TODO: handle errorAccessToken, Invalid cred
    if (error) {

    }

    useEffect(() => {
        if (isSuccess) {
            if (path === "authorize-new") {
                window.location = data?.serverUrl
            } else {
                moveToNextScreen(props.navigate, dispatch, '../dashboard', data)
            }
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    return (
        <>
            <div style={{
                display: "flex", alignItems: 'center', justifyContent: 'center', height: '100%',
                flexDirection: 'column'
            }}>
                {(isLoading || isFetching) && <AuthLoading />}
                {(credentialsmsg === 'Invalid Credentials' || credentialsmsg === 'errorAccessToken') ?
                    <>
                        {isError && <AuthCredMsg credentialsmsg={credentialsmsg} redirecturl={redirecturl} />}
                    </>
                    :
                    <>
                        {isError && <AuthRetry retry={refetch} />}
                    </>
                }
            </div>
        </>
    )
}