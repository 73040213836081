import { Alert, Card, Divider, Skeleton, Tooltip } from "antd";
import { useTranslation } from "react-i18next";
import { softwareImages } from '../../utils/helpers/softwareImages';
import useConnectionMode from "../../hooks/useConnectionMode";
import { PopupButton } from "react-calendly";
import { GoTools } from "react-icons/go";
import { Text } from "../custom/text";
import { useDispatch, useSelector } from "react-redux";
import useCompanyName from "../../hooks/useCompanyName";
import amIVisible from "../../componentControl/registry";
import { setIsPostingMethodChanged, setPostingTypeJEWarning } from "../../slices/dashboardSlice";
import { getStateOnChangePostingMethod } from "../../utils/helpers/dashboard";
import { resetAccounts } from "../../redux/reducer/accountsReducer";
import { Dropdown } from '../fields/dropdown';
import { LabelRequired } from "../custom/LabelRequired";
import { TextInput } from "../fields";
import InfoSyncStatus from "./InfoSyncStatus";
import { Flex } from '@react-css/flex';
import { ReconnectBtn } from "./dashComponents/ReconnectBtn";
import { DisconnectBtn } from "./dashComponents/DisconnectBtn";
import { PostingMethod } from "./dashComponents/PostingMethod";
import { Layout } from "../custom/Layout";
import { colors } from "../../style/color";
import { AiOutlineInfoCircle } from "react-icons/ai";

const InfoDashboard = (props) => {

    const {
        formik
    } = props

    const { t } = useTranslation();

    const [connectionMode, softwareName] = useConnectionMode()
    const [companyName] = useCompanyName();
    const dispatch = useDispatch()

    let { isGlobalLoading: isLoading, fetchedConfig, showPostingTypeJEWarning } = useSelector(state => state.dashboard)

    const canShowPostingType = amIVisible("Posting Type")
    const canShowSyncSchedule = amIVisible("Sync Schedule")
    const canShowEmail = amIVisible("Notification email")
    const canShowPhoneNo = amIVisible("Notification phone number")
    const [, sw] = useConnectionMode()
    const isQBD = (sw ?? "") === "QuickBooks Desktop"

    const postingType = [
        {
            value: 1,
            label: 'Individual invoice sync'
        },
        {
            value: 2,
            label: 'Batch end of day summary'
        },
    ]


    const syncSchedule = [
        {
            value: 0,
            label: 'Pause'
        },
        {
            value: 1,
            label: 'Sync daily'
        },
        {
            value: 2,
            label: 'Sync weekly on Mondays'
        },
        {
            value: 4,
            label: 'Sync weekly on Tuesdays'
        },
        {
            value: 5,
            label: 'Sync weekly on Wednesday'
        },
        {
            value: 6,
            label: 'Sync weekly on Thursdays'
        },
        {
            value: 7,
            label: 'Sync weekly on Fridays'
        },
        {
            value: 8,
            label: 'Sync weekly on Saturdays'
        },
        {
            value: 9,
            label: 'Sync weekly on Sundays'
        },
    ]

    let fieldName = ''
    let options = []

    if (canShowPostingType) {
        fieldName = 'dataTransferType'
        options = postingType
    } else if (canShowSyncSchedule) {
        fieldName = 'syncRange'
        options = syncSchedule
    }

    const handleOnChange = (value) => {
        if (value == "1" && formik?.values?.postingMethod === "journalEntry") {
            dispatch(setIsPostingMethodChanged(getStateOnChangePostingMethod(formik)))
            dispatch(resetAccounts());
            formik?.setFieldValue('postingMethod', "")
            formik?.setFieldValue('keepInvoice', false)

            const newState = getStateOnChangePostingMethod(formik);

            formik.resetForm(newState);
            formik?.setFieldValue('configType', 0)
            dispatch(setPostingTypeJEWarning(true))
        }

        formik?.setFieldValue('dataTransferType', value)
    }

    return (
        <>
            <Layout
                title={t('Accounting Software')}
                description={t('AccountingSoftwareDescription')}
            >
                <>
                    <Card className="info-dash" style={{ marginTop: "50px", paddingTop: "0 !important" }}>
                        <div className="logo-section">
                            {(connectionMode) ? <img src={softwareImages[connectionMode]} alt={softwareName}
                                className="acc-sw-logo"
                            /> : <Skeleton.Avatar active size={"large"} style={{ width: '100px', height: '100px', backgroundColor: "#ddddddf5" }}>loading</Skeleton.Avatar>}
                        </div>
                        <div className="schedule-btn-section">
                            {!isLoading ? <>
                                <GoTools />
                                <PopupButton
                                    text="Schedule time with Integration Developer to get help on setup"
                                    url="https://calendly.com/posaccounting/30min"
                                    rootElement={document.getElementById("root")}
                                    className="epos-chat"
                                />
                            </>
                                :
                                <Skeleton.Input style={{ marginTop: "20px", width: '350px', height: "10px" }} active></Skeleton.Input>
                            }
                        </div>
                        <div className="info-dash-companyName">
                            {!isLoading ? <Flex flexDirection="column">
                                {!isQBD && <Text fontSize="medium" fontWeight="bolder"> Company : <span style={{ color: colors.secondary }}>{companyName}</span></Text>}
                                <Text fontSize="medium" mt="3px" fontWeight="bolder"> Connected to : <span style={{ color: colors.secondary }}></span>{softwareName}</Text>
                                {isQBD && (
                                    <>
                                        <Text fontSize="medium" mt="3px" fontWeight="bolder"> Connector Time : <span style={{ color: colors.secondary }}>{fetchedConfig?.connectorLastRun ?? " "}</span></Text>
                                        {fetchedConfig?.filePath !== "" && <Tooltip className="filepath" placement="bottomLeft" title={
                                            <>
                                                <p fontWeight="bolder" color="white">{fetchedConfig?.filePath ?? ""}</p>
                                            </>
                                        }>
                                            <Flex>
                                                <Text fontSize="medium" mt="3px" fontWeight="bolder"> View File Path </Text>
                                                <AiOutlineInfoCircle style={{ fontSize: "16px", paddingTop: "6.5px", paddingLeft: "4px" }} />
                                            </Flex>
                                        </Tooltip>}
                                    </>
                                )}
                            </Flex> :
                                <Flex flexDirection="column">
                                    <Skeleton.Input active style={{ marginTop: "20px", width: "230px", height: "10px" }} />
                                    <Skeleton.Input active style={{ marginTop: "10px", width: "50px", height: "10px" }} />
                                </Flex>
                            }
                            <div className="info-dash-btns">
                                {!isLoading ? <>
                                    <ReconnectBtn {...props} />
                                    <DisconnectBtn {...props} />
                                </>
                                    : <>
                                        <Skeleton.Button active style={{ width: "120px", marginRight: "10px" }}></Skeleton.Button>
                                        <Skeleton.Button active style={{ width: "120px", }}></Skeleton.Button>
                                    </>

                                }
                            </div>
                        </div>
                        <div className="info-main-center">
                            <div className="info-dash-inputs">
                                <>
                                    <div>
                                        <Text fontWeight="bolder" mb=".5rem">Posting Type</Text>
                                        {!isLoading ?
                                            <Dropdown
                                                id={fieldName}
                                                name={fieldName}
                                                options={options}
                                                allowClear={false}
                                                onChange={handleOnChange}
                                            />
                                            :
                                            <Skeleton.Input active style={{ width: "320px" }}></Skeleton.Input>
                                        }
                                    </div>
                                    {canShowEmail && <div className="info-dash-inputFields">
                                        <LabelRequired
                                            description={t('NotificationEmailDescription')}
                                            descriptionProps={{ color: 'gray-600' }}
                                        >
                                            {t('Notification email')}
                                        </LabelRequired>
                                        {!isLoading ?
                                            <TextInput
                                                isLoading={isLoading}
                                                name="email1"
                                                id="email1"
                                                placeholder={t('Enter email address')}
                                            /> :
                                            <Skeleton.Input active style={{ width: "320px" }}></Skeleton.Input>
                                        }
                                    </div>}
                                    {canShowPhoneNo && <div className="info-dash-inputFields">
                                        <CustomLabel
                                            label={"Notification Phone Number"}
                                            description={"Get a notification by phone number when a sync error occurs"}
                                            htmlFor="phoneNumber"
                                            mb="0.375rem"
                                            mt="1rem"
                                        />
                                        {!isLoading ?
                                            <TextInput
                                                pt={".5rem"}
                                                type={"number"}
                                                isLoading={isLoading}
                                                id="phoneNumber"
                                                name="phoneNumber"
                                                placeholder={t('Enter Phone number')}
                                            /> :
                                            <Skeleton.Input active style={{ width: "320px" }}></Skeleton.Input>
                                        }
                                    </div>}
                                </>
                            </div>
                            <div className="info-dash-status">
                                {
                                    !isLoading ?
                                        <InfoSyncStatus formik={formik} />
                                        :
                                        <Skeleton.Input active style={{ width: "330px", height: "260px" }} />
                                }
                            </div>
                        </div>
                        <Divider />
                        {showPostingTypeJEWarning && <Alert
                            description={<>
                                <Text fontSize="medium" fontWeight="bolder">
                                Individual invoice sync posting type doesn't support journal entry, on saving the changes, It'll vanish all your saved configuration!
                                </Text>
                            </>}
                            type="warning"
                            showIcon
                            style={{ marginBottom: "25px" }}
                        />}
                        <PostingMethod isLoading={isLoading} {...props} formik={formik} />
                    </Card>
                </>
            </Layout>
        </>
    )
}

const CustomLabel = ({ label, required, ...props }) => {
    const LabelComp = Text;
    return <LabelComp
        fontWeight="bolder"
        descriptionProps={{ marginTop: '0.375rem' }}
        {...props}
    >
        {label}
    </LabelComp>;
};

export default InfoDashboard;