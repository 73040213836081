import { useSelector } from 'react-redux';
import { Flex } from '@react-css/flex';
import { companyLogo } from '../../utils/helpers/softwareImages';
import { McHeading, Text } from './text';
import { useLocationData } from '../../hooks/useLocation';
import { HiLocationMarker } from 'react-icons/hi';
import { useState } from 'react';
import { useEffect } from 'react';

export const LogoBranding = ({ isLogoOnly = false }) => {
    const { fullName } = useSelector(state => state.auth)

    return (
        <>
            <Flex row style={{ marginTop: "20px", marginLeft: "20px" }}>
                <Flex>
                    <img src={companyLogo[fullName]} alt={fullName} style={{ width: "90px", height: "90px", marginRight: "1rem" }} />
                </Flex>
                {!isLogoOnly && <Flex column style={{ marginTop: ".8rem" }}>
                    <McHeading level={4}>{fullName} Accounting</McHeading>
                    <Text size="small">
                        Eliminate manual data entry by automating your accounting tasks.
                    </Text>
                </Flex>}
            </Flex>
        </>
    )
}

export const Branding = ({ noMargin = false, noLocationName = false }) => {
    const { fullName } = useSelector(state => state.auth)

    const { currentLocation } = useLocationData()

    const showLocationName = !noLocationName

    const [offset, setOffset] = useState(0);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset);
        // clean up code
        window.removeEventListener('scroll', onScroll);
        window.addEventListener('scroll', onScroll, { passive: true });
        return () => window.removeEventListener('scroll', onScroll);
    }, []);

    let stickySetting = {}

    if (noMargin) {
        stickySetting = { top: offset < 40 ? "120px" : "0", }
    } else {
        stickySetting = { top: offset < 40 ? "145px" : "0", }
    }

    return (
        <Flex column style={{ marginLeft: "1rem" }}>
            <Flex row alignItemsCenter style={{ marginTop: showLocationName && !noMargin ? "2.8rem" : "" }}>
                <Flex>
                    <img src={companyLogo[fullName]} alt={fullName} style={{ width: "90px", height: "90px", marginRight: "1rem" }} />
                </Flex>
                <Flex column>
                    <McHeading level={4}>{fullName} Accounting</McHeading>
                    <Text size="small" color="gray-600">
                        Eliminate manual data entry by automating your accounting tasks.
                    </Text>
                </Flex>
            </Flex>
            {showLocationName && <div className='sticky-location-name' style={stickySetting}>
                <Flex style={{ paddingTop: ".5rem" }}>
                    <HiLocationMarker fontSize={"28px"} style={{ paddingTop: ".05rem", paddingRight: ".4rem", borderColor: "red" }} color="red" />
                    <McHeading level={3}>{currentLocation?.name}</McHeading>
                </Flex>
            </div>}
        </Flex>
    )
}