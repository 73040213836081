import { McHeading, Text } from '../../components/custom/text';

// eslint-disable-next-line import/no-anonymous-default-export
export default (props) => {
    /* TODO: translation here for Error on authorize! */

    const retry = (clickEvent) => {
        clickEvent.preventDefault();
        props.retry();
    }

    return (
        <>
            <McHeading level={4}>Error</McHeading>
            <Text>
                An error occurred. Please click 
                <Text link onClick={retry}>
                   {`  here  `}
                </Text>
                 to try again
            </Text>
        </>
    );
};