import { McHeading, Text } from "../../custom/text"
import { Flex } from '@react-css/flex';
import { Modal, Radio, Skeleton } from "antd";
import { useSelector, useDispatch } from 'react-redux';
import amIVisible from "../../../componentControl/registry";
import { useState } from 'react';
import { getStateOnChangePostingMethod } from "../../../utils/helpers/dashboard";
import { setCurrentPostingMethod, setIsPostingMethodChanged, setPostingTypeJEWarning, setRefreshAccounts } from "../../../slices/dashboardSlice";
import { AiFillWarning } from 'react-icons/ai';

export const PostingMethod = ({ formik, isLoading }) => {

    const { fullName: fullPortal } = useSelector(state => state.auth)

    const canShowProduct = amIVisible("Invoice By Product")
    const canShowCategory = amIVisible("Invoice By Category")
    const canShowGroup = amIVisible("Invoice By Group")
    const canShowAccountingSw = amIVisible("Invoice By accounting sw")
    let canShowJe = amIVisible("Journal Entry")
    const canShowPostingMethod = amIVisible("Posting Type")

    const [showModal, setShowModal] = useState(false)
    const [current, setValue] = useState({})
    const dispatch = useDispatch()

    const { postingMethod, dataTransferType } = formik.values

    if (!canShowPostingMethod) {
        return <></>
    }

    if (dataTransferType === 1) {
        canShowJe = false;
    }

    const postingMethods = [
        (canShowCategory) && {
            name: "Invoice by categories",
            value: "invoiceCategory",
            description: `${fullPortal} Accounting will send an invoice with your daily sales summary and respective payment types. Product sales will be grouped by category.`
        },
        (canShowGroup) && {
            name: "Invoice by grouping",
            value: "invoiceCategory",
            description: `${fullPortal} Accounting will send an invoice with your daily sales summary and respective payment methods. Item sales will be reported and mapped individually.`
        },
        (canShowAccountingSw) && {
            name: "Invoice by accounting software",
            value: "invoiceCategory",
            description: `${fullPortal} Accounting will send an invoice with your daily sales summary and respective payment methods. Item sales will be reported and mapped individually.`
        },
        (canShowProduct) && {
            name: "Invoice by product",
            value: "invoice",
            description: `${fullPortal} Accounting will send an invoice with your daily sales summary and respective payment types. Product sales will be grouped by category.`
        },
        (canShowJe) && {
            name: "Journal entry",
            value: "journalEntry",
            description: `${fullPortal} Accounting will create a general journal entry based on your end-of-day summary and group your product sales by category. Payments will be posted based on your mapping configuration.`
        },
    ].filter(Boolean)

    const handleOnOkay = () => {
        const newState = getStateOnChangePostingMethod(formik);

        dispatch(setIsPostingMethodChanged(true))
        dispatch(setRefreshAccounts(true))
        dispatch(setCurrentPostingMethod(current.value))

        formik.resetForm(newState);
        formik.setFieldValue('postingMethod', current.value)

        dispatch(setPostingTypeJEWarning(false))
        showWarning({}, false)
    }

    const showWarning = (value, flag) => {
        if (postingMethod !== "") {
            setShowModal(flag)
            setValue(value)
        } else {
            formik.setFieldValue('postingMethod', value.value)
            dispatch(setCurrentPostingMethod(value.value))
        }
    }

    return (
        <>
            {
                showModal && (
                    <Modal width={"700px"} title={<Flex alignItems={"flex-end"}>
                        <AiFillWarning color="orange" fontSize={"26px"} style={{ marginRight: "8px" }} />
                        Warning
                    </Flex>} open={showModal} closable={false} onCancel={() => showWarning({}, false)} okText={"Change Posting Method"} okType="danger" onOk={handleOnOkay}>
                        <>
                            <Text ml="1.5rem" mt="2rem" fontSize="large" fontWeight="bolder">Are you sure you want to change the posting method to "{current?.name}" ?</Text>
                            <Text ml="1.5rem" mt=".7rem" mb="3rem" fontSize="large">All configured accounts mapping and settings will be lost.</Text>
                        </>
                    </Modal>
                )
            }
            <McHeading level={5}>Select Posting Method</McHeading>
            <Flex>
                {
                    !isLoading ?
                        <>
                            {
                                <Flex style={{ paddingLeft: "1rem" }} wrap justifyContent={"space-between"}>
                                    {postingMethods?.map(item => (
                                        <Flex style={{ width: "49%", padding: ".8rem 0" }} justifyCenter="center" flexDirection="column">
                                            <Radio checked={postingMethod === item?.value} onChange={() => showWarning(item, true)} name={item?.value ?? ""}>
                                                <Text style={{ width: "100%" }} fontWeight="bolder">{item?.name}</Text>
                                            </Radio>
                                            <Text ml="1.5rem" mt=".3rem">{item?.description}</Text>
                                        </Flex>
                                    ))}
                                </Flex>
                            }
                        </>
                        :
                        <Flex flexDirection="row" justifyContent={"space-between"} style={{ width: "100%" }}>
                            <div style={{ width: "46%" }}>
                                <Skeleton avatar active paragraph={{ rows: 2 }} />
                            </div>
                            <div style={{ width: "49%" }}>
                                <Skeleton avatar active paragraph={{ rows: 2 }} />
                            </div>
                        </Flex>
                }
            </Flex>
        </>
    )
}