import { notification } from 'antd';
import { Text } from '../../components/custom/text';

export const useNotification = () => {

    notification.config({
        placement: 'topRight',
        maxCount: 1,
        rtl: false
    });

    const openNotificationWithIcon = (message, type) => {
        notification[type ?? "info"]({
            message: <Text fontWeight='bolder' fontSize='large'>{message}</Text>,
            top: 32
        });
    };

    const showNotification = (msg, type) => {
        openNotificationWithIcon(msg, type)
    }

    return { showNotification }
};