import { apiService } from "./apiService";

export const authService = apiService.injectEndpoints({
    endpoints: (builder) => ({
        isCompanyAuthorirzed: builder.query({
            query: () => 'isCompanyAuthorize/'
        }),
        authorize: builder.query({
            query: ({ appToken = '', prefix = '', token, locationId, key, ...params }) => {

                let headers = {}

                if (token && locationId) {
                    headers = {
                        Authorization: token,
                        locationId
                    }
                }

                return {
                    url: `${prefix}authorize/${appToken}`,
                    params: { ...params, appId: 961 },
                    headers
                }
            }
        }),
        authorizeAccountId: builder.query({
            query: ({ appToken = '', prefix = '', token, locationId, key, ...params }) => {

                let headers = {}

                if (token && locationId) {
                    headers = {
                        Authorization: token,
                        locationId
                    }
                }

                return {
                    url: `${prefix}authorizeAccountId/${appToken}/`,
                    params: { ...params },
                    headers
                }
            }
        }),
        login: builder.mutation({
            query: (body) => {
                return {
                    url: 'login/',
                    method: 'POST',
                    body
                }
            }
        }),
        signup: builder.mutation({
            query: (body) => {
                return {
                    url: 'signup/',
                    method: 'POST',
                    body
                }
            }
        }),
        logout: builder.query({
            query: () => 'logout/'
        }),
        recreate: builder.query({
            query: () => 'company/recreate/'
        }),
        connectionMode: builder.mutation({
            query: (payload) => {
                return {
                    url: "connectionMode/",
                    ...payload
                }
            }
        })
    })
});

export const {
    useIsCompanyAuthorirzedQuery,
    useAuthorizeQuery,
    useAuthorizeAccountIdQuery,
    useLogoutQuery,
    useLoginMutation,
    useRecreateQuery,
    useConnectionModeMutation,
    useSignupMutation
} = authService;