import { useSelector } from 'react-redux';
import { AiOutlineWarning } from "react-icons/ai/index";
import { MdError } from "react-icons/md/index"
import { getConnectionModeName } from '../../utils/helpers/commonHelpers';
import { useTranslation } from 'react-i18next';
import { Button, Card, Divider, Modal, Tag } from 'antd';
import { useState } from 'react';
import { useConnectionModeMutation } from '../../services/authService';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setIsReconnecting, setLocationId, setLocationList } from '../../slices/authSlice';
import { resetDashboardPage, setConnectionMode, setGlobalLoading } from '../../slices/dashboardSlice';
import Logout from '../custom/logout';
import Copyrights from '../custom/copyright';
import { Text } from '../custom/text';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import { softwareImages } from '../../utils/helpers/softwareImages';
import { Branding } from '../custom/brand';
import moment from 'moment';
import { SessionTimeoutError } from '../Dashboard/dashComponents/sessionTimeout';

export const MultiLocation = ({ navigate }) => {

    const { locationList } = useSelector(state => state.auth)
    const { t } = useTranslation()

    const [copyLocation, { isLoading, isSuccess }] = useConnectionModeMutation()

    const [showModal, setShowModal] = useState(false)
    const [listOfConnectedAccounts, setConnectedAccounts] = useState([])
    const [selectedLoc, setLoc] = useState({})
    const dispatch = useDispatch()

    // reset the dashboard states
    useEffect(() => {
        dispatch(resetDashboardPage())
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (isSuccess) {
            dispatch(setLocationList(locationList.map((loc) => {
                const currentLocation = Object.assign({}, loc)
                if (currentLocation?.id === selectedLoc?.id) {
                    currentLocation.showSetupAccountingButton = false;
                }

                return currentLocation
            })))
            dispatch(setIsReconnecting(1))
            navigate("../dashboard")
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    const handleBtnClick = (location) => {
        const { id, showSetupAccountingButton: isSetup, connectionMode } = location

        if (isSetup) {
            let connectedAccounts = locationList.filter((loc) => !loc?.showSetupAccountingButton)
            if (connectedAccounts.length > 0) {
                setShowModal(true)
                setConnectedAccounts(connectedAccounts)
                setLoc(location)
            } else {
                dispatch(setGlobalLoading(true))
                dispatch(setLocationId(id ?? ""))
                navigate("../user-onboard")
            }
        } else {
            dispatch(setGlobalLoading(true))
            dispatch(setLocationId(id ?? ""))
            dispatch(setConnectionMode(connectionMode ?? ""))
            navigate("../dashboard")
        }

    }

    return (
        <>
            <SessionTimeoutError navigate={navigate} />
            <Flex style={{ minHeight: "100vh", padding: "1rem" }} flexDirection={"column"}>
                <Box pb="1px">
                    <Branding noLocationName />
                </Box>
                <Flex flexDirection={"column"} mt={"1rem"} p=".5rem">
                    {locationList?.map((location) => {
                        const { id, isConfigured, syncEnabled, name, companyName, showSetupAccountingButton, connectionMode, address } = location

                        if (showSetupAccountingButton) {
                            return (
                                <Card style={{ marginTop: "1.3rem", padding: ".4rem" }}>
                                    <Flex justifySpaceBetween>
                                        <Flex flexDirection='column' justifyContent={"flex-end"}>
                                            <Text fontWeight="bolder" fontSize='large'>{name}</Text>
                                            <Text fontSize="text-s" color="gray-600" mt="0.375rem">
                                                {address}
                                            </Text>
                                        </Flex>
                                        <Button
                                            type={'primary'}
                                            fill={true}
                                            style={{ height: "36px" }}
                                            onClick={() => handleBtnClick({ id, showSetupAccountingButton, connectionMode })}
                                            loading={isLoading}
                                            disabled={isLoading}
                                        >
                                            Setup Accounting
                                        </Button>
                                    </Flex>
                                </Card>
                            )
                        }

                        return (
                            <>
                                <Card style={{ marginTop: "1.3rem", padding: ".2rem" }}>
                                    <>
                                        <Flex justifyContent="space-between" padding="0.75rem" alignItems="center">
                                            <Box width={"100%"}>
                                                <Flex flexDirection={"column"}>
                                                    <Flex alignItems={"center"}>
                                                        <>
                                                            <Text fontWeight="bolder" fontSize='large'>{name}</Text>
                                                            <Text fontSize="text-s" color="gray-600" mt="0.375rem">
                                                                {address}
                                                            </Text>
                                                        </>
                                                        <Flex >
                                                            <Text>{companyName}</Text>
                                                        </Flex>
                                                    </Flex>
                                                </Flex>
                                                <Flex justifyContent={"space-between"}>
                                                    <Flex flexDirection={"column"}>
                                                        {connectionMode && <Flex alignItems={"center"} style={{ marginTop: "1.8rem" }}>
                                                            <img alt="" src={softwareImages[connectionMode]} style={{ width: "25px", height: "25px" }} />
                                                            <Text ml=".5rem" fontWeight="bolder">{getConnectionModeName(t, connectionMode)}</Text>
                                                        </Flex>}
                                                        {!showSetupAccountingButton &&
                                                            <>
                                                                {<Flex style={{ marginTop: ".7rem" }}>
                                                                    <Flex style={{ marginTop: ".2rem" }}>
                                                                        {isConfigured ?
                                                                            <>{!syncEnabled && <AiOutlineWarning color="orange-500" />}</> :
                                                                            <MdError fontSize={20} color="red" />
                                                                        }
                                                                    </Flex>
                                                                    {(!isConfigured) ?
                                                                        <Text pl=".5rem" pt=".2rem" fontWeight='bolder'>{t('UpdateMapping')}</Text>
                                                                        : (!syncEnabled && isConfigured)
                                                                            ? <Text pl=".5rem" pt=".2rem" fontWeight='bolder'>{t('SyncPausedInfo')}</Text> :
                                                                            ""}
                                                                </Flex>}
                                                                <>
                                                                    {(isConfigured && syncEnabled && !showSetupAccountingButton) ?
                                                                        (
                                                                            <Flex alignItems="baseline" style={{ marginTop: ".5rem" }}>
                                                                                <Flex>
                                                                                    <Tag style={{ marginInlineEnd: 0 }} color="green">
                                                                                        <Text fontWeight='bold'>Connected</Text>
                                                                                    </Tag>
                                                                                </Flex>
                                                                                <Divider style={{ borderInlineStart: "1px solid black" }} type='vertical' />
                                                                                <Flex>
                                                                                    <Text fontWeight='bold'>
                                                                                        Next Sync Date : {moment(moment())?.add(1, 'days')?.format("YYYY-MM-DD")}
                                                                                    </Text>
                                                                                </Flex>
                                                                            </Flex>
                                                                        )
                                                                        : <></>}
                                                                </>
                                                            </>
                                                        }
                                                    </Flex>
                                                    <Flex>
                                                        <Button
                                                            type={'default'}
                                                            fill={true}
                                                            style={{ height: "36px" }}
                                                            onClick={() => handleBtnClick({ id, showSetupAccountingButton, connectionMode })}
                                                            loading={isLoading}
                                                            disabled={isLoading}
                                                        >
                                                            Edit mapping
                                                        </Button>
                                                    </Flex>
                                                </Flex>
                                            </Box>
                                        </Flex>
                                    </>
                                </Card>
                                <Modal title={
                                    <Text fontWeight='bolder' fontSize='large'>Setup with existing account</Text>
                                } maskClosable={false} closable={false} open={showModal} footer={null}>
                                    <Flex p={"1rem"} flexDirection="column">
                                        <Text>Use an existing account configured for another location or setup a different account for this location.</Text>
                                        <Flex mt="1.5rem" flexDirection={"column"}>
                                            <Text fontWeight="bold" fontSize="text-s">{getConnectionModeName(t, connectionMode)} existing accounts :</Text>
                                            <Flex flexDirection={"column"} style={{ marginTop: ".8rem" }}>
                                                {
                                                    listOfConnectedAccounts?.map((loc) => (
                                                        <Text
                                                            link
                                                            fontWeight="bolder"
                                                            fontSize="text-s"
                                                            onClick={() => {
                                                                setShowModal(false)
                                                                dispatch(setLocationId(selectedLoc?.id))
                                                                dispatch(setConnectionMode(loc?.connectionMode))
                                                                copyLocation({ method: "POST", body: { connectionMode: loc?.connectionMode, locationFrom: loc?.id } })
                                                            }}
                                                        >
                                                            {getConnectionModeName(t, loc?.connectionMode)} {loc?.name}
                                                        </Text>
                                                    ))
                                                }
                                            </Flex>
                                        </Flex>
                                        <Divider mt="2rem" />
                                        <Flex width="100%" justifyContent="flex-end" mt={"1rem"}>
                                            <Button
                                                onClick={() => {
                                                    setShowModal(false)
                                                    setLoc({})
                                                    setConnectedAccounts([])
                                                }}
                                                mr="0.75rem"
                                            >Cancel</Button>
                                            <Button
                                                type="primary"
                                                onClick={() => {
                                                    dispatch(setGlobalLoading(true))
                                                    dispatch(setLocationId(selectedLoc?.id))
                                                    navigate('../user-onboard')
                                                }}
                                            >Setup another account</Button>
                                        </Flex>
                                    </Flex>
                                </Modal>
                            </>
                        )
                    })}
                </Flex>
            </Flex>
            <Logout navigate={navigate} />
            <Copyrights />
        </>
    )
}