import { LabelRequired } from '../custom/LabelRequired';
import { Autocomplete } from '../fields';
import { useTranslation } from 'react-i18next';
import { SwitchLayout } from '../custom/SwitchLayout';
import useAccounts from '../../hooks/useAccounts';
import { useConnectionMode } from '../../hooks/useConnectionMode';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import amIVisible from '../../componentControl/registry';

export const Adjustment = ({ formik }) => {
  const [isLoading, accountsList] = useAccounts('adjustment');
  const [, expenseAccount] = useAccounts('shipping');
  const {
    isSentAdjustment
  } = formik.values;
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  const title = amIVisible("chargebacks") ? "Chargebacks" : "Transfer Order / Inventory Adjustment"
  const accounts = amIVisible("chargebacks") ? expenseAccount : accountsList

  return <SwitchLayout
    heading={title}
    description={t('', { swName })}
    switchFieldName="isSentAdjustment"
    isSwitchOn={isSentAdjustment}
    isLoading={isLoading}
  >
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="adjustmentAccount">
          {`Default ${title} Account`}
        </LabelRequired>
        <Autocomplete
          id="adjustmentAccount"
          name="adjustmentAccount"
          isLoadingOptions={isLoading}
          options={accounts}
          placeholder={"Select an account"}
        />
      </Box>
    </Flex>
  </SwitchLayout>;
};