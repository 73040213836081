import React, { useEffect } from 'react';
import { Router as Routes, Redirect } from "@reach/router";
import { Dashboard } from '../components/Dashboard/Dash';
import i18n from '../i18n';
import portal from "../target.json"
import { useDispatch } from 'react-redux';
import { setAppToken, setFullName, setPortal, setToken } from '../slices/authSlice';
import { parse as parseQuery } from 'query-string';
import { LSAuthorize } from '../Auth/LSAuthorize';
import { UserOnboard } from '../Auth/userOnboard/userOnboard';
import target from "../target.json";
import { MultiLocation } from '../components/selectLocation/multiLocation';
import LoginPage from '../Auth/components/LoginPage';
import { SignupPage } from '../Auth/components/signupPage';

const Router = () => {

    const dispatch = useDispatch();
    const params = parseQuery(window.location.search);

    useEffect(() => {
        if (params && params.language) {
            i18n.changeLanguage(params.language);
        }

        if (portal.target) {
            if (portal.target !== "ls") {
                dispatch(setPortal(portal.target))
            } else {
                dispatch(setPortal("ls"))
            }

            if (params?.token) {
                dispatch(setToken(params?.token))
            }

            let name = ''

            switch (portal.target) {
                case "ls":
                    name = "PayEngine"
                    break
                case "epos":
                    name = "EposNow"
                    break
                case "revel":
                    name = "Revel"
                    break
                case "runit":
                    name = "Runit"
                    break
                default:
                    name = portal.target
            }

            dispatch(setFullName(name))

            document.title = name + " Accounting"
        }

        if (params?.appToken) {
            dispatch(setAppToken(params?.appToken))
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Routes style={{ height: "100%" }} basepath={target.basepath ?? ''} >
            <LSAuthorize path="/authorize" key="authorize"  />
            <LSAuthorize path="/authorize-existing" key="authorize-existing"  />
            <LSAuthorize path="/authorize-new" key="authorize-new"  />
            {/* <EposAuthorize path="/" /> */}
            <Redirect from="/" to="./login" noThrow />
            <LoginPage path="/login" />
            <SignupPage path="/signup" />
            <UserOnboard path="/user-onboard" />
            <Dashboard path="/dashboard" />
            <MultiLocation path="/select-location" />
        </Routes>
    );
}

export default Router;