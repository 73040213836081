import useCurrency from '../../hooks/useCurrency';
import { Spin, Table } from 'antd';
import { Text } from '../custom/text';
import { MdOutlineDone } from 'react-icons/md';

export const AdjustmentTable = ({ isLoading, heading, mappingType, isJeLayout, ...props }) => {
    const { data } = props;
    const { toCurrency } = useCurrency();

    let columns = [
        {
            title: "Name",
            dataIndex: "name",
            key: "name"
        },
        {
            title: "Account",
            dataIndex: "account",
            key: "account"
        },
        {
            title: "Credits",
            dataIndex: "type",
            key: "credits",
            render: (_, item) => item?.type === "Credit" && <MdOutlineDone />
        },
        {
            title: "Debits",
            dataIndex: "type",
            key: "debits",
            render: (_, item) => item?.type === "Debit" && <MdOutlineDone />
        },
        {
            title: "Amount",
            dataIndex: "amount",
            key: "amount",
            render: (_, item) => toCurrency(item.amount)
        },
    ]

    return <>
        {
            <Spin spinning={!isLoading}>
                {(data !== null && data?.length > 0) && <Table style={{ marginTop: "20px" }} caption={
                    <Text fontWeight='bolder' fontSize='large'>{heading}</Text>
                } columns={columns} dataSource={data} pagination={false} />}
            </Spin>
        }
    </>;
};
