import { Autocomplete } from '../fields';
import { LabelRequired } from '../custom/LabelRequired';
import { useTranslation } from 'react-i18next';
import useAppSubmitting from '../../hooks/useAppSubmitting';
import useConnectionMode from '../../hooks/useConnectionMode';
import useAccounts from '../../hooks/useAccounts';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentComponent } from '../../slices/dashboardSlice';
import { useNotification } from '../../utils/helpers/notifications';
import { Text } from '../custom/text';
import { Alert, Card, Skeleton } from 'antd';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import _ from 'lodash';

export const MapPaymentMethods = ({ fetchedConfig, formik, ...props }) => {
  const [isAppSubmitting] = useAppSubmitting();
  const [, softwareName] = useConnectionMode();
  const [isLoadingAccounts, accountsList] = useAccounts('payment');
  const { showNotification } = useNotification();
  const { defaultPaymentAccountName: defaultAccount } = formik.values;
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();
  const isLoading = isInputsOnlyLayout ? false : isLoadingAccounts;

  const { hasPaymentError } = useSelector(state => state.dashboard)

  const dispatch = useDispatch()

  const gotoPaymentMapping = () => {
    if (formik.dirty) {
      showNotification("Save the current changes to proceed", "error")
    } else {
      dispatch(setCurrentComponent("payment mapping"))
    }
  };

  const handleNavigation = () => {
    if (isAppSubmitting) {
      showNotification(t('Please wait for data to save'), 'error');
    } else if (defaultAccount) {
      gotoPaymentMapping();
    } else {
      showNotification(t(isInputsOnlyLayout ? 'DefaultAccountTextInputFirst' : 'SelectPaymentFirst'), 'error');
    }
  };

  return <Card type="inner" title={
    <Text fontSize={"large"} fontWeight="bolder">{t('Payment types')}</Text>
  } style={{ marginBottom: "1rem" }}>
    <Text color="gray-600" size="small">
      {t('DefaultPaymentDesc', { swName: softwareName })}
    </Text>
    <Flex>
      <Box width="49%" pr="1.1rem">
        <LabelRequired htmlFor="defaultPaymentAccountName">
          {t('Default payment account')}
        </LabelRequired>
        <Autocomplete
          id="defaultPaymentAccountName"
          name="defaultPaymentAccountName"
          isLoading={isLoading}
          options={accountsList}
          placeholder={
            t(isInputsOnlyLayout ? 'Enter payments account' : 'Select an account')
          }
        />
      </Box>
      {amIVisible("payment individual") && <Flex style={{ marginTop: "45px" }} >
        {isLoading ?
          <Skeleton.Input size="small" /> :
          <Text link size="small" fontWeight="bolder"
            onClick={handleNavigation}
          >
            {t('Map individual payment types')}
          </Text>
        }
      </Flex>}
    </Flex>
    {!_.isEmpty(hasPaymentError) && <Alert
      message={hasPaymentError + " Please map it in \"Map individual payment types\""}
      type="error"
      showIcon
      style={{ width: "655px", marginTop: "1rem" }}
    />}
  </Card>;
};