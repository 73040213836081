import { useField } from "formik";
import { useSelector } from 'react-redux';
import Flex from '@react-css/flex'
import './field.css'
import { Input as AntInput } from 'antd';

export const Input = (props) => {

    const {
        hasErrorInitial,
        disabled,
        isLoading: propsLoading
    } = props

    const [field, meta, helpers] = useField(props.name);
    const { isGlobalLoading, isAccountLoading, isRefreshAccount, hasError: anyError } = useSelector(state => state.dashboard);
    const isLoading = isGlobalLoading || propsLoading || isAccountLoading || isRefreshAccount;
    let status = hasErrorInitial ? 'error' : false;

    if ((anyError || meta.touched) && meta.error && !props.disabled) {
        status = 'error'
    }

    const handleChange = (evt) => {
        helpers.setValue(evt.target.value ?? '');
    };

    return (
        <>
            <Flex column >
                <AntInput
                    {...field}
                    {...meta}
                    style={{ maxWidth: 320 }}
                    allowClear
                    value={meta?.value}
                    loading={isLoading}
                    status={status}
                    placeholder={"Enter an Input"}
                    {...props}
                    disabled={disabled || isLoading}
                    onChange={handleChange}
                    autoComplete="none"
                />
                {status && 
                    <Flex>
                        <p className="required-label">
                            {meta.error}
                        </p>
                    </Flex>
                }
            </Flex>
        </>
    )
}