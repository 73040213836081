import { apiService } from "./apiService";

export const dashboardApi = apiService.injectEndpoints({
    endpoints: (builder) => ({
        getDashValues: builder.query({
            query: () => 'configuration/'
        }),
        getAccounts: builder.query({
            query: ({ journalEntry, isRefresh }) => {

                return {
                    url: `accounts/`,
                    params: { journalEntry, isRefresh }
                }
            },
            transformResponse: (res) => {
                return res?.map((accountData, idx) => {
                    const result = {
                        value: accountData?.name,
                        label: `${accountData?.accountNumber ? "(" + accountData?.accountNumber + ")" : ""} ${accountData?.name} - ${accountData?.type} `,
                        type: accountData?.type?.toLowerCase(),
                        id: accountData?.accountNumber ?? "",
                        key: idx
                    };

                    return result;
                })
            }
        }),
        getTaxes: builder.query({
            query: ({ journalEntry }) => `taxes/?journalEntry=${journalEntry}`
        }),
        sendPayload: builder.mutation({
            query: (body) => ({
                url: 'configuration/',
                method: 'POST',
                body
            })
        }),
        getLocations: builder.query({
            query: ({ class: isClass }) => {
                return {
                    url: 'posLocations/',
                    params: { "class": isClass }
                }
            },
            transformResponse: (res) => {
                return res.map(res => ({
                    value: res.name,
                    label: res.id
                }))
            }
        }),
        getPoTaxes: builder.query({
            query: ({ journalEntry }) => `taxes/?journalEntry=${journalEntry}&purchaseTaxRate=true`
        }),
        getPaymentMethod: builder.query({
            query: (params) => {
                return {
                    url: 'paymentMethods/',
                    params
                }
            }
        }),
        getDiscounts: builder.query({
            query: () => 'discounts/'
        }),
        getItems: builder.query({
            query: (params) => {
                return {
                    url: 'items/',
                    params
                }
            }
        }),
        getCategories: builder.query({
            query: (params) => {
                return {
                    url: 'items/',
                    params
                }
            }
        }),
        getSyncCounts: builder.query({
            query: () => 'authorize/',
            transformResponse: ({ syncStatus: response }) => {
                
                return {
                    syncSuccessCount: response?.salesTransactionsSyncedWeekly ?? 0 + response?.purchaseOrderTransactionsSyncedWeekly ?? 0,
                    syncFailedCount: parseInt(response?.saleTransactionsFailedToSync ?? 0) + parseInt(response?.purchaseOrderTransactionsFailedToSync ?? 0)
                } 
            }
        })
    })
});

export const {
    useGetDashValuesQuery,
    useGetAccountsQuery,
    useGetTaxesQuery,
    useSendPayloadMutation,
    useGetLocationsQuery,
    useGetPoTaxesQuery,
    useGetPaymentMethodQuery,
    useGetItemsQuery,
    useGetCategoriesQuery,
    useGetSyncCountsQuery,
    useGetDiscountsQuery
} = dashboardApi;