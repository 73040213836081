import { useEffect, useState } from 'react'

const useImage = (fileName) => {
    const [image, setImage] = useState(null)

    useEffect(() => {
        const fetchImage = async () => {
            try {
                const response = await import("../Auth/statics/" + fileName) 
                setImage(response.default)
            } catch (err) {
                console.error(err)   
            }
        }

        fetchImage()
    }, [fileName])

    return image
}

export default useImage