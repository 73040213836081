import amIVisible from "../../componentControl/registry";
import { payloadIndividualParser, payloadPoParser } from "./individualMappingParser";
import { isEmpty } from 'lodash';

/* eslint-disable import/no-anonymous-default-export */

// parse the received values
export const dashboardTransformer = (config) => {
    let syncRange
    if (config?.dataTransferType === 1) {
        syncRange = 0
    } else {
        // Parse syncRange
        syncRange = config.syncRange ?? 1;
        if (syncRange === 2) {
            syncRange = 1 + config.weekDay;
        }
    }

    const values = {
        email: config.email1 || '',
        syncRange: `${syncRange}`,
        dataTransferType: config?.dataTransferType,
    };

    const init = {
        locations: '',
        classes: '',
        postingMethod: '',
        discountsMapping: {}
    }

    if (config.configType) {
        if (config.journalEntry) {
            values.postingMethod = 'journalEntry';
            
            // set default value to tax
            if (config?.tax) {
                if (Object.entries(config?.tax ?? {})?.length > 0) {
                    if (Object.keys(config?.tax)?.includes("default")) {
                        values.defaultTaxAccount = config?.tax["default"]
                    }
                }
            }

        } else if (config.recordItems === false) {
            values.postingMethod = 'invoiceCategory';
        } else {
            values.postingMethod = 'invoice';
        }
    }

    values.isMapClass = config?.locationMapping || config?.classMapping

    if (config?.locationMapping) {
        if (!isEmpty(config?.locations)) {
            config.locations = config?.locations[Object.keys(config?.locations ?? {})[0]] ?? ""
        }
    }

    if (config?.classMapping) {
        if (!isEmpty(config?.classes)) {
            config.classes = config?.classes[Object.keys(config?.classes ?? {})[0]] ?? ""
        }
    }

    let keys = Object.keys(config)

    for (let i = 0; i < keys.length; i++) {
        if ((config[keys[i]] === 0 || config[keys[i]] === 1) && !["isParentCategory", "swapSuperParentCategoryFromProducts"].includes(keys[i])) {
            config[keys[i]] = Boolean(config[keys[i]])
        }
    }

    return { ...init, ...config, ...values };
};

export const parsePayload = (values) => {

    const payload = {
        ...values,
        configType: Number(values.configType)
    }


    const syncRange = parseInt(payload.syncRange, 10);

    // Sync schedule
    if (syncRange > 1) {
        payload.weekDay = syncRange - 1;
        payload.syncRange = 2;
    }

    payload.type = 1;

    if (values.postingMethod === '' || !values.postingMethod) {
        delete payload.type
    }

    payload.recordItems = payload.postingMethod === 'invoice';
    payload.journalEntry = payload.postingMethod === 'journalEntry';

    if (payload.postingMethod === 'journalEntry') {
        payload.openInvoices = false
    }

    // location / class parsing
    if (payload.locations !== "" && payload.isMapClass) {
        payload.locationMapping = true
        payload.locations = {
            [payload.locations]: payload.locations
        }
    }

    if (payload.classes !== "" && payload.isMapClass) {
        payload.classMapping = true
        payload.classes = {
            [payload.classes]: payload.classes
        }
    }

    if (!payload.isMapClass) {
        payload.classMapping = false;
        payload.locationMapping = false;
        payload.locations = "";
        payload.classes = "";
    }

    if (payload.isMapClass) {
        if (payload.locations === "" && payload.classes === "") {
            payload.classMapping = true;
            payload.locationMapping = true;
        } else if (payload.classes !== "" && payload.locationMapping) {
            payload.locationMapping = false;
        } else if (payload.locations !== "" && payload.classMapping) {
            payload.classMapping = false
        }
    }

    // floor mapping parsing
    if (payload.floorMapping) {
        payload.floorLocationMapping = {}
        payload.floorClassMapping = {}
        for (let i = 0; i < payload?.floorMapping?.length; i++) {
            payload.floorLocationMapping[payload?.floorMapping[i]?.value] = payload?.floorMapping[i]?.location ?? ""
            payload.floorClassMapping[payload?.floorMapping[i]?.value] = payload?.floorMapping[i]?.class ?? ""
        }
    }

    // tax Parsing
    if (payload.taxes) {
        payload.tax = payloadIndividualParser(payload.taxes)
    }

    // parse default tax for payload
    if (payload?.journalEntry) {
        if (payload?.parseDefaultTax) {
            payload.tax = {
                "default": payload?.defaultTaxAccount
            }
        }
    }

    // poTaxes Parsing
    if (payload.poTaxMapping) {
        payload.poTaxes = payloadIndividualParser(payload.poTaxMapping)
    }

    // payment parsing
    if (payload.paymentsMapping) {
        payload.payments = payloadIndividualParser(payload.paymentsMapping)
    }

    // discounts parsing
    if (payload.discountsMapping) {
        payload.discounts = payloadIndividualParser(payload.discountsMapping)
    }

    // items parsing
    if (payload.itemsMapping) {
        payload.items = payloadIndividualParser(payload.itemsMapping)
    }

    // categories parsing
    if (payload.categoriesMapping) {
        payload.categories = payloadIndividualParser(payload.categoriesMapping)
    }

    // itemsLevelMapping parsing
    if (payload.itemsLevelMapping) {
        payload.itemsLevel = payloadIndividualParser(payload.itemsLevelMapping)
    }

    // items parsing
    if (payload.poItemsMapping) {
        payload.itemSpecificInventoryCogsAccounts = payloadPoParser(payload.poItemsMapping)
    }

    // categories parsing
    if (payload.poCategoriesMapping) {
        payload.categorySpecificInventoryCogsAccounts
            = payloadPoParser(payload.poCategoriesMapping)
    }

    // poItemsLevelMapping parsing
    if (payload.poItemsLevelMapping) {
        payload.poItemsLevel
            = payloadPoParser(payload.poItemsLevelMapping)
    }

    if (!payload.POInventoryCogs) {
        delete payload.poTaxes
        delete payload.poTaxMapping
        delete payload.isSentCogs
        delete payload.trackInventory
        delete payload.isSentPurchaseOrder
        if (!amIVisible("individual inventory account")) {
            delete payload.defaultInventoryAccountName
        }
        delete payload.defaultCogsAccountName
        delete payload.freightCharges
        delete payload.shipping
        delete payload.otherExpenses
        delete payload.poDiscountAccount
        delete payload.poAdditionalDiscountAccount
        delete payload.categorySpecificInventoryCogsAccounts
        delete payload.itemSpecificInventoryCogsAccounts
        delete payload.poItemsLevel
    }

    if (!payload?.isSentAdjustment) {
        delete payload.adjustmentAccount
    }

    delete payload.attributes

    return payload;
}