import React from 'react';
import { Autocomplete } from '../fields';
import { useGetLocationsQuery } from '../../services/dashboardService';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import { Text } from '../custom/text';
import { useNotification } from '../../utils/helpers/notifications';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import { LabelRequired } from '../custom/LabelRequired';
import { IoIosRefresh } from 'react-icons/io';
import { colors } from '../../style/color';
import { Button } from 'antd';

export const ClassMapping = ({ formik }) => {
    const { isMapClass } = formik.values;
    const [, swName] = useConnectionMode();
    const { t } = useTranslation();

    const { data: classList, isFetching: fetchingLocation, isLoading: loadingLocation, refetch: refetchLocations } = useGetLocationsQuery({ class: true }, { skip: !isMapClass ?? true })

    const isLoading = fetchingLocation || loadingLocation

    const { showNotification } = useNotification();

    useEffect(() => {
        if (classList && classList?.length === 0) {
            showNotification('Class Tracking not enabled in accounting software', 'error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classList])

    return (
        <SwitchLayout
            heading={"Location/Class Tracking"}
            description={`Use Classes to track your transactions by departments, product lines, or any other meaningful segments in your business. Only available for ${swName} Plus and Advanced. Ensure that \"Track Location/Classes\" is enabled in ${swName}.`}
            switchFieldName="isMapClass"
            isSwitchOn={isMapClass}
            isLoading={isLoading}
        >
            <Flex>
                <Box width="49%" pr="1.1rem">
                    <LabelRequired notRequired>
                        {t('Select a Class')}
                    </LabelRequired>
                    <Autocomplete
                        id="classMapping"
                        name="classes"
                        isLoading={!classList || isLoading}
                        options={classList}
                        placeholder={t('Select a class')}
                        formik={formik}
                    />
                </Box>
            </Flex>
            <Box mt="1rem" ml=".2rem" width='100%'>
                <Flex justifySpaceBetween>
                    <Flex>
                        <Text fontWeight={"bolder"} mr=".5rem">Required : </Text><Text pr=".2rem">Class mapping</Text>
                    </Flex>
                    <Flex>
                        {!(isLoading) && <IoIosRefresh style={{ color: colors.primary, paddingTop: "5px" }} />}
                        <Button onClick={() => {
                            refetchLocations()
                        }} loading={isLoading} size='small' type='link' style={{ padding: "0 1px" }}>Refresh Classes</Button>
                    </Flex>
                </Flex>
            </Box>
        </SwitchLayout>
    )
}