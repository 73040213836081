import { useState } from 'react';
import { TbTrash } from "react-icons/tb/index"
import { FiSave } from "react-icons/fi/index"
import { GoIssueOpened } from "react-icons/go/index"
import { useSelector, useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { useSendPayloadMutation } from '../../services/dashboardService';
import { parsePayload } from '../../utils/helpers/dashboardTransformer';
import { useLocationData } from '../../hooks/useLocation';
import { resetDashboardPage, setCurrentComponent, setCurrentPostingMethod, setFetchedConfig, setIsPostingMethodChanged, setPaymentError, setPostingTypeJEWarning, setRefreshAccounts } from '../../slices/dashboardSlice';
import { useNotification } from '../../utils/helpers/notifications';
import amIVisible from '../../componentControl/registry';
import { Text } from '../custom/text';
import Copyrights from '../custom/copyright';
import { Button, Card, Modal } from 'antd';
import { BsArrowLeft } from 'react-icons/bs';
import { Flex } from '@react-css/flex';
import { AiFillWarning } from 'react-icons/ai';
import { Branding } from '../custom/brand';

const NavigateBack = ({ formik, isSubmitting, path, currentLocation, isMultiLocation, disableBack, hasChanges, ...props }) => {
  const { showNotification } = useNotification();
  const { t } = useTranslation();
  const isLocationLink = useSelector(state => state.dashboard.currentComponent) === "dash";
  const dispatch = useDispatch()

  const gotoDashboard = () => {
    if (isSubmitting) {
      showNotification(t('Please wait for data to save'), 'error');
    } else {
      if (isLocationLink && !hasChanges) {
        dispatch(resetDashboardPage())
        formik.resetForm()
        props.navigate('../select-location');
      } else {
        if (hasChanges) {
          showNotification('Save the current changes to proceed', 'error')
        } else {
          dispatch(setCurrentComponent("dash"))
        }
      }
    }
  };

  return <Flex style={{ margin: "1.125rem 0" }}>
    <BsArrowLeft color="blue-500"
      style={{
        marginTop: '0.1rem',
        marginRight: '0.375rem',
      }}
      fontSize="24px"
    />
    <Text link mb="0.75rem" mt=".275rem" fontWeight='bolder' onClick={gotoDashboard}>
      {isLocationLink ? "Back to locations" : "Back to dashboard"}
    </Text>
  </Flex>
};

const DiscardWarning = ({ closeModal, confirm }) => {
  const { t } = useTranslation();

  return (
    <Modal open closable={false} title={<Flex alignItems={"flex-end"}>
      <AiFillWarning color="red" fontSize={"26px"} style={{ marginRight: "8px" }} />
      Discard Warning
    </Flex>}
      onCancel={closeModal}
      onOk={confirm}
      okType="danger"
      okText="Discard changes"
    >
      <Text fontWeight="bolder" fontSize="large" mb="0.8rem" mt="2rem">
        Discard changes made on this page ?
      </Text>
      <Text mb="2.5rem" color="gray-600" fontSize="medium">
        {t('DiscardWarning.1')}
      </Text>

    </Modal>
  );
};

const BottomBar = ({ children }) => {
  return <>
    <div style={{ paddingTop: "3rem" }}></div>
    <div
      style={{
        width: "100%",
        position: 'fixed', bottom: 0, left: 0, zIndex: 3, borderRadius: 0,
        boxShadow: '0px -2px 4px rgba(12, 13, 13, 0.2)'
      }}
    >
      <Card className="savebar">
        {children}
      </Card>
    </div>
  </>;
};

const SaveBar = ({ isSubmitting, isDisabled, discardData, submitData, refetch }) => {
  const [showWarning, setShowWarning] = useState(false);
  const { t } = useTranslation();

  const { isParentCategory, swapSuperParentCategoryFromProducts, currentComponent } = useSelector(state => state.dashboard)?.fetchedConfig
  const { isPostingMethodChanged, fetchedConfig } = useSelector(state => state.dashboard)

  const dispatch = useDispatch()

  const handleCloseWarning = () => {
    setShowWarning(false);
  };

  const handleConfirm = () => {
    discardData(isPostingMethodChanged);
    if (refetch) {
      if ((currentComponent === "sales category mapping")) {
        refetch(Number(isParentCategory) ?? 0)
      } else {
        refetch(Number(swapSuperParentCategoryFromProducts) ?? 0)
      }
    }

    if (isPostingMethodChanged) {
      dispatch(setRefreshAccounts(true))
      dispatch(setIsPostingMethodChanged(false))
      dispatch(setCurrentPostingMethod(fetchedConfig?.postingMethod))
    }
  };

  const handleClick = () => {
    setShowWarning(true);
  };

  return <>
    {showWarning &&
      <DiscardWarning
        closeModal={handleCloseWarning}
        confirm={handleConfirm}
      />
    }
    <BottomBar>
      <Flex justifyContent="space-between" alignItems="center">
        <Flex alignItems="center">
          <GoIssueOpened color="red" className="mc-btn-icon" />
          <Text color="red">{t('Unsaved changes')}</Text>
        </Flex>
        <Flex>
          <Button
            disabled={isSubmitting}
            onClick={handleClick}
            mr="0.75rem"
            danger
            className='btn-with-icon'
          >
            <TbTrash className="mc-btn-icon" />
            Discard
          </Button>
          <Button
            type="primary"
            htmlType={"submit"}
            loading={isSubmitting}
            onClick={submitData}
            disabled={isDisabled}
            className='btn-with-icon'
          >
            <FiSave className="mc-btn-icon" />
            {t('Save changes')}
          </Button>
        </Flex>
      </Flex>
    </BottomBar>
  </>;
};

const SwitchMappingBar = ({ isDisabled, formik, switchMapping }) => {
  const { t } = useTranslation();

  const dispatch = useDispatch()

  const { currentComponent, currentPostingMethod } = useSelector(state => state.dashboard)

  const isCategoryOnly = currentPostingMethod !== 'invoice';

  const hasItemId = amIVisible("show sales mapping with items ID")

  if (!switchMapping) {
    return <></>
  }

  const switchOptions = {
    product: {
      label: t('Map Products'),
      switchTo: 'product',
    },
    category: {
      label: t('Map Categories'),
      switchTo: 'category',
    },
    productIds: {
      label: t('Map Type/sub-type'),
      switchTo: 'productId',
    },
  };

  const handleMappingChange = () => {

    let moveTo = ""

    if (!hasItemId) {
      switch (currentComponent) {
        case "sales product mapping":
          moveTo = "sales category mapping"
          break
        case "sales category mapping":
          moveTo = "sales product mapping"
          break
        case "po product mapping":
          moveTo = "po category mapping"
          break
        case "po category mapping":
          moveTo = "po product mapping"
          break
        default:
          break
      }
    } else {

      if (isCategoryOnly) {

        switch (currentComponent) {
          case "sales product level mapping":
            moveTo = "sales category mapping"
            break
          case "sales category mapping":
            moveTo = "sales product level mapping"
            break
          case "po product level mapping":
            moveTo = "po category mapping"
            break
          case "po category mapping":
            moveTo = "po product level mapping"
            break
          default:
            break
        }

      } else {

        switch (currentComponent) {
          case "sales product mapping":
            moveTo = "sales category mapping"
            break
          case "sales product level mapping":
            moveTo = "sales product mapping"
            break
          case "sales category mapping":
            moveTo = "sales product level mapping"
            break
          case "po product mapping":
            moveTo = "po category mapping"
            break
          case "po product level mapping":
            moveTo = "po product mapping"
            break
          case "po category mapping":
            moveTo = "po product level mapping"
            break
          default:
            break
        }
      }

    }

    dispatch(setCurrentComponent(moveTo))
  };

  const getCurrentLabel = () => {

    let key = ""

    if (!hasItemId) {
      if (currentComponent.includes("category")) {
        key = "product"
      } else if (currentComponent.includes("product")) {
        key = "category"
      }
    } else {

      if (isCategoryOnly) {
        if (currentComponent.includes("category")) {
          key = "productIds"
        } else {
          key = "category"
        }
      } else {
        if (currentComponent.includes("category")) {
          key = "productIds"
        } else if (currentComponent.includes("level")) {
          key = "product"
        } else {
          key = "category"
        }
      }
    }

    return switchOptions[key]?.label
  }

  return <BottomBar>
    <Flex justifyContent="flex-end" alignItems="center">
      <Flex>
        <Button
          type="primary"
          onClick={handleMappingChange}
          disabled={isDisabled}
        >
          {getCurrentLabel()}
        </Button>
      </Flex>
    </Flex>
  </BottomBar>;
};

export const PageLayout = ({
  title,
  description,
  children,
  showBack,
  loadAccounts,
  disableBack,
  onReconnectSuccess,
  refetchConfig,
  formik,
  noBottomBar,
  ...props
}) => {

  const { currentLocation, isMultiLocation } = useLocationData();
  const hasChanges = formik?.dirty;
  const [sendPayload] = useSendPayloadMutation()

  const { isGlobalLoading, fetchedConfig, isPostingMethodChanged } = useSelector(state => state.dashboard);

  const { showNotification } = useNotification()

  const dispatch = useDispatch()

  const { currentComponent } = useSelector(state => state.dashboard)

  const isItemLevel = amIVisible("show sales mapping with items ID")

  let switchMapping = (
    amIVisible("sales can show both product and categories") ||
    amIVisible("inventory cogs can show both product and categories") ||
    isItemLevel
  )

  if (switchMapping) {
    switchMapping = ["sales product mapping", "sales category mapping", "sales product level mapping", "po category mapping", "po product mapping", "po product level mapping"]
      .includes(currentComponent)
  }

  if (switchMapping && !isItemLevel) {
    const { postingMethod } = formik?.values

    const isTypeCategory = postingMethod !== 'invoice';
    switchMapping = !isTypeCategory && switchMapping
  }

  const discard = (reInit = false) => {
    if (reInit) {
      dispatch(setFetchedConfig({ ...fetchedConfig, key: Math.random() }))
    } else {
      formik.resetForm()
    }

    dispatch(setPostingTypeJEWarning(false))
  }

  const handleOnSubmit = () => {

    const canSendOpenInvoiceAsTrue = amIVisible("send open invoice as true")

    const extra = {}

    if (canSendOpenInvoiceAsTrue) {
      extra.openInvoices = true
    }

    let parseDefaultTax = false

    if (amIVisible("show default tax rates")) {
      parseDefaultTax = true
    }

    let payload = { ...formik?.values, ...extra, isResetConfiguration: isPostingMethodChanged, parseDefaultTax }

    sendPayload(parsePayload(payload))
      .unwrap()
      .then((res) => {
        // TODO : show success msg
        formik.validateForm();
        if (res?.error && !res?.Validation) {
          dispatch(setPaymentError(res?.error))
          showNotification(res?.error, "error")
        } else if (res?.error) {
          showNotification("Configuration can't be completed", "error")
        } else {
          showNotification("Configuration completed", "success")
        }
      })
      .catch((res) => {
        showNotification(res?.data?.error ?? "Something went wrong", "error")
      })
  }

  return <>
    <Branding />
    <div className='main-content' style={{ padding: "1.125rem", maxWidth: "1024px", minHeight: "100vh" }}>
      {showBack &&
        <NavigateBack
          isSubmitting={isGlobalLoading}
          currentLocation={currentLocation}
          isMultiLocation={isMultiLocation}
          disableBack={disableBack}
          hasChanges={hasChanges}
          formik={formik}
          {...props}
        />
      }
      {children}
      {
        (hasChanges ? <SaveBar isSubmitting={isGlobalLoading} discardData={discard} submitData={handleOnSubmit} {...props} /> : <SwitchMappingBar switchMapping={switchMapping} formik={formik} isDisabled={isGlobalLoading} />)
      }
    </div>
    <div>
      <Copyrights hasShownBottomBar={hasChanges || switchMapping} />
    </div>
  </>
};