import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setAppConnected } from '../redux/reducer/globalsReducer';

export const useAppConnected = () => {
  const isConnected = useSelector(state => state.globals.isConnected);
  const dispatch = useDispatch();

  const setConnected = (args) => {
    dispatch(setAppConnected(args));
  };

  return [isConnected, setConnected];
};

export default useAppConnected;
