import { useState } from 'react';
import { PageLayout } from '../pageLayout/PageLayout';
import { HistoricalSync } from './HistoricalSync';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import { useSelector } from 'react-redux';
import { useGetSyncDataQuery, useRepostSyncMutation } from '../../services/syncService';
import { useNotification } from '../../utils/helpers/notifications';
import { SyncDynamicTable } from './SyncDynamicTable';
import { SyncModal } from './SyncModal';

export const SyncLogs = ({ isTypePo = false }) => {
  const [, swName] = useConnectionMode();

  const [syncDetailsInfo, setSyncDetailsInfo] = useState({
    show: false,
  });

  const { t } = useTranslation();

  const { syncData } = useSelector(state => state.sync)

  const [repost] = useRepostSyncMutation()

  const [key] = useState(Math.random())

  const { refetch } = useGetSyncDataQuery({ key })

  const { showNotification } = useNotification()

  const openSyncDetailsModal = (data) => {
    setSyncDetailsInfo({
      show: true,
      data,
    });
  };

  const closeSyncDetailsModal = () => {
    setSyncDetailsInfo({ show: false });
  };

  const makeRequest = (id) => {
    repost({
      url: "resync/",
      body: {
        id,
        repostingData: false,
        userType: "user",
        resyncPO: isTypePo
      }
    })
      .unwrap()
      .then(() => {
        showNotification("Data Queued", 'success')
      })
      .catch(() => {
        showNotification("Something went wrong", 'error')
      })
  }

  return <>
    {syncDetailsInfo.show &&
      <SyncModal
        closeModal={closeSyncDetailsModal}
        rowData={syncDetailsInfo.data}
        swName={swName}
        isTypePo={isTypePo}
      />
    }
    <PageLayout
      title={t('Sync history')}
      description={t('SyncHistoryDescription')}
      noBottomBar
      showBack
    >
      <>
        <HistoricalSync isTypePo={isTypePo} />
        <>
          <>
            <SyncDynamicTable
              refreshData={refetch}
              rows={syncData}
              makeRequest={makeRequest}
              openSyncDetailsModal={openSyncDetailsModal}
              isTypePo={isTypePo}
            />
          </>
        </>
      </>
    </PageLayout>
  </>;
};
