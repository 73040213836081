import { FloatButton, Spin } from "antd";
import { useEffect } from "react";
import { useState } from "react";
import { useLogoutQuery } from "../../services/authService";
import { useSelector } from 'react-redux';
import amIVisible from "../../componentControl/registry";
import { Flex } from '@react-css/flex';
import { Text } from "./text";
import { BiLogOutCircle } from "react-icons/bi";

const Logout = ({ navigate }) => {

    const [isLoading, setLoading] = useState(false)
    const { isSuccess } = useLogoutQuery({}, { skip: !isLoading })
    const { isLogoutPending } = useSelector(state => state.auth)

    useEffect(() => {
        isSuccess && navigate("../login")
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess])

    const handleClick = () => {
        !isLogoutPending && setLoading(true);
    }

    const canShowLogout = amIVisible("logout button")

    if (!canShowLogout) {
        return <></>
    }

    return (
        <>
            <FloatButton onClick={handleClick} style={{ top: 0, width: "120px", height: "28px" }} shape="" description={
                <>
                    <Spin spinning={isLogoutPending} size="small">
                        <Flex>
                            <BiLogOutCircle style={{ marginRight: "5px", marginTop: "1px" }} />
                            <Text fontWeight={"bold"}>Log out</Text>
                        </Flex>
                    </Spin>
                </>
            } />
        </>
    );
}

export default Logout;