import "./auth.css"
import { Flex } from '@react-css/flex';
import { LogoBranding } from '../../components/custom/brand';
import { Button, Card, Input } from "antd";
import { Text } from "../../components/custom/text";
import Copyrights from '../../components/custom/copyright';
import { Formik, Form, useField } from "formik";
import * as Yup from 'yup';
import { useDispatch, useSelector } from "react-redux";
import { useState } from "react";
import useNotification from "antd/es/notification/useNotification";
import { useLoginMutation } from "../../services/authService";
import { setToken } from "../../slices/authSlice";
import { moveToNextScreen } from "../../utils/helpers/commonHelpers";
import { colors } from "../../style/color";
import useImage from "../../hooks/useImage";

const LoginPage = (props) => {
    const dispatch = useDispatch();
    const [sendLoginData] = useLoginMutation()
    const { showNotification } = useNotification()
    const [isLoading, setLoading] = useState(false)
    const { fullName } = useSelector(state => state.auth)
    const bgImg = useImage(`bgLogin_${colors?.color}.svg`)

    const isRefresh = useSelector(state => state?.auth?.isLogout);

    if (isRefresh) {
        window.location.reload();
    }

    const TextInput = (props) => {

        const [field, meta] = useField(props);

        let status = {};

        if (meta.touched && meta.error) {
            status = { type: 'error', message: meta.error };
        }

        return (
            <>
                <Input
                    status={status} {...field} {...props}
                    value={field.value === undefined ? '' : field.value}
                />
                {status &&
                    <Flex>
                        <Text fontWeight='bolder' mt="4px" color="red">
                            {meta.error}
                        </Text>
                    </Flex>
                }
            </>
        );
    };

    const createValidationSchema = () => {
        const schemaObject = {
            email: Yup.string()
                .email('Invalid email address')
                .required('Email  is required'),
            password: Yup.string()
                .required('Password is required'),
        };

        return Yup.object().shape(schemaObject);
    };

    const LoginForm = ({ formik, loading, ...props }) => {

        return <Form>
            <div style={{ marginBottom: "10px" }}>
                <Text mb="10px" fontWeight='bolder' fontSize="large">Email address</Text>
                <TextInput id="email" name="email" placeholder="Enter email" />
            </div>
            <div style={{ marginBottom: "10px" }}>
                <Text mb="10px" fontWeight='bolder' fontSize="large">Password</Text>
                <TextInput id="password" name="password" placeholder="Enter password" type="password" />
            </div>
            <Button
                type="primary"
                htmlType='submit'
                disabled={loading}
                loading={loading}
                style={{ width: "100%", margin: 0, marginTop: "8px" }}
                mb={2}
            >
                Login
            </Button>
        </Form>;
    };

    const onSubmit = (values) => {
        // Send data to backend
        setLoading(true)
        sendLoginData({
            email: values.email,
            password: values.password
        })
            .unwrap()
            .then((res) => {
                dispatch(setToken(res.token))
                moveToNextScreen(props.navigate, dispatch, '../dashboard', res);
            })
            .catch(({ data: res }) => {
                if (res?.error || res?.message) {
                    showNotification(res?.error || res?.message, "error")
                }
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const moveToSignup = () => {
        props.navigate('../signup');
    };

    return (
        <>
            <Flex class="main-login-layout">
                <Flex className="left" column>
                    <Flex className="logo-corner">
                        <LogoBranding isLogoOnly />
                        <Flex column>
                            <p className="title-brand">
                                {fullName} Accounting
                            </p>
                            <p className="desp-brand">
                                Eliminate manual data entry by automating your accounting tasks.
                            </p>
                        </Flex>
                    </Flex>
                    <Flex justifyCenter alignItemsCenter style={{ height: "100%" }}>
                        <Card className="main-card">
                            <>
                                <Text fontSize="xx-large" className="te" fontWeight="bolder" mb="1.5rem">
                                    Login
                                </Text>
                            </>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validationSchema={createValidationSchema()}
                                onSubmit={onSubmit}
                            >
                                {formik =>
                                    <LoginForm formik={formik} loading={isLoading} {...props} />
                                }
                            </Formik>
                            <>
                                <Flex justifyContent="center" style={{ marginTop: "20px" }}>
                                    Create a new account ? <Text color={colors.primary} fontWeight="bolder" link onClick={() => { moveToSignup() }} ml="6px">Signup</Text>
                                </Flex>
                            </>
                        </Card>
                    </Flex>
                    <div className="brand-slogan">
                        <Flex column>
                            <p className="slogan">#NoMoreWaiting</p>
                            <p className="slogan-2">for your Financial Data</p>
                        </Flex>
                    </div>
                    <Copyrights isFromAuth />
                </Flex>
                <Flex className="right">
                    <img alt="login" className="img-bg" src={bgImg} />
                </Flex>
            </Flex>
        </>
    );
}

export default LoginPage;