import { MapSalesByProducts } from '../mapSales/MapSalesByProducts';
import { MapPaymentMethods } from '../mapPaymentMethods/MapPaymentMethods';
import { MapDiscounts } from '../mapDiscounts/MapDiscounts';
import { MapTips } from '../mapTips/MapTips';
import { MapServiceCharges } from '../mapServiceCharges/MapServiceCharges';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { useTranslation } from 'react-i18next';
import { KeepInvoice } from '../keepInvoice/KeepInvoice';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import { useIsInputsOnlyLayout } from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { SplitPayment } from '../mapPaymentMethods/splitPayment';
import { TaxMapping } from '../mapTaxCodes/taxMapping';
import { Layout } from '../custom/Layout';
import { MapGiftcard } from '../mapGiftcard/mapGiftcard';
import { MapCreditSales } from '../mapCreditSales/mapCreditSales';
import { Alteration } from '../mapAlteration/mapAlteration';
import { MapBagFee } from '../mapBagFee/mapBagFee';
import { Shipping } from '../mapShipping/mapShipping';
import { MapDefaultTax } from '../mapTaxCodes/mapDefaultTax';

const target = getCurrentTarget();

const MapAccountsContent = (props) => {
  const { postingMethod } = props.formik.values;
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  /* Keep Invoice */
  let canShowKeepInvoice = amIVisible("keep invoice open for credit sales")

  if (canShowKeepInvoice) {
    canShowKeepInvoice = postingMethod !== 'journalEntry'
  }

  /* sales */
  let canShowSales = amIVisible("sales")

  /* payments */
  const canShowPayment = amIVisible('payment')

  /* discount */
  let canShowDiscount = amIVisible("discount")

  if (canShowDiscount) {
    canShowDiscount = postingMethod && !isInputsOnlyLayout
  }

  let showDefaultTax = false

  if (amIVisible("show default tax rates") && postingMethod === "journalEntry") {
    showDefaultTax = true
  }

  return (
    <div style={{ marginLeft: "1rem" }}>
      {canShowKeepInvoice && <KeepInvoice formik={props?.formik} />}
      {canShowSales && <MapSalesByProducts {...props} />}
      {canShowPayment && <MapPaymentMethods {...props} />}
      {showDefaultTax && <MapDefaultTax {...props} />}
      {amIVisible("split credit card payment by card type") && <SplitPayment formik={props?.formik} />}
      {amIVisible("tax rates") && !showDefaultTax && <TaxMapping {...props} />}
      {canShowDiscount && <MapDiscounts {...props} />}
      {amIVisible("tips") && <MapTips {...props} />}
      {amIVisible("giftCard") && <MapGiftcard {...props} />}
      {amIVisible("creditSales") && <MapCreditSales {...props} />}
      {amIVisible("alteration") && <Alteration {...props} />}
      {amIVisible("Bag Fee") && <MapBagFee {...props} />}
      {amIVisible("shipping") && <Shipping {...props} />}
      {amIVisible("service charge") && <MapServiceCharges {...props} />}
    </div>
  )
};

export const MapAccounts = (props) => {
  const { t } = useTranslation();

  return <Layout
    title={t('Map Accounts')}
    description={t('MapAccountsDescription', { target: target.fullName })}
    extra={
      <>
        <RefreshAccounts dirty={props?.formik?.dirty} />
      </>
    }
  >
    <MapAccountsContent {...props} />
  </Layout>;
};
