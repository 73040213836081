import { useState, useEffect } from 'react';
import { Formik, Form, useField } from 'formik';
import * as Yup from 'yup';
import ct from "countries-and-timezones"
import { useSignupMutation } from '../../services/authService';
import { useNotification } from '../../utils/helpers/notifications';
import { some } from 'lodash';
import { isEmpty } from 'lodash';
import signupSchema from "../authorizeLogin/signupSchema"
import { Text } from '../../components/custom/text';
import Copyrights from '../../components/custom/copyright';
import { Button, Input, Select } from 'antd';
import { Flex } from '@react-css/flex';
import { Card } from 'antd';
import { colors } from '../../style/color';
import { LogoBranding } from '../../components/custom/brand';
import { useSelector } from 'react-redux';
import useImage from '../../hooks/useImage';
import "./auth.css"
import PhoneNumber from 'react-phone-number-input/input';
import { getCountryCallingCode } from 'react-phone-number-input'
import { getTZ } from '../../utils/helpers/commonHelpers';

const getInitialValues = () => {
    let fields = {}

    for (let i = 0; i < signupSchema.length; i++) {
        fields[signupSchema[i]?.fieldName] = ''
    }

    return fields;
};

const TextInput = (props) => {
    const [field, meta, helpers] = useField(props);
    const [errMsg, setErrMsg] = useState();

    const { id, formik, name } = props;
    const country = formik?.values?.country === "" ? "US" : formik?.values?.country

    let status = {};

    const isPassField = ["password", "confirmPass"].includes(id)

    const [value, setValue] = useState(null)
    const [showPhoneNumberError, setPhoneNumberError] = useState({
        showInvalid: false,
        showRequired: false
    })

    if (Boolean(errMsg)) {
        status = { type: 'error', message: errMsg };
      }
    
    if (meta.touched && meta.error) {
        if (isPassField) {
            if (Boolean(errMsg)) {
              status = { type: 'error', message: errMsg };
            }
          } else {
            status = { type: 'error', message: meta.error };
          }
    }

    useEffect(() => {
        if (value !== null) {
            if (value.length === (" +" + getCountryCallingCode(country)).length) {
                setPhoneNumberError({
                    showInvalid: false,
                    showRequired: true
                })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value])

    const handlePhoneNumberChange = (val) => {
        console.log(val)
        let prefix = "+"
        if (country) {
            prefix = "+" + getCountryCallingCode(country)
        }
        if (val?.length < prefix?.length + 11) {
            setPhoneNumberError({
                showInvalid: false,
                showRequired: false
            })
        } else {
            setPhoneNumberError({
                showInvalid: true,
                showRequired: false
            })
        }
        helpers.setValue(val)
        setValue(val)
    }

    const handleOnValueChange = (evt) => {
        const val = evt.target.value
    
        if (isPassField) {
    
          let msg;
    
          if (Boolean(val)) {
            // need to contain atleast one upper case letter
            if (!(/[A-Z]/.test(val))) {
              msg = "Password should contain one capital letter"
    
              // need to contain atleast one lower case letter
            } else if (!(/[a-z]/.test(val))) {
              msg = "Password should contain one small letter"
    
              // need to check for on number
            } else if (!(/[0-9]/.test(val))) {
              msg = "Password should contain one number"
    
              // need to check for one special letter
            } else if (!(/[^a-zA-Z0-9]/.test(val))) {
              msg = "Password should contain one special character"
    
              // pwd need to be more than 8 character
            } else if (val?.length < 8) {
              msg = "Password should be more than 8 characters"
    
              // pwd need to be more than 8 character
            } else if (val?.length > 16) {
              msg = "Password should be less than 16 characters"
    
            } else if (["confirmPass"].includes(id)) {
              if (formik?.values?.password !== val) {
                msg = "Confirm password isn't matching with password"
              }
            }
          } else {
            msg = "Password is required"
    
          }
    
          setErrMsg(msg)
    
          helpers.setValue(val)
        } else {
    
          helpers.setValue(val)
        }
      }
    
    return (
        <div style={{ marginBottom: "11px" }}>
            {
                (name === "contactNumber" && false) ?
                    (
                        <>
                            <PhoneNumber
                                country={country}
                                onChange={handlePhoneNumberChange}
                                inputComponent={Input} // Antd Input Component
                                {...props}
                            />
                            {showPhoneNumberError.showInvalid && <Text style={{ fontSize: ".875rem" }} color="#DE2E37" mt=".3rem">Invalid Phone Number</Text>}
                            {showPhoneNumberError.showRequired && <Text style={{ fontSize: ".875rem" }} color="#DE2E37" mt=".3rem">Contact Phone Number is required</Text>}
                        </>
                    )
                    :
                    (
                        <>
                            <Input
                                status={status} {...field} {...props}
                                value={field.value === undefined ? '' : field.value}
                                onChange={handleOnValueChange}
                            />
                            {["password"].includes(id) && <Text mt={".4rem"} fontWeight={"bolder"} ml={".1rem"} fontSize={"xx-small"} color={"#524d4d91"}>
                                Note : Password should have at least 8 characters and be less than 16. It should also contain one capital letter, one small letter, one number, and one special character.
                            </Text>}
                            {
                                status.message &&
                                <Text fontWeight='bolder' mb="4px" mt="4px" color="red">
                                    {status.message}
                                </Text>
                            }
                        </>
                    )
            }
        </div>
    );
};

const SelectField = (props) => {
    const [field, meta, helpers] = useField(props);
    const [options, setOptions] = useState(props?.options ?? [])
    const selectedOption = options?.find(option => (option.value === meta.value));
    const { formik, name } = props;
    const country = formik?.values?.country
    let c = Object.assign({
        "US": null,
        "CA": null,
        "GB": null,
        "MX": null,
        "AU": null,
        "IN": null,
        "DE": null,
    }, ct.getAllCountries())

    const handleChange = (option) => {
        if (name !== "timeZone" && Object.keys(getInitialValues()).includes(name)) {
            formik.setFieldValue('timeZone', '')
            // formik.setFieldValue('contactNumber', '')
            formik.validateField('timeZone')
        }

        helpers.setValue(option);
    };

    useEffect(() => {
        if (name === "country" || name === "timeZone") {
            setOptions(name === "timeZone" ?
                getTZ(country)?.map(t => { return { label: t, value: t } }) ?? []
                : Object.keys(c).map(k => { return { label: c[k].name, value: c[k].id } }))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    useEffect(() => {
        if (country !== "" && name === "timeZone") {
            setOptions(getTZ(country)?.map(t => { return { label: t, value: t } }) ?? [])
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [country])

    let status = {}, hasError = false;

    if (meta.error) {
        status = { type: 'error', message: meta.error };
        hasError = true
    }

    return (
        <div style={{ marginBottom: "11px" }}>
            <Select
                {...field}
                {...props}
                showSearch
                filterOption={(input, option) => (option?.label?.toLowerCase() ?? '').includes(input?.toLowerCase())}
                autoComplete="none"
                status={status}
                hasError={hasError}
                options={options}
                onChange={handleChange}
                isDisabled={country === "" && name === "timeZone"}
                placeholder={`Enter ${props.label ?? "label"}`}
                value={selectedOption || undefined}
            />
            {
                status.message &&
                <Text fontWeight='bolder' mb="4px" mt="4px" color="red">
                    {status.message}
                </Text>
            }
        </div>
    );
}

const createValidationSchema = () => {

    let validation = {}

    for (let i = 0; i < signupSchema.length; i++) {
        const { name, fieldName, required } = signupSchema[i]

        if (required) {
            switch (fieldName) {
                case "contactNumber":
                    validation[fieldName] = Yup.string()
                        .required('Phone number is required')
                        .matches(/^\d+$/, 'Only numeric digits are accepted')
                        .length(10, 'Phone number must have 10 digits')
                    break
                case "email":
                    validation[fieldName] = Yup.string()
                        .email('Invalid email address')
                        .required('Email is required')
                    break
                case "password":
                    validation[fieldName] = Yup.string()
                        .required('Password is required')
                        .max(16, 'No more than 16 characters')
                        .min(6, 'At least 6 characters required')
                        .matches(
                            '(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[#$@$!%*?&]).{6,16}',
                            'Must have one capital character, one number and one special character'
                        )
                    break
                case "confirmPass":
                    validation[fieldName] = Yup.string()
                        .required('Please confirm your password')
                        .oneOf([Yup.ref('password')], 'Does not match password')
                    break
                default:
                    validation[fieldName] = Yup.string()
                        .required(`${name} is required`)
            }
        }
    }

    return Yup.object().shape(validation);
};

const SignupForm = ({ formik, loading }) => {

    const { country, contactNumber } = formik.values

    const checkPhoneNumber = () => {
        if (country !== '') {
            const prefix = "+" + getCountryCallingCode(country)
            if (!(contactNumber?.length <= prefix?.length + 10 && contactNumber?.length >= prefix?.length + 10)) {
                return true
            }
        }
        return false;
    }

    let isDisabled = (!isEmpty(formik.errors) || some(formik.values, isEmpty));

    return <Form>
        {
            signupSchema?.map(field => {
                const { name, fieldName, type } = field

                return (
                    <div mb={5}>
                        <Text mb="7px" fontWeight='bolder'>{name}</Text>
                        {
                            type !== "option" ?
                                <TextInput formik={formik} id={fieldName} name={fieldName} type={type} placeholder={`Enter ${name}`} />
                                :
                                <SelectField
                                    id={fieldName}
                                    name={fieldName}
                                    label={name}
                                    formik={formik}
                                    style={{ width: "100%" }}
                                />
                        }
                    </div>
                )
            })
        }
        <Button
            type="primary"
            htmlType='submit'
            disabled={isDisabled}
            loading={loading}
            style={{ width: "97.1%", marginTop: "8px" }}
            mb={2}
        >
            Sign up
        </Button>
    </Form>;
};

export const SignupPage = (props) => {
    const [sendPayload] = useSignupMutation()
    const { showNotification } = useNotification();
    const [isLoading, setLoading] = useState(false)
    const { fullName } = useSelector(state => state.auth)
    const bgImg = useImage(`bgLogin_${colors?.color}.svg`)

    const onSubmit = (values) => {
        setLoading(true)

        const prefix = "+" + getCountryCallingCode(values?.country ?? "US")
        const payload = {
            ...values,
            // contactNumber: values?.contactNumber?.substring(prefix?.length, values?.contactNumber?.length)
        }

        sendPayload(payload)
            .unwrap()
            .then(res => {
                res.message ? showNotification(res.message, "success") : showNotification('User Registered!', "success")
                props.navigate('../login');
            })
            .catch(res => {
                showNotification(res?.data?.error, "error")
            })
            .finally(() => {
                setLoading(false)
            })
    };

    const moveToLogin = () => {
        props.navigate('../login');
    };

    return (
        <>
            <Flex class="main-login-layout">
                <Flex className="left" column>
                    <Flex className="logo-corner">
                        <LogoBranding isLogoOnly />
                        <Flex column>
                            <p className="title-brand">
                                {fullName} Accounting
                            </p>
                            <p className="desp-brand">
                                Eliminate manual data entry by automating your accounting tasks.
                            </p>
                        </Flex>
                    </Flex>
                    <Flex justifyCenter className='left-side' style={{ marginTop: "30px" }}>
                        <Card className="main-card">
                            <>
                                <Text fontSize="xx-large" fontWeight="bolder" mb=".5rem">
                                    Signup
                                </Text>
                            </>
                            <Formik
                                initialValues={{
                                    email: '',
                                    password: '',
                                }}
                                validationSchema={createValidationSchema()}
                                onSubmit={onSubmit}
                            >
                                {formik =>
                                    <SignupForm formik={formik} loading={isLoading} {...props} />
                                }
                            </Formik>
                            <>
                                <Flex justifyContent="center" style={{ marginTop: "20px" }}>
                                    Already have an account ? <Text color={colors.primary} fontWeight="bolder" link onClick={moveToLogin} ml="6px">Login</Text>
                                </Flex>
                            </>
                        </Card>
                    </Flex>
                    <div className="brand-slogan">
                        <Flex column>
                            <p className="slogan">#NoMoreWaiting</p>
                            <p className="slogan-2">for your Financial Data</p>
                        </Flex>
                    </div>
                    <Copyrights />
                </Flex>
                <Flex className="right">
                    <img alt="login" className="img-bg" src={bgImg} />
                </Flex>
            </Flex>
        </>
    );
};