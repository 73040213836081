import { useTranslation } from 'react-i18next';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import { MappingOptions } from './MappingOptions';
import { ShippingSection } from './ShippingSection';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import useConnectionMode from '../../hooks/useConnectionMode';
import useIsInputsOnlyLayout from '../../hooks/useIsInputsOnlyLayout';
import amIVisible from '../../componentControl/registry';
import { PoTaxMapping } from '../mapTaxCodes/PoTaxMapping';
import { MapDefaultIndividualCogsInventory } from './MapDefaultIndividualCogsInventory';
import { Layout } from '../custom/Layout';
import { Divider } from 'antd';
import { useSelector } from 'react-redux';

export const InventoryCogsSwitch = ({ ...props }) => {

  const { formik } = props

  const {
    POInventoryCogs,
    isSentPurchaseOrder,
    trackInventory,
    isSentCogs,
  } = formik.values;

  const [, swName] = useConnectionMode();
  const { t } = useTranslation();

  const country = useSelector(state => state.auth.country)

  let showPoTaxes = amIVisible("po taxes")

  if (country === "US") {
    showPoTaxes = false
  }

  // Auto disable track inventory
  useEffect(() => {
    if ((isSentCogs && trackInventory) || (!isSentPurchaseOrder && trackInventory)) {
      formik.setFieldValue('trackInventory', false);
    }
  }, [formik, isSentCogs, trackInventory, isSentPurchaseOrder]);

  const showInventory = amIVisible("inventory option")

  const description = `Purchase Invoice ${showInventory ? ", inventory" : ""} and COGS can be mapped to different ${swName} accounts. ${(POInventoryCogs) ?
    t('PO, inventory and COGS desc.1') : ''}`;

  return <SwitchLayout
    heading={`Purchase Invoice ${showInventory ? ", inventory" : ""} and Cost of Goods Sold`}
    description={
      description
    }
    switchFieldName="POInventoryCogs"
    isSwitchOn={POInventoryCogs}
  >
    <div>
      <MappingOptions
        isSentPurchaseOrder={isSentPurchaseOrder}
        trackInventory={trackInventory}
        isSentCogs={isSentCogs}
      />
    </div>
    <>
      {isSentPurchaseOrder && POInventoryCogs &&
        <>
          <Divider />
          <div>
            <ShippingSection />
            <div style={{ margin: "0 .75rem" }}>
              {showPoTaxes && <PoTaxMapping {...props} />}
            </div>
          </div>
        </>
      }
      {(isSentPurchaseOrder || isSentCogs) && POInventoryCogs &&
        <>
        <Divider />
          <div>
            <MapDefaultIndividualCogsInventory {...props} />
          </div>
        </>
      }
    </>
  </SwitchLayout>;
};

const CogsInventorySectionContent = (props) => {
  return <>
    <InventoryCogsSwitch {...props} />
  </>;
};

export const CogsInventorySection = (props) => {
  const { t } = useTranslation();
  const [isInputsOnlyLayout] = useIsInputsOnlyLayout();

  return <Layout
    title={t('Map Purchase Orders, COGS and Inventory')}
    description={t('MapAccountsDescription', { target: getCurrentTarget().fullName })}
    extra={
      <>
        {(isInputsOnlyLayout === false) && <RefreshAccounts dirty={props?.formik?.dirty} />}
      </>
    }
    mb="2.25rem"
    mt="2.25rem"
  >
    <CogsInventorySectionContent {...props} />
  </Layout>;
};