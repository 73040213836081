import { Select } from "antd"
import { useField } from "formik";
import { useSelector } from 'react-redux';
import Flex from '@react-css/flex'
import './field.css'

export const Dropdown = (props) => {

    const {
        hasErrorInitial,
        disabled,
        isLoading: propsLoading
    } = props

    const [field, meta, helpers] = useField(props.name);
    const selectedOption = props?.options?.find(option => (option?.value === meta?.value))?.value;
    const { isGlobalLoading, isAccountLoading, isRefreshAccount, hasError: anyError } = useSelector(state => state.dashboard);
    const isLoading = isGlobalLoading || propsLoading || isAccountLoading || isRefreshAccount;
    let status = hasErrorInitial ? 'error' : false;

    if (anyError && meta.error && !props.disabled) {
        status = 'error'
    }

    const handleChange = (value) => {
        helpers.setValue(value ?? '');
    };

    return (
        <>
            <Flex column>
                <Select
                    {...field}
                    {...meta}
                    style={{ maxWidth: 320 }}
                    allowClear
                    showSearch
                    value={selectedOption}
                    loading={isLoading}
                    status={status}
                    disabled={disabled || isLoading}
                    placeholder={"Select an option"}
                    onChange={handleChange}
                    filterOption={(input, option) => (option?.label ?? '')?.toLowerCase().includes(input?.toLowerCase())}
                    {...props}
                    autoComplete="none"
                />
                {status && 
                    <Flex>
                        <p className="required-label">
                            Required
                        </p>
                    </Flex>
                }
            </Flex>
        </>
    )
}