import { Button, Modal } from "antd"
import { useState } from "react"
import { TbPlugConnectedX } from "react-icons/tb"
import useConnectionMode from "../../../hooks/useConnectionMode";
import { useConnectionModeMutation } from "../../../services/authService"
import { Text } from '../../custom/text';
import { AiFillWarning } from "react-icons/ai"
import { Flex } from '@react-css/flex';

export const DisconnectBtn = ({ isLoading, isDisabled, navigate, onClickCallBack, onSuccessCallback, description }) => {

    const [showModal, setShowModal] = useState(false)
    const [deleteConnection] = useConnectionModeMutation()
    const [, swName] = useConnectionMode()

    const handleConfirm = () => {
        setShowModal(false);
        onClickCallBack && onClickCallBack()

        deleteConnection({
            method: "DELETE"
        })
            .unwrap()
            .then(() => {
                !onSuccessCallback && navigate('../user-onboard');
                onSuccessCallback()
            })
    };

    return (
        <>
            <Modal title={<Flex alignItems={"flex-end"}>
                <AiFillWarning color="red" fontSize={"26px"} style={{ marginRight: "8px" }} />
                Disconnect Warning
            </Flex>} closable={false} open={showModal} onOk={handleConfirm} okText="Disconnect" okType="danger" onCancel={() => setShowModal(false)}>
                <Flex>
                    <Text mb="10px" mt="10px" fontWeight="bolder" fontSize="large">
                        Are you sure you want to disconnect {swName} ?
                    </Text>
                </Flex>
                <Text mb="50px">
                    {description ? description :
                        "All configured accounts mapping, settings, and sync history will be lost. This action cannot be undone."
                    }
                </Text>
            </Modal>
            <Button
                danger
                disabled={isDisabled}
                loading={isLoading}
                onClick={() => {
                    !(isLoading || showModal) && setShowModal(true)
                }}
                pl="0.563rem"
                pt="0.625rem"
                pb="0.625rem"
                className='btn-with-icon'
                icon={<TbPlugConnectedX className='mc-btn-icon' />}
            >
                Disconnect
            </Button>
        </>
    )
}