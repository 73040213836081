import React from 'react';
import { Autocomplete } from '../fields';
import { useGetLocationsQuery } from '../../services/dashboardService';
import { useTranslation } from 'react-i18next';
import useConnectionMode from '../../hooks/useConnectionMode';
import { SwitchLayout } from '../custom/SwitchLayout';
import { useEffect } from 'react';
import { Text } from '../custom/text';
import { LabelRequired } from '../custom/LabelRequired';
import { useNotification } from '../../utils/helpers/notifications';
import { Flex } from '@react-css/flex';
import { Box } from '../custom/box';
import { Button } from 'antd';
import { IoIosRefresh } from 'react-icons/io';
import { colors } from '../../style/color';

export const MultipleClass = ({ formik }) => {
    const { isMapClass, locations, classes } = formik.values;
    const [, swName] = useConnectionMode();
    const { t } = useTranslation();

    let { data: classList, isFetching: fetchingClass, isLoading: loadingClass, refetch: refetchLocations } = useGetLocationsQuery({ class: true }, { skip: !isMapClass ?? true })
    let { data: locationsList, isFetching: fetchingLocation, isLoading: loadingLocation, refetch: refetchClasses } = useGetLocationsQuery({ class: false }, { skip: !isMapClass ?? true })

    const isLoading = fetchingClass || fetchingLocation || loadingClass || loadingLocation

    const { showNotification } = useNotification()

    useEffect(() => {
        if ((classList && classList?.length === 0) && !(locations && locations !== '')) {
            showNotification('Class not enabled in accounting software', 'error');
        }

        if (locationsList && locationsList?.length === 0 && !(classes && classes !== '')) {
            showNotification('Location not enabled in accounting software', 'error');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [classList])

    return (
        <SwitchLayout
            heading={t('Location tracking', { swName })}
            description={t('ClassMappingKSeries', { swName })}
            switchFieldName="isMapClass"
            isSwitchOn={isMapClass}
            isLoading={isLoading}
        >
            <Flex>
                <Box width="49%" pr="1.1rem">
                    <LabelRequired notRequired>
                        {t('Select a Location')}
                    </LabelRequired>
                    <Autocomplete
                        id="locationMapping"
                        name="locations"
                        isLoading={!classList || isLoading}
                        options={locationsList}
                        disabled={classes && classes !== ''}
                        placeholder={t('Select a location')}
                        formik={formik}
                    />
                </Box>
                <Box width="49%" pr="1.1rem">
                    <LabelRequired notRequired>
                        {t('Select a Class')}
                    </LabelRequired>
                    <Autocomplete
                        id="classMapping"
                        name="classes"
                        isLoading={!classList || isLoading}
                        disabled={locations && locations !== ''}
                        options={classList}
                        placeholder={t('Select a class')}
                        formik={formik}
                    />
                </Box>
            </Flex>
            <Flex justifySpaceBetween mt=".4rem" ml=".2rem" style={{ marginTop: "1rem", marginLeft: ".3rem" }}>
                <Flex><Text fontWeight={"bolder"} mr=".3rem">Required : </Text> Either Location mapping or Class Mapping.</Flex>
                <Flex>
                    {!(!classList || isLoading) && <IoIosRefresh style={{ color: colors.primary, paddingTop: "5px" }} />}
                    <Button onClick={() => {
                        refetchClasses()
                        refetchLocations()
                    }} loading={!classList || isLoading} size='small' type='link' style={{ padding: "0 1px", color: colors.primary }}>Refresh Location & Class</Button>
                </Flex>
            </Flex>
        </SwitchLayout>
    )
}