import { CheckboxField } from '../fields';
import Flex from '@react-css/flex'
import { useSelector } from 'react-redux';
import { Box } from '../custom/box';
import { Alert } from 'antd';
import amIVisible from '../../componentControl/registry';
import { Text } from '../custom/text';

export const MappingOptions = ({ isSentPurchaseOrder, trackInventory, isSentCogs }) => {
  const disableInventory = isSentCogs || (!isSentPurchaseOrder);
  const hasSubmittedWithErrors = useSelector(state => state.dashboard.hasError);
  const showError = !(isSentPurchaseOrder || isSentCogs) && hasSubmittedWithErrors;

  const showInventory = amIVisible("inventory option")

  return <>
    <Flex column>
      <Flex.Item>
        <CheckboxField
          id="isSentPurchaseOrder"
          name="isSentPurchaseOrder"
          label={`Purchase Invoice`}
        />
      </Flex.Item>
      {showInventory && <Flex.Item>
        <CheckboxField
          id="trackInventory"
          name="trackInventory"
          disabled={disableInventory}
          label={"Track Inventory"}
        />
      </Flex.Item>}
      <Flex.Item>
        <CheckboxField
          id="isSentCogs"
          name="isSentCogs"
          label={`Track Cost of Goods Sold`}
        />
      </Flex.Item>
    </Flex>
    {showError &&
      <Box width="49%" pr="1.1rem" pt="1.5rem">
        <Alert
          type="error"
          noCloseBtn
          message={<Text>
            Select an option to map your accounts
          </Text>}
          showIcon
        />
      </Box>
    }
  </>;
};
