import { createSlice } from "@reduxjs/toolkit";
import { authService } from "../services/authService";
import { dashboardApi } from '../services/dashboardService';
import { dashboardTransformer } from "../utils/helpers/dashboardTransformer";
import { getConnectionModeName } from '../utils/helpers/commonHelpers';
import { optionsParser, parseAdvancedMapping, parseCogsMapping, parseIndividualMapping } from "../utils/helpers/individualMappingParser";

const initialState = {
    accounts: [],
    taxes: {},
    poTaxes: {},
    paymentsLabels: [],
    discountsLabels: [],
    isTaxAgency: false,
    isAccountLoading: false,
    isFetchingSyncCount: true,
    syncCounts: {
        syncSuccessCount: 0,
        syncFailedCount: 0
    },
    refetchSyncCount: false,
    isTaxFetching: true,
    isPoTaxFetching: true,
    isGlobalLoading: true,
    connectionMode: "",
    fetchedConfig: {},
    floorList: [],
    currentComponent: 'dash',
    currentSw: "",
    isPostingMethodChanged: false,
    refreshAccounts: false,
    currentPostingMethod: '',
    isSynced: false,
    categoriesLabels: [],
    productLabels: [],
    isRefreshAccount: false,
    hasError: false,
    hasPaymentError: "",
    companyName: "",
    isSessionExpired: false,
    showPostingTypeJEWarning: false
}

export const dashboardSlice = createSlice({
    name: 'dashboard',
    initialState,
    reducers: {
        resetDashboardPage: () => {
            return initialState
        },
        setIsTaxAgency: (state, { payload: isTaxAgency }) => {
            return {
                ...state,
                isTaxAgency
            }
        },
        setPostingTypeJEWarning: (state, { payload: showPostingTypeJEWarning }) => {
            return {
                ...state,
                showPostingTypeJEWarning
            }
        },
        setCurrentComponent: (state, { payload }) => {
            state.currentComponent = payload
        },
        setConnectionMode: (state, { payload: connectionMode }) => {
            return {
                ...state,
                connectionMode
            }
        },
        setFetchedConfig: (state, { payload }) => {
            return {
                ...state,
                fetchedConfig: payload
            }
        },
        setPaymentLabels: (state, { payload }) => {
            return {
                ...state,
                paymentsLabels: payload
            }
        },
        setCurrentPostingMethod: (state, { payload: currentPostingMethod }) => {
            return {
                ...state,
                currentPostingMethod,
            }
        },
        setIsPostingMethodChanged: (state, { payload: isPostingMethodChanged }) => {
            return {
                ...state,
                isPostingMethodChanged
            }
        },
        setRefreshAccounts: (state, { payload: refreshAccounts }) => {
            return {
                ...state,
                refreshAccounts
            }
        },
        setRefetchAccount: (state, { payload: isAccountLoading }) => {
            return {
                ...state,
                isAccountLoading,
                isRefreshAccount: isAccountLoading
            }
        },
        setGlobalLoading: (state, { payload: isGlobalLoading }) => {
            return {
                ...state,
                isGlobalLoading
            }
        },
        setRefetchSyncCount: (state, { payload: refetchSyncCount }) => {
            if (!state.isFetchingSyncCount) {
                return {
                    ...state,
                    refetchSyncCount
                }
            } else {
                return {
                    ...state
                }
            }
        },
        setPaymentError: (state, { payload: hasPaymentError }) => {
            return {
                ...state,
                hasPaymentError
            }
        },
        setSessionExpired: (state) => {
            return {
                ...state,
                isSessionExpired: true
            }
        }
    },
    extraReducers: (builder) => {

        builder
        /* isCompanyAuthorize */
        .addMatcher(
            authService.endpoints.isCompanyAuthorirzed.matchFulfilled, (state, { payload: { companyName } }) => {
                return {
                    ...state,
                    companyName
                }
            }
        )

        builder
            /* accounts api */
            .addMatcher(
                dashboardApi.endpoints.getAccounts.matchPending, (state) => {
                    return {
                        ...state,
                        isAccountLoading: true
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.getAccounts.matchFulfilled, (state, { payload: accounts }) => {
                    return {
                        ...state,
                        accounts,
                        isAccountLoading: false,
                        isRefreshAccount: false,
                        showPostingTypeJEWarning: false,
                        refreshAccounts: false
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.getAccounts.matchRejected, (state, arg) => {

                if (arg?.error) {
                    if (arg?.error?.name === "ConditionError" && arg?.error?.message === "Aborted due to condition callback returning false.") {
                        return {
                            ...state
                        }
                    }
                }

                    return {
                        ...state,
                        accounts: [],
                        isAccountLoading: false,
                        isRefreshAccount: false,
                        refreshAccounts: false
                    }
                }
            )

            // tax accounts
            .addMatcher(
                dashboardApi.endpoints.getTaxes.matchPending, (state) => {
                    return {
                        ...state,
                        isTaxFetching: true
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.getTaxes.matchFulfilled, (state, { payload: taxes }) => {
                    const taxLabels = parseIndividualMapping(taxes?.LSTaxRates, state?.isPostingMethodChanged ? {} : state?.fetchedConfig?.tax)
                    const taxAccounts = optionsParser(taxes?.accountingSoftware)

                    let extra = {}

                    if (!state?.isPostingMethodChanged) {
                        extra = {
                            fetchedConfig: { ...state.fetchedConfig, taxes: taxLabels, poTaxMapping: state?.poTaxes?.taxLabels }
                        }
                    }

                    return {
                        ...state,
                        taxes: { taxLabels, taxAccounts, ...taxes },
                        ...extra,
                        isTaxFetching: false
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.getPoTaxes.matchRejected, (state) => {
                    return {
                        ...state,
                        isPoTaxFetching: false
                    }
                }
            )

            // po tax api 
            .addMatcher(
                dashboardApi.endpoints.getPoTaxes.matchPending, (state) => {
                    return {
                        ...state,
                        isPoTaxFetching: true
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.getPoTaxes.matchFulfilled, (state, { payload: taxes }) => {
                    let config = state?.fetchedConfig

                    let taxLabels = parseIndividualMapping(taxes?.LSTaxRates, Object.entries(config?.poTaxes ?? {}).length > 0 ? state?.fetchedConfig?.poTaxes : undefined)

                    let taxAccounts = optionsParser(taxes?.accountingSoftware)

                    if (config?.POInventoryCogs) {
                        config = { ...config, poTaxMapping: taxLabels }
                    }

                    let extra = {}

                    if (!state?.isPostingMethodChanged) {
                        extra = { fetchedConfig: config }
                    }

                    return {
                        ...state,
                        poTaxes: { taxLabels, taxAccounts, ...taxes },
                        isPoTaxFetching: false,
                        ...extra
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.getPoTaxes.matchRejected, (state) => {
                    return {
                        ...state,
                        isPoTaxFetching: false
                    }
                }
            )

            // config get api
            .addMatcher(
                dashboardApi.endpoints.getDashValues.matchFulfilled, (state, { payload }) => {
                    let floorMapping = {}
                    if (payload.splitRevenueRecordByFloor) {
                        floorMapping = state.floorList.map((floor) => {
                            return {
                                ...floor,
                                location: payload?.attributes?.floorLocationMapping ? payload?.attributes?.floorLocationMapping[floor?.value] : "location",
                                class: payload?.attributes?.floorClassMapping ? payload?.attributes?.floorClassMapping[floor?.value] : "",
                            }
                        })
                    }

                    const fetchedConfig = dashboardTransformer({ ...payload?.attributes, ...payload, floorMapping }, state)

                    return {
                        ...state,
                        fetchedConfig,
                        currentPostingMethod: fetchedConfig?.postingMethod,
                        connectionMode: payload?.connectionMode,
                        currentSw: getConnectionModeName((t) => t, payload?.connectionMode),
                        isGlobalLoading: false,
                        isSynced: payload?.isConfigured,
                    }
                }
            )

            // payment api
            .addMatcher(
                dashboardApi.endpoints.getPaymentMethod.matchFulfilled, (state, { payload }) => {

                    let payments = state?.fetchedConfig?.payments ?? {}

                    const paymentsLabels = parseIndividualMapping(payload, payments)

                    let fetchedConfig = state.fetchedConfig;

                    if (payments) {
                        fetchedConfig = { ...fetchedConfig, paymentsMapping: paymentsLabels }
                    }

                    return {
                        ...state,
                        paymentsLabels,
                        fetchedConfig
                    }
                }
            )

            // discount api
            .addMatcher(
                dashboardApi.endpoints.getDiscounts.matchFulfilled, (state, { payload }) => {

                    let discounts = state?.fetchedConfig?.discounts

                    const discountsLabels = parseIndividualMapping(payload, discounts)

                    let fetchedConfig = state.fetchedConfig;

                    if (discounts) {
                        fetchedConfig = { ...fetchedConfig, discountsMapping: discountsLabels }
                    }

                    return {
                        ...state,
                        discountsLabels,
                        fetchedConfig
                    }
                }
            )

            // items api
            .addMatcher(
                dashboardApi.endpoints.getItems.matchFulfilled, (state, { payload }) => {

                    let mapping = []

                    let labels = payload?.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })

                    const { items, itemSpecificInventoryCogsAccounts } = state?.fetchedConfig

                    const { currentComponent } = state

                    if (currentComponent === "sales product mapping") {
                            mapping = parseAdvancedMapping(payload, items)
                            return {
                                ...state,
                                fetchedConfig: {
                                    ...state.fetchedConfig,
                                    itemsMapping: mapping
                                },
                                productLabels: labels,
                            }
                    } else {
                        mapping = parseCogsMapping(payload, itemSpecificInventoryCogsAccounts)
                        return {
                            ...state,
                            fetchedConfig: {
                                ...state.fetchedConfig,
                                poItemsMapping: mapping
                            },
                            productLabels: labels,
                        }
                    }
                }
            )

            .addMatcher(
                dashboardApi.endpoints.getCategories.matchFulfilled, (state, { payload, meta }) => {

                    const { 
                        isParentCategory,
                        swapSuperParentCategoryFromProducts,
                        categories,
                        itemsLevel,
                        categorySpecificInventoryCogsAccounts,
                        poItemsLevel,
                     } = state?.fetchedConfig

                     const { currentComponent } = state

                    const isProductLvlMapping = Boolean(meta?.arg?.originalArgs?.productLevel)

                    const isSalesMapping = ["sales category mapping", "sales product level mapping"]?.includes(currentComponent)

                    const mappingData = isProductLvlMapping ? itemsLevel : categories
                    const mappingDataLabel = isProductLvlMapping ? "itemsLevelMapping" : "categoriesMapping"

                    const mappingLabelName = isProductLvlMapping ? "itemsLevelLabels" : "categoriesLabels"

                    const poMappingData = isProductLvlMapping ? poItemsLevel : categorySpecificInventoryCogsAccounts
                    const poMappingDataLabel = isProductLvlMapping ? "poItemsLevelMapping" : "poCategoriesMapping"

                    let mapping = []
                    let category = isSalesMapping ? isParentCategory : swapSuperParentCategoryFromProducts

                    let labels = payload?.map((item) => {
                        return {
                            label: item.name,
                            value: item.id
                        }
                    })

                    if (isSalesMapping) {

                        if (Number(meta?.arg?.originalArgs?.superParentCategory) === Number(isParentCategory)) {
                            mapping = parseAdvancedMapping(payload, mappingData)
                            return {
                                ...state,
                                fetchedConfig: {
                                    ...state.fetchedConfig,
                                    [mappingDataLabel]: mapping,
                                    isParentCategory: category
                                },
                                [mappingLabelName]: labels
                            }
                        }
                    } else {
                        if (Number(meta?.arg?.originalArgs?.superParentCategory) === Number(swapSuperParentCategoryFromProducts)) {
                            mapping = parseCogsMapping(payload, poMappingData)
                            return {
                                ...state,
                                fetchedConfig: {
                                    ...state.fetchedConfig,
                                    [poMappingDataLabel]: mapping,
                                    swapSuperParentCategoryFromProducts: category
                                },
                                [mappingLabelName]: labels
                            }
                        }
                    }

                    return {
                        ...state,
                        [mappingLabelName]: labels
                    }
                }
            )

            // config post api 
            .addMatcher(
                dashboardApi.endpoints.sendPayload.matchPending, (state, action) => {
                    return {
                        ...state,
                        isGlobalLoading: true,
                        hasPaymentError: "",
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.sendPayload.matchFulfilled, (state, action) => {
                    const extra = {}

                    if (state.currentComponent === "dash") {
                        extra.paymentsLabels = []
                    }

                    return {
                        ...state,
                        fetchedConfig: dashboardTransformer(action?.meta?.arg?.originalArgs, state),
                        isGlobalLoading: false,
                        showPostingTypeJEWarning: false,
                        isSynced: Boolean(action?.payload?.message),
                        isPostingMethodChanged: false,
                        hasError: Boolean(action?.payload?.error),
                        ...extra
                    }
                }
            )
            .addMatcher(
                dashboardApi.endpoints.sendPayload.matchRejected, (state, action) => {
                    return {
                        ...state,
                        isGlobalLoading: false
                    }
                }
            )
            // isCompanyAuthorize api
            .addMatcher(
                authService.endpoints.authorize.matchFulfilled, (state, { payload }) => {
                    let floorList = []
                    if (payload?.multipleFloor) {
                        floorList = payload.floorDetail.map(item => {
                            return {
                                label: item.name,
                                value: item.id
                            }
                        })
                    }

                    return {
                        ...state,
                        multipleFloor: payload?.multipleFloor,
                        floorList
                    }
                }
            )

            /* Sync Count */
            .addMatcher(
                dashboardApi.endpoints.getSyncCounts.matchPending, (state) => {
                    return {
                        ...state,
                        isFetchingSyncCount: true
                    }
                }
            )

            .addMatcher(
                dashboardApi.endpoints.getSyncCounts.matchFulfilled, (state, { payload: syncCounts }) => {
                    return {
                        ...state,
                        syncCounts,
                        isFetchingSyncCount: false,
                        refetchSyncCount: false,
                    }
                }
            )

            .addMatcher(
                dashboardApi.endpoints.getSyncCounts.matchRejected, (state) => {
                    return {
                        ...state,
                        isFetchingSyncCount: false,
                        refetchSyncCount: false,
                    }
                }
            )

    }
});

export const {
    setFetchedConfig,
    setCurrentComponent,
    setPaymentLabels,
    setIsPostingMethodChanged,
    setCurrentPostingMethod,
    setRefreshAccounts,
    setRefetchAccount,
    resetDashboardPage,
    setConnectionMode,
    setGlobalLoading,
    setRefetchSyncCount,
    setPaymentError,
    setSessionExpired,
    setPostingTypeJEWarning
} = dashboardSlice.actions

export default dashboardSlice.reducer;

export const allAccounts = (state) => state.accounts;
export const isTaxAgency = (state) => state.isTaxAgency;
export const globalLoading = (state) => state.isGlobalLoading;
export const isAccountsLoading = (state) => state.isAccountsLoading;
export const taxAccounts = (state) => state.taxes;