import { Modal } from "antd"
import { Flex } from '@react-css/flex';
import { AiFillWarning } from "react-icons/ai";
import { Text } from '../../custom/text';
import { useSelector } from 'react-redux';

export const SessionTimeoutError = ({ navigate }) => {

    const { isSessionExpired } = useSelector(state => state.dashboard)

    const { token } = useSelector(state => state.auth)

    if (!token) {
      navigate("../login")
    }

    return (
        <Modal title={
          <Flex alignItems={"flex-end"}>
            <AiFillWarning color="red" fontSize={"26px"} style={{ marginRight: "8px" }} />
            Session expired
          </Flex>
        } footer={null} open={isSessionExpired} closable={false} maskClosable={false} centered>
          <Text fontWeight='bolder' fontSize='large'>
            The session has timed out, please refresh screen to access it.
          </Text>
        </Modal>
      )
}