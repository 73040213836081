import useCurrency from '../../hooks/useCurrency';
import { Spin, Table } from 'antd';
import { Text } from '../custom/text';

export const PaymentsTable = ({ isLoading, heading, mappingType, isJeLayout, ...props }) => {
  const { data } = props;
  const { toCurrency } = useCurrency();

  let columns = [
    {
      title: "Account",
      dataIndex: "account",
      key: "account"
    },
    {
      title: 'Date',
      dataIndex: "date",
      key: "date"
    },
  ]
  
  const jeLayout  = [
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => toCurrency(item.amount)
    },
    {
      title: "Balance",
      dataIndex: "balance",
      key: "balance"
    },
    {
      title: "Payment",
      dataIndex: "name",
      key: "name"
    },
  ]
  
  const noJeLayout = [
    {
      title: "Reference Number",
      dataIndex: "referenceNumber",
      key: "referenceNumber"
    },
    {
      title: "Payment Method",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => toCurrency(item.amount)
    },
  ]

  if (isJeLayout) {
    columns = [...columns, ...jeLayout]
  } else {
    columns = [...columns, ...noJeLayout]
  }

  return <>
    {
      <Spin spinning={!isLoading}>
        {data !== null && <Table style={{ marginTop: "20px" }} caption={
          <Text fontWeight='bolder' fontSize='large'>{heading}</Text>
        } columns={columns} dataSource={data} pagination={false} />}
      </Spin>
    }
  </>;
};
