import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _, { random } from 'lodash';
import useAccounts from '../../../hooks/useAccounts';
import { useGetItemsQuery } from '../../../services/dashboardService';
import { FullPageWrapper } from '../../custom/IndividualMappingLayout';
import amIVisible from '../../../componentControl/registry';
import { useEffect, useState } from 'react';

export const IndividualPoProductMapping = ({ formik }) => {

    const { t } = useTranslation()

    const [isLoadingAccounts, inventoryAccounts] = useAccounts('inventory');
    
    const [, cogsAccounts] = useAccounts('cogs');

    const [, inventoryCogs] = useAccounts('inventoryCogs');

    let listOfAccounts = inventoryAccounts

    if (amIVisible("show expense account in inventory")) {
      listOfAccounts = inventoryCogs
    }

    const [key] = useState(random())
  
    const { currentSw } = useSelector(state => state.dashboard)

    const { items, isSentPurchaseOrder, trackInventory, isSentCogs, poItemsMapping } = formik.values;

    const { productLabels } = useSelector(state => state.dashboard)

    const [isEmptyLabels, setIfEmptyLabels] = useState(false)

    const { isLoading, isFetching, refetch } = useGetItemsQuery({
        recordItems: true,
        key
    }, {
        skip: false,
        selectFromResult: ({ data, ...rest }) => {
            let labels = data?.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            labels = _.filter(labels, item => {
                return !_.keys(_.pickBy(items, (items) => items !== '')).includes(item?.value + '')
            })
     
            return {
                data: labels,
                ...rest
            }
        }
    })

    useEffect(() => {
        setIfEmptyLabels(productLabels?.length === 0)
    }, [productLabels])

    useEffect(() => {

        const labels = productLabels ?? []

        if (labels?.length === 0 && !isEmptyLabels) {
            refetch()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLabels])

    const isDynamicMapping = amIVisible("enter field for product and categories individual")

    let extraProps = {
        column2: "COGS",
        cogsAccounts
    }

    if (isSentPurchaseOrder && !trackInventory && !isSentCogs) {
        extraProps = {}
    }

    return (
        <>
            <FullPageWrapper
                title={t('Map COGS and Inventory By Product')}
                fullPageDesp={t(`Individual products can be mapped to unique accounts in ${currentSw}. All unassigned and new products will be assigned to the default product account.`, { swName: currentSw })}
                formik={formik}
                formikFieldName={"poItemsMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Product"}
                column1="Inventory"
                isDynamicMapping={isDynamicMapping}
                options={listOfAccounts}
                labelOption={productLabels}
                advanceMapping
                {...extraProps}
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}