import { FieldArray } from 'formik';
import { useSelector } from 'react-redux';
import { Autocomplete } from '../fields';
import { RowLoader } from '../custom/rowLoader';
import useConnectionMode from '../../hooks/useConnectionMode';
import { useEffect } from 'react';
import { Text } from '../custom/text';
import DynamicFields from '../custom/DynamicFields';
import amIVisible from '../../componentControl/registry';
import { Card, Divider } from 'antd';
import { Flex } from '@react-css/flex';
import { TbTrash } from "react-icons/tb/index"

export const TaxMapping = (props) => {

    const { formik } = props

    const { taxes, isTaxFetching, isPostingMethodChanged } = useSelector(state => state.dashboard)

    const [, swName] = useConnectionMode()

    useEffect(() => {
        if (isPostingMethodChanged) {
            formik.setFieldValue('taxes', taxes?.taxLabels)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isPostingMethodChanged, taxes])

    const { taxes: taxMapping } = formik?.values

    const isDynamic = amIVisible("dynamic tax rates")

    return (
        <Card type="inner" title={
            <Text fontSize={"large"} fontWeight="bolder">Tax rates</Text>
        } style={{ marginBottom: "1rem" }}>
            <Text color="gray-600" mb="1.125rem">
                All tax rates must be mapped to a tax code or a G/L account in {swName}. Ensure that Sales Tax is enabled in {swName}
            </Text>
            <FieldArray name="taxes">
                {
                    (array) => {
                        const width = `${100 / 2}%`
                        return (
                            <Card style={{ marginTop: "1rem" }} type="inner" title={
                                <>
                                    <Flex>
                                        <Flex style={{ width: '60%' }}>
                                            <Text fontSize='medium' fontWeight="bolder">Tax Rate</Text>
                                        </Flex>
                                        <Flex justifyContent={"space-between"} style={{ width: '40%' }}>
                                            <Text fontSize='medium' fontWeight="bolder">Tax Accounts</Text>
                                            <Text fontSize='medium' fontWeight="bold" color="grey">Required</Text>
                                        </Flex>
                                    </Flex>
                                </>
                            }>
                                <>
                                    {isTaxFetching ? <RowLoader /> :
                                        <>
                                            {
                                                taxMapping?.map((row, index) => {
                                                    return (
                                                        <>
                                                            <Flex alignItems={"center"} style={{ width: "100%" }}>
                                                                <div style={{ width, padding: "0.2rem", textAlign: "center" }}>
                                                                    <Text fontSize='medium' fontWeight="bolder">{row?.id}</Text>
                                                                </div>
                                                                <div style={{ width, padding: "0.2rem" }}>
                                                                    <Autocomplete
                                                                        name={`taxes[${index}].account`}
                                                                        formik={formik}
                                                                        options={taxes?.taxAccounts}
                                                                        placeholder={"Select Tax Account"}
                                                                    />
                                                                </div>
                                                                {isDynamic && <TbTrash
                                                                    pointer="cursor"
                                                                    style={{ marginLeft: '0.75rem', fontSize: "18px", pointer: 'cursor', color: "red" }}
                                                                    onClick={() => {
                                                                        array.remove(index)
                                                                    }}
                                                                />}
                                                            </Flex>
                                                            <Divider style={{ margin: "12px 0" }} />
                                                        </>
                                                    )
                                                })
                                            }
                                            {isDynamic && <DynamicFields title={"Tax Rate"} labels={taxMapping} options={taxes?.taxAccounts} onFieldAdd={(id = '', account = '') => {
                                                formik.setFieldValue('taxes', [...taxMapping, { id, account }])
                                            }} />}
                                        </>}
                                </>
                            </Card>
                        )
                    }
                }
            </FieldArray>
        </Card>
    )
}