import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _ from 'lodash';
import useAccounts from '../../hooks/useAccounts';
import { useGetItemsQuery } from '../../services/dashboardService';
import { FullPageWrapper } from '../custom/IndividualMappingLayout';
import amIVisible from '../../componentControl/registry';
import { useEffect, useState } from 'react';

export const IndividualProductMapping = ({ formik }) => {

    const { t } = useTranslation()

    const [isLoadingAccounts, accountsList] = useAccounts('items');

    const { currentSw } = useSelector(state => state.dashboard)

    const { items } = formik.values;

    const { productLabels } = useSelector(state => state.dashboard)

    const [isEmptyLabels, setIfEmptyLabels] = useState(false)

    const { isLoading, isFetching, refetch } = useGetItemsQuery({
        recordItems: true
    }, {
        skip: false,
        selectFromResult: ({ data, ...rest }) => {
            let labels = data?.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            labels = _.filter(labels, item => {
                return !_.keys(_.pickBy(items, (items) => items !== '')).includes(item?.value + '')
            })
     
            return {
                data: labels,
                ...rest
            }
        }
    })

    useEffect(() => {
        setIfEmptyLabels(productLabels?.length === 0)
    }, [productLabels])

    useEffect(() => {

        const labels = productLabels ?? []

        if (labels?.length === 0 && !isEmptyLabels) {
            refetch()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [productLabels])

    const isDynamicMapping = amIVisible("enter field for product and categories individual")

    return (
        <>
            <FullPageWrapper
                title={t('Map Products')}
                fullPageDesp={t(`Individual products can be mapped to unique accounts in ${currentSw}. All unassigned and new products will be assigned to the default product account.`, { swName: currentSw })}
                formik={formik}
                formikFieldName={"itemsMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Product"}
                isDynamicMapping={isDynamicMapping}
                options={accountsList}
                labelOption={productLabels}
                advanceMapping
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}