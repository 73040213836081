import { FieldArray } from 'formik';
import { Autocomplete, TextInput } from '../fields';
import { RowLoader } from '../custom/rowLoader';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { PageLayout } from '../pageLayout/PageLayout';
import { useState } from 'react';
import { BiListPlus } from 'react-icons/bi/index';
import _ from 'lodash';
import { useNotification } from '../../utils/helpers/notifications';
import { Empty, Radio, Button, Modal, Card, Divider, Input } from 'antd';
import { RefreshAccounts } from '../refreshAccounts/RefreshAccounts';
import { McHeading, Text } from './text';
import DynamicFields from './DynamicFields';
import { AiFillWarning } from 'react-icons/ai';
import { Flex } from '@react-css/flex';
import { TbTrash } from 'react-icons/tb';
import { Layout } from './Layout';
import amIVisible from '../../componentControl/registry';

export const FullPageWrapper = (props) => {

    const {
        title,
        fullPageDesp,
        formik,
        advanceMapping,
        refetch,
        isDynamic
    } = props

    return (
        <>
            <PageLayout
                title={title}
                formik={formik}
                refetch={refetch}
                showBack
            >
                <Layout
                    title={title}
                    description={fullPageDesp}
                    extra={
                        <>
                            {!isDynamic && <RefreshAccounts dirty={props?.formik?.dirty} />}
                        </>
                    }
                    style={{ marginBottom: "2.25rem" }}
                >
                    {advanceMapping ?
                        <AdvanceMappingLayout {...props} />
                        :
                        <IndividualMappingLayout {...props} />
                    }
                </Layout>
            </PageLayout>
        </>
    )
}

export const AdvanceMappingLayout = (props) => {

    const {
        formik,
        isLoading,
        isAccountLoading,
        formikFieldName,
        heading,
        options,
        rowLoaderProps,
        labelOption,
        refetch,
        column1,
        column2,
        parentCategory,
        isPo,
        cogsAccounts,
        isDynamicMapping,
        radioOptions: configs
    } = props

    const { currentSw, isGlobalLoading } = useSelector(state => state.dashboard)

    const { fullName } = useSelector(state => state.auth)

    const [selected, setSelected] = useState(undefined)

    const [showModal, setShowModal] = useState(null);

    const { showNotification } = useNotification()

    const { t } = useTranslation()

    const mapping = formik.values[formikFieldName]

    const canShowParentCategory = ["Type/sub-type", "Categories"].includes(heading) && amIVisible("groupby") && Object.entries(configs ?? {})?.length > 1

    const WarningModal = () => {
        return (
            <>
                <Modal open closable={false} width={"700px"} title={<Flex alignItems={"flex-end"}>
                    <AiFillWarning color="orange" fontSize={"26px"} style={{ marginRight: "8px" }} />
                    Warning
                </Flex>} onCancel={() => {
                    setShowModal(null)
                }} onOk={() => {
                    if (!isPo) {
                        formik.setFieldValue('isParentCategory', showModal)
                    } else {
                        formik.setFieldValue('swapSuperParentCategoryFromProducts', showModal)
                    }
                    formik.setFieldValue(formikFieldName, [])
                    refetch(showModal)
                    setShowModal(null)
                }} okType="danger" okText="Change">
                    <Text fontWeight="bold" fontSize="large" mb="0.8rem">
                        {t('ConfirmCatLevelChange.0')}
                    </Text>
                    <Text mb="0.5rem" color="gray-600" fontSize="large">
                        {t('ConfirmCatLevelChange.1')}
                    </Text>
                </Modal>
            </>
        )
    }

    return <>
        {showModal !== null && <WarningModal />}
        <div>
            <FieldArray name={formikFieldName}>
                {
                    (array) => {
                        const width = `${100 / (column2 ? 3 : 2)}%`
                        return (
                            <>
                                <Card type='inner' title={<Text fontSize={"large"} fontWeight="bolder">Add {heading}</Text>} style={{ marginBottom: "1.125rem" }} mb="1.125rem">
                                    <Text mt=".6rem">Add a {heading} to the list below to map it to a custom {currentSw} accounts.</Text>
                                    <div style={{ padding: "1rem 0" }}>
                                        {
                                            canShowParentCategory && (
                                                <>
                                                    <Flex style={{ marginBottom: "1.3rem" }} justifyContent="start">
                                                        <Radio.Group disabled={isLoading || isGlobalLoading} onChange={(e) => {
                                                            setShowModal(e.target.value)
                                                            setSelected(undefined)
                                                        }} value={Number(parentCategory ?? 0)}>
                                                            {
                                                                _.keys(configs)?.map(key => {
                                                                    return (
                                                                        <>
                                                                            <Radio value={parseInt(key)}>
                                                                                <Text>{configs[key]?.label}</Text>
                                                                            </Radio>
                                                                        </>
                                                                    )
                                                                })
                                                            }
                                                        </Radio.Group>
                                                    </Flex>
                                                    <Divider />
                                                </>
                                            )
                                        }
                                        <Flex>
                                            <div style={{ minWidth: "20rem", marginRight: "8px" }}>
                                                {isDynamicMapping ?
                                                    <Input
                                                        id="selectedOption"
                                                        name="selectedOption"
                                                        isLoading={isLoading}
                                                        placeholder={`Enter ${heading} name`}
                                                        value={selected}
                                                        onChange={(evt) => {
                                                            setSelected(evt.target.value)
                                                        }}
                                                    />
                                                    : <Autocomplete
                                                        id="selectedOption"
                                                        name="selectedOption"
                                                        isLoading={isLoading}
                                                        dropdown
                                                        options={labelOption?.filter((option) => !(Array.isArray(mapping) ? mapping : [])?.map(i => i?.id).includes(option?.value))}
                                                        placeholder={`Enter ${heading} name`}
                                                        value={selected}
                                                        onChange={setSelected}
                                                    />}
                                            </div>
                                            <Button
                                                type={"primary"}
                                                disabled={isLoading || isGlobalLoading}
                                                onClick={() => {
                                                    if (selected && selected !== '') {
                                                        if (isDynamicMapping) {
                                                            let name = labelOption?.find(option => option?.id === selected)?.label ?? ""

                                                            if (!mapping?.map(row => row?.id)?.includes(selected)) {
                                                                array.push({
                                                                    id: selected,
                                                                    name,
                                                                    account: '',
                                                                    cogs: ''
                                                                })
                                                                setSelected(undefined)
                                                            } else {
                                                                showNotification(`${heading} is already mapped.`, "error")
                                                            }
                                                        } else {
                                                            let name = labelOption?.find(option => option?.value === selected)?.label ?? ""

                                                            array.push({
                                                                id: selected,
                                                                name,
                                                                account: '',
                                                                cogs: ''
                                                            })
                                                            setSelected(undefined)
                                                        }
                                                    } else {
                                                        if (isDynamicMapping) {
                                                            showNotification(`Enter a ${heading} first`, 'error')
                                                        } else {
                                                            showNotification("Select an option first", 'error')
                                                        }
                                                    }
                                                }}
                                                value={selected}
                                                className='btn-with-icon'
                                            >
                                                <BiListPlus className="mc-btn-icon" />
                                                Add
                                            </Button>
                                        </Flex>
                                    </div>
                                </Card>
                                <>
                                    <Card>
                                        <>
                                            <div>
                                                <Flex width={"100%"} mb=".6rem">
                                                    <div style={{ width, padding: "0 .8rem" }}>
                                                        <Text fontWeight="bolder">{`${fullName} ${heading}`}</Text>
                                                    </div>
                                                    <div style={{ width, padding: "0 .8rem" }}>
                                                        <Text fontWeight="bolder">{currentSw} {column1} Accounts</Text>
                                                    </div>
                                                    {column2 && <div style={{ width, padding: "0 .8rem" }}>
                                                        <Text fontWeight="bolder">{currentSw} {column2} Accounts</Text>
                                                    </div>}
                                                </Flex>
                                                <Divider />
                                            </div>
                                            {isLoading ? <RowLoader {...rowLoaderProps} /> :
                                                <>
                                                    {
                                                        (mapping?.length === 0 || !mapping) && <Flex justifyContent={"center"}><Empty image={Empty.PRESENTED_IMAGE_SIMPLE} /></Flex>
                                                    }
                                                    {
                                                        mapping?.map((row, index) => {
                                                            return (
                                                                <div>
                                                                    {index !== 0 && <Divider />}
                                                                    <Flex alignItems={"center"} width={"100%"} mb=".5rem">
                                                                        <div style={{ width, padding: "0 .8rem" }}>
                                                                            <Text>{row?.name}</Text>
                                                                        </div>
                                                                        <div style={{ width, padding: "0 .8rem" }}>
                                                                            <Flex justifyContent={"space-between"} alignItems="center" style={{ width: '100%' }}>
                                                                                <Autocomplete
                                                                                    name={`${formikFieldName}[${index}].account`}
                                                                                    formik={formik}
                                                                                    options={options}
                                                                                    placeholder={"Select an Account"}
                                                                                    isLoading={isAccountLoading}
                                                                                    style={{ width: column2 ? '12rem' : '18rem' }}
                                                                                />
                                                                                {!column2 && <TbTrash
                                                                                    style={{ marginLeft: '0.75rem', fontSize: "18px", cursor: 'pointer', color: "red" }}
                                                                                    onClick={() => {
                                                                                        array.remove(index)
                                                                                    }}
                                                                                />}
                                                                            </Flex>
                                                                        </div>
                                                                        {column2 && <div style={{ width, padding: "0 .8rem" }}>
                                                                            <Flex justifyContent={"space-between"} alignItems="center" style={{ width: '100%' }}>
                                                                                <Autocomplete
                                                                                    name={`${formikFieldName}[${index}].cogs`}
                                                                                    formik={formik}
                                                                                    options={cogsAccounts ?? []}
                                                                                    placeholder={"Select an Account"}
                                                                                    isLoading={isAccountLoading}
                                                                                    style={{ width: column2 ? '11rem' : '18rem' }}
                                                                                />
                                                                                <TbTrash
                                                                                    style={{ marginLeft: '0.75rem', fontSize: "18px", cursor: 'pointer', color: "red" }}
                                                                                    onClick={() => {
                                                                                        array.remove(index)
                                                                                    }}
                                                                                />
                                                                            </Flex>
                                                                        </div>}
                                                                    </Flex>
                                                                </div>
                                                            )
                                                        })
                                                    }
                                                </>}
                                        </>
                                    </Card>
                                </>
                            </>
                        )
                    }
                }
            </FieldArray>
        </div>
    </>
}

export const IndividualMappingLayout = (props) => {

    const {
        formik,
        isLoading,
        isAccountLoading,
        formikFieldName,
        heading,
        desp,
        options,
        paymentLiabilityList,
        labels,
        rowLoaderProps,
        isDynamic,
        dynamicFieldTitle
    } = props

    const { t } = useTranslation()
    const { currentSw } = useSelector(state => state.dashboard)
    const { fullName } = useSelector(state => state.auth)

    return (
        <>
            <div style={{ padding: "0 .75rem" }}>
                {desp && <>
                    <McHeading level={2} mb="0.375rem">{t(heading)}</McHeading>
                    <Text color="gray-600" mb="1.125rem" fontSize=''>
                        {t(desp, { swName: currentSw })}
                    </Text>
                </>}
                <FieldArray name={formikFieldName}>
                    {
                        (array) => {
                            const width = `${100 / 2}%`
                            return (
                                <Card>
                                    <>
                                        <div>
                                            <Flex width={"100%"} mb=".6rem">
                                                <div style={{ width, padding: "0 .8rem" }}>
                                                    <Text fontWeight="bolder">{`${fullName} ${heading}`}</Text>
                                                </div>
                                                <div style={{ width, padding: "0 .8rem" }}>
                                                    <Text fontWeight="bolder">{currentSw}</Text>
                                                </div>
                                            </Flex>
                                            <Divider />
                                        </div>
                                        {isLoading ? <RowLoader {...rowLoaderProps} /> :
                                            <>
                                                {
                                                    labels?.map((row, index) => {
                                                        return (
                                                            <div>
                                                                <Flex alignItems={"center"} style={{ width: "100%", marginBottom: ".5rem" }}>
                                                                    <div style={{ width, padding: "0 .8rem" }}>
                                                                        <Text>{row?.label}</Text>
                                                                    </div>
                                                                    <div style={{ width, padding: "0 .8rem" }}>
                                                                        {!isDynamic ?
                                                                            <Autocomplete
                                                                                name={`${formikFieldName}[${index}].account`}
                                                                                formik={formik}
                                                                                options={row?.type === "Liability" ? paymentLiabilityList : options}
                                                                                placeholder={"Select an Account"}
                                                                                isLoading={isAccountLoading}
                                                                            />
                                                                            :
                                                                            <TextInput
                                                                                name={`${formikFieldName}[${index}].account`}
                                                                                formik={formik}
                                                                                placeholder={`Enter ${heading} Account`}
                                                                            />}
                                                                    </div>
                                                                    {isDynamic && <TbTrash
                                                                        pointer="cursor"
                                                                        style={{ marginLeft: '0.75rem', fontSize: "18px", pointer: 'cursor', color: "red" }}
                                                                        onClick={() => {
                                                                            array.remove(index)
                                                                        }}
                                                                    />}
                                                                        </Flex>
                                                                        <Divider />
                                                                    </div>
                                                                    )
                                                    })
                                                }
                                                                    {isDynamic && <DynamicFields labels={labels ?? []} title={dynamicFieldTitle} options={options} onFieldAdd={(id = '', account = '') => {
                                                                        formik.setFieldValue(formikFieldName, [...labels ?? [], { id, label: id, account }])
                                                                    }} />}
                                                                </>}
                                                            </>
                                </Card>
                            )
                        }
                    }
                                    </FieldArray>
                                </div>
        </>
                )
}