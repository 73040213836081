import { Suspense } from 'react';
import './App.css';
import Router from './Router/routes';
import { FloatButton } from 'antd';

function App() {

  return (
    <>
      <div style={{ height: '100%' }}>
        <Router />
        <FloatButton.BackTop style={{ bottom: "130px" }} />
      </div>
    </>
  );
}

export default function AppSuspense() {
  return (
    <Suspense fallback="loading">
      <App />
    </Suspense>
  );
};