import useCurrency from '../../hooks/useCurrency';
import { Spin, Table } from 'antd';
import { Text } from '../custom/text';
import { MdOutlineDone } from 'react-icons/md';

export const PurchasesTable = ({ title, isLoading, heading, mappingType, postingType, ...props }) => {
  const { data } = props;
  const { toCurrency } = useCurrency();

  const customCol = [
    {
      title: "Income Account",
      dataIndex: "account",
      key: "account"
    },
    {
      title: 'Expense Account',
      dataIndex: "name",
      key: "name"
    },
  ]
  
  let allColumns = [
    {
      title: "Name",
      dataIndex: "name",
      key: "name"
    },
    {
      title: "Unit price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (_, item) => toCurrency(item.unitPrice)
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => toCurrency(item.amount)
    },
    {
      title: "Tax",
      dataIndex: "taxEnabled",
      key: "taxEnabled",
      render: (_, item) => Boolean(item.taxEnabled) ? <MdOutlineDone /> : ""
    },
    {
      title: "Location / Class tracking",
      dataIndex: "class",
      key: "class"
    },
    {
      title: "Asset Account",
      dataIndex: "assetAccount",
      key: "assetAccount"
    },
  ]

  if (postingType === 1) {
    allColumns = [...allColumns, ...customCol]
  }

  return <>
    {
      <Spin spinning={!isLoading}>
        {data !== null && <Table style={{ marginTop: "20px" }} caption={
          <Text fontWeight='bolder' fontSize='large'>{heading}</Text>
        } columns={allColumns} dataSource={data} pagination={false} />}
      </Spin>
    }
  </>;
};
