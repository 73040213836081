
import { Text } from '../custom/text';
import { Flex } from '@react-css/flex';
import { Button, Card, DatePicker, Empty, Input, Select, Spin, Tooltip } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { SyncStatusBadge } from '../custom/SyncStatusBadge';
import useCurrency from '../../hooks/useCurrency';
import "./syncTable.css"
import { useEffect, useRef, useState } from 'react';
import { setDateFilter, setPostTypeFilter, setResetAll, setResetParticular, setSearchQuery } from '../../slices/syncSlice';
import { BiReset } from 'react-icons/bi';
import { useBottomScrollListener } from 'react-bottom-scroll-listener';
import { colors } from '../../style/color';
import { AiOutlineFilter } from 'react-icons/ai';
import moment from 'moment';

export const SyncDynamicTable = (props) => {
    const {
        rows,
        openSyncDetailsModal,
        refreshData,
        loadingStatus,
        makeRequest,
        isTypePo
    } = props;

    const { isLoading, s, isLastPage, isResyncing, filterPostType } = useSelector(state => state.sync)

    const [loading, setLoading] = useState(false)
    const ref = useRef();
    const [dateValue, setDateValue] = useState([])
    const { toCurrency } = useCurrency();

    const dispatch = useDispatch()

    useEffect(() => {
        setLoading(loadingStatus)
    }, [loadingStatus])


    const columns = [
        {
            title: 'Date Posted',
            dataIndex: 'actionDate',
            key: 'date'
        },
        {
            title: 'Post status',
            dataIndex: 'actionDate',
            key: 'status'
        },
        {
            title: 'Reference number',
            dataIndex: (isTypePo ? "orderRef" : "orderNumber"),
            key: 'ref',
        },
        (isTypePo) && {
            title: 'Purchase Order ID',
            dataIndex: 'orderNumber',
            key: 'on',
        },
        {
            title: 'Total',
            dataIndex: "totalAmount",
            key: 'ref',
        },
        {
            title: 'Transaction Type',
            dataIndex: "tranType",
            key: 'txnType',
        },
        {
            title: 'Action',
            dataIndex: "",
            key: 'txnType',
        },
        (!isTypePo) && {
            title: 'View invoice',
            dataIndex: "url",
            key: 'txnType'
        }
    ].filter(Boolean)

    const getTxnType = (type) => {
        switch (type) {
            case 1:
                return isTypePo ? "Purchase Order" : "Individual Sync"
            case 2:
                return isTypePo ? "Bill" : "EOD Sync"
            case 3:
                return isTypePo ? "Vendor Returns" : "Account Sales"
            case 4:
                return "Account Payment"
            case 5:
                return "Adjustment"
            default:
                return ""
        }
    }

    const DateFilter = () => {
        const { RangePicker } = DatePicker;

        return (
            <>
                <RangePicker style={{ marginRight: "1rem" }} allowClear value={dateValue} onChange={(e, val) => {
                    setDateValue(e)

                    dispatch(setDateFilter(val))
                    dispatch(setResetParticular({ s: null }))
                    refreshData()
                }} disabledDate={(current) => {
              let customDate = moment().format("YYYY-MM-DD");
              return current && current > moment(customDate, "YYYY-MM-DD");
            }}  />
            </>
        )
    }

    const Filter = () => {

        const onFilter = (value) => {
            dispatch(setPostTypeFilter(value))
        }

        return (
            <Select
                placeholder="Filter by Post status"
                options={[
                    { value: 0, label: 'Failed' },
                    { value: 1, label: 'Success' },
                    { value: 2, label: 'Queued' },
                ]}
                allowClear
                onChange={(val) => onFilter(val)}
                onClear={() => onFilter(-1)}
                defaultValue={filterPostType}
                style={{ marginRight: "14px", minWidth: 200 }}
            />
        )
    }

    const Search = () => {

        return (
            <Flex flexDirection={"row"} ml={"1rem"} alignItems={"center"}>

                <Input
                    ref={ref}
                    defaultValue={s ?? ''}
                    placeholder='Type order number'
                    width={200}
                />
                <Button
                    ml={"1rem"}
                    mr={'1rem'}
                    style={{ margin: "0 1rem", marginRight: "3px" }}
                    loading={isLoading}
                    onClick={() => {
                        dispatch(setSearchQuery(ref?.current?.input?.value ?? ""))
                        setDateValue([])

                        refreshData()
                    }}
                    type="primary"
                    htmlType='submit'
                >Search by order number</Button>
            </Flex>
        )
    }

    const RefreshButton = ({ refreshData }) => {

        return <Button
            loading={isLoading}
            onClick={refreshData}
            type="primary"
        >Refresh</Button>;
    };

    const handleOnUserScrollDown = () => {
        !isLastPage && refreshData()
    }

    useBottomScrollListener(handleOnUserScrollDown);

    return (
        <>
            <Card style={{ marginBottom: "1.5rem" }} type='inner' title={
                <Flex alignItems={"center"}>
                    <AiOutlineFilter size={18} />
                    <Text fontWeight='bolder' pl="6px" fontSize='large'>Data Filters</Text>
                </Flex>
            }>
                <Flex justifyContent={"start"} alignItemsCenter>
                    <DateFilter />
                    <Filter />
                    <Search />
                    <Tooltip title="Reset filter">
                        <BiReset style={{ margin: "0 8px" }} size={24} className='reset-icon' onClick={() => {
                            dispatch(setResetAll())
                            refreshData()
                        }} />
                    </Tooltip>
                    <RefreshButton refreshData={() => {
                        dispatch(setResetAll())
                        setDateValue([])
                        refreshData()
                    }} loadingStatus={loading} />
                </Flex>
            </Card>
            <div
                id="scrollableDiv"
                style={{
                    height: "auto",
                    overflow: 'auto',
                    display: 'flex',
                    flexDirection: 'column-reverse',
                }}
            >
                <Spin
                    spinning={isLoading}
                    tip={<Flex justifyCenter>
                        <Text color={colors?.primary}>Fetching Data...</Text>
                    </Flex>}
                >
                    <table className={"mc-table"}>
                        <thead className="mc-thead">
                            <tr style={{ width: "100%" }}>
                                {
                                    columns?.map(col => (
                                        <th className="mc-th"><div fontWeight='bolder'>{col?.title}</div></th>
                                    ))
                                }
                            </tr>
                        </thead>
                        <tbody className="mc-tbody">
                            {rows?.length !== 0 &&
                                rows?.map((item, index) => (
                                    <tr key={index} className={`mc-tr mc-tr-${index % 2 === 0 ? 'even' : 'odd'}`}>
                                        <td className='mc-tc'>
                                            {(item?.requestExists) ?
                                                <Flex justifyContent={"center"}>
                                                    <Text link
                                                        fontWeight="bolder"
                                                        onClick={() => { openSyncDetailsModal(item) }}
                                                        color="black"
                                                    >
                                                        {item.actionDate}
                                                    </Text>
                                                </Flex> :
                                                <>
                                                    {item.actionDate}
                                                </>
                                            }
                                        </td>
                                        <td className='mc-tc'><SyncStatusBadge type={item.success} /></td>
                                        <td className='mc-tc'>{(item.success === 3) ? '' : (isTypePo ? item?.orderRef : item?.orderNumber)}</td>
                                        {isTypePo && <td className='mc-tc'>{item?.orderNumber ?? ""}</td>}
                                        <td className='mc-tc'>{(item.success === 3) ? '' : toCurrency(item.totalAmount)}</td>
                                        <td className='mc-tc'>{getTxnType(item?.tranType)}</td>
                                        {item?.success === 0 && !loading ?
                                            <td className='mc-tc'>{isResyncing ? <Spin spinning /> :
                                                <Flex justifyContent={"center"}>
                                                    <Text link
                                                        fontWeight="bolder"
                                                        onClick={() => makeRequest(item?.id)}
                                                        disabled={loading}
                                                        style={{ textAlign: "center" }}
                                                    >
                                                        Resync
                                                    </Text>
                                                </Flex>
                                            }</td> : <td className='mc-tc'></td>}
                                        {!isTypePo && <td className='mc-tc'>
                                            {(item.success === 1 && item?.url && Object?.entries(item?.url)?.length !== 0) ?
                                                Object.keys(item?.url ?? {})?.map((key) => (
                                                    <>
                                                        <Text link fontWeight="bold" href={item?.url[key]} target="_blank" color="black">View {key}</Text>
                                                        <Flex mb={".5rem"} />
                                                    </>
                                                ))
                                                :
                                                ''
                                            }
                                        </td>}
                                    </tr>
                                ))
                            }
                        </tbody>
                    </table>
                    {rows?.length === 0 &&
                        <Card style={{ width: "995px" }} bordered={false}>
                            <Empty />
                        </Card>
                    }
                    {isLoading && !(rows?.length === 0) && <Flex justifyContent={"center"} style={{ padding: "5px", paddingTop: "1rem" }}>
                        <Spin size='default' spinning tip={<Text mt='.2rem' fontWeight='bolder' fontSize='x-large'>Fetching More Data...</Text>} />
                    </Flex>}
                </Spin>
            </div>
        </>
    )
}