import { Button } from "antd"
import { TbRefreshDot } from "react-icons/tb"
import { setIsReconnecting } from "../../../slices/authSlice"
import { useDispatch } from 'react-redux';

export const ReconnectBtn = ({ isLoading, isDisabled, navigate }) => {

    const dispatch = useDispatch()

    return (
        <>
            <Button
                disabled={isDisabled}
                loading={isLoading}
                onClick={() => {
                    dispatch(setIsReconnecting(1))
                    navigate('../user-onboard')
                }}
                icon={<TbRefreshDot className='mc-btn-icon' />}
                className="btn-with-icon"
            >
                Reconnect
            </Button>
        </>
    )
}