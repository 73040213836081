import { Flex } from '@react-css/flex';
import { colors } from '../../style/color';
import { Text } from './text';

export const Layout = ({
    title = '',
    description = "",
    learnMoreLink = "https://posaccounting.co/",
    extra = <></>,
    children = <></>
}) => {

    return (
        <>
            <Flex justifyContent={"space-between"} style={{ width: "100%", height: "100%", paddingBottom: "2.25rem" }}>
                <Flex flexDirection='column' style={{ width: "24%", marginTop: "1rem" }}>
                    <Text fontSize='small' fontWeight='bolder'>{title.toUpperCase()}</Text>
                    <Text mt=".3rem" mb=".8rem" fontSize='x-small' color={colors.secondary}>{description}</Text>
                    <Text mb=".5rem" link onClick={() => window.open(learnMoreLink)}>Learn More</Text>
                    <Flex>
                        {extra}
                    </Flex>
                </Flex>
                <Flex style={{ width: "75%" }}>
                    <div style={{ width: "100%" }}>
                        {children}
                    </div>
                </Flex>
            </Flex>
        </>
    )
}