import { TextInput } from '../fields';
import { useTranslation } from 'react-i18next';
import { OverShort } from '../overShort/OverShort';
import { LayAway } from '../Layaway/LayAway';
import { useSelector } from 'react-redux';
import { PettyCashPayouts } from '../pettyCashPayouts/PettyCashPayouts';
import { getCurrentTarget } from '../../utils/helpers/commonHelpers';
import useConnectionMode from '../../hooks/useConnectionMode';
import amIVisible from '../../componentControl/registry';
import { MultipleClass } from '../locationSection/MultipleClass';
import { FloorMapping } from '../Floors/floor';
import { Adjustment } from '../mapAdjustment/Adjustment';
import { Card, Divider } from 'antd';
import { Flex } from '@react-css/flex';
import { Text } from '../custom/text';
import { Box } from '../custom/box';
import { Layout } from '../custom/Layout';
import { LocationMapping } from '../locationSection/LocationMapping';
import { InventoryAccount } from '../mapInventoryAccount/mapInventoryAccounts';
import { ClassMapping } from '../locationSection/ClassMapping';

const CustomCards = ({ title, description, children, requiredLabel }) => {
  return <>
    <Flex>
      <Card type="inner" title={
        <Text fontWeight='bolder' fontSize=''>{title}</Text>
      } extra={<Text color={"grey"} >{requiredLabel}</Text>}>
        {description}
        <Box mt="1rem">
          {children}
        </Box>
      </Card>
    </Flex>
  </>
}

const OptionsMapping = () => {
  const isLoading = false;
  const target = getCurrentTarget();
  const [, swName] = useConnectionMode();
  const { t } = useTranslation();
  const canShowCustomerName = amIVisible("default customer name")
  const canShowTxnNumber = amIVisible("transaction number")

  return (
    <Flex style={{ marginLeft: "1rem" }} justifyContent="space-between">
      {canShowCustomerName && <Flex style={{ width: "49%" }}>
        <CustomCards
          title={t('Default customer name')}
          description={t('DefaultNameDescription', { swName })}
          requiredLabel="Required"
          children={
            <TextInput
              isLoading={isLoading}
              id="defaultCustomer"
              name="defaultCustomer"
              placeholder={"POS customer"}
            />
          }
        />
      </Flex>}
      {canShowTxnNumber && <Flex style={{ width: "49%" }}>
        <CustomCards
          title={t('Custom transaction number')}
          description={t('CustomerTransactionDescription', { target: target.fullName })}
          children={
            <TextInput
              isLoading={isLoading}
              id="customTransactionNumber"
              name="customTransactionNumber"
              placeholder={t('Enter the custom transaction number')}
            />
          }
        />
      </Flex>}
    </Flex>
  )
};

const OptionsSectionContent = (props) => {
  const [swName] = useConnectionMode();
  let canShowLocationClassTracking = amIVisible("location / class tracking")
  let canShowLocationMapping = amIVisible("location mapping")
  let canShowClassMapping = amIVisible("class mapping")
  const canShowOverShort = amIVisible("over short")
  const canShowPettyCashPayments = amIVisible("petty cash payouts")
  const canShowFloorMapping = amIVisible("floor mapping")
  let canShowAdjustmentMapping = amIVisible("adjustment")
  const canShowLayAway = useSelector(state => state.globals.canShowLayAway) && amIVisible("layaway");
  const canShowIndividualInventoryBlock = amIVisible("individual inventory account");

  let LocationSection = <></>

  switch (swName) {
    case "Xero":
      if (canShowLocationMapping)
        LocationSection = LocationMapping
      break
    case "QB Desktop Edition":
      if (canShowClassMapping)
        LocationSection = ClassMapping
      break
    default:
      if (canShowLocationClassTracking)
        LocationSection = MultipleClass
      break
  }

  return <>
    <>
      <OptionsMapping {...props} />
    </>
    <>
      {
        canShowLayAway && (
          <>
            <Divider style={{ marginTop: "1rem" }} />
            <LayAway formik={props?.formik} />
          </>
        )
      }
    </>
    <LocationSection {...props} />
    {canShowOverShort && <OverShort {...props} />}
    {canShowFloorMapping && <FloorMapping {...props} />}
    {canShowAdjustmentMapping && <Adjustment {...props} />}
    {canShowPettyCashPayments && <PettyCashPayouts {...props} />}
    {canShowIndividualInventoryBlock && <InventoryAccount {...props} />}
  </>;
};

export const OptionsSection = (props) => {
  const { t } = useTranslation();

  return <Layout
    title={t('Additional Settings')}
    style={{ margin: "2.25rem 0" }}
  >
    <OptionsSectionContent {...props} />
  </Layout>;
};
