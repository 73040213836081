import { Button, Descriptions, Divider, Modal, Skeleton } from "antd"
import { Text } from "../custom/text"
import { Flex } from '@react-css/flex';
import { useState } from "react";
import useRole from "../../hooks/useRole";
import { useTranslation } from "react-i18next";
import { useGetOrderQuery, useRepostSyncMutation } from "../../services/syncService";
import { useNotification } from '../../utils/helpers/notifications';
import useLocale from "../../hooks/useLocale";
import { SyncStatusBadge } from "../custom/SyncStatusBadge";
import useCurrency from "../../hooks/useCurrency";
import { SalesTable } from "../syncDetails/SalesTable";
import { PurchasesTable } from "../syncDetails/PurchasesTable";
import { PaymentsTable } from "../syncDetails/PaymentsTable";
import { AdjustmentTable } from "../syncDetails/AdjustmentTable";

export const SyncModal = ({ closeModal, swName, rowData, isTypePo, ...props }) => {

    const [isDisabledClose, setIsDisabledClose] = useState(false);
    const locale = useLocale();
    const { toCurrency } = useCurrency();

    const hasNoData = (![0, 1, 2, true, false].includes(rowData.success));

    const [key] = useState(Math.random())

    let { data: transactionData, isLoading, isFetching } = useGetOrderQuery({ key, orderNumber: rowData.id }, { skip: rowData.success === 3 })

    const isOrderLoading = !(isLoading || isFetching)

    const handleClose = () => {
        if (!isDisabledClose) {
            closeModal();
        }
    };

    const getRows = (fieldName) => {
        return (transactionData) ? transactionData[fieldName]?.length > 0 ? transactionData[fieldName] : null : null;
    };

    const parseAdjustments = (data) => {
        let adjustments = []   

        try {
            for (let i in data) {
                if (data[i]?.accounts) {
                    for (let j in data[i]?.accounts) {
                        adjustments.push({
                            name: data[i]?.name,
                            account: data[i]?.accounts[j]?.name,
                            type: data[i]?.accounts[j]?.type,
                            amount: data[i]?.accounts[j]?.amount
                        })
                    }
                }
            }
        } catch (err) {
            console.error(err)
        }

        return adjustments
    }

    const ResyncRepost = ({ disableActions, isDisabledClose, swName, rowData, closeSyncDetails, hasNoData, isTypePo }) => {
        const [isShowingWarning, setIsShowingWarning] = useState(false);
        let type = 'repost';
        const { t } = useTranslation();
        const [user] = useRole();

        const [repost] = useRepostSyncMutation()

        const { showNotification } = useNotification()

        if ([0, false].includes(rowData.success) || hasNoData) {
            type = 'resync';
        }

        const handleButtonClick = () => {
            if (type === 'repost') {
                setIsShowingWarning(true);
            } else {
                makeRequest();
            }
        };

        const closeWarning = () => {
            setIsShowingWarning(false);
        };

        const makeRequest = () => {
            if (!isDisabledClose) {
                disableActions(true);
                if (type === 'repost') {
                    closeWarning();
                }

                const body = {
                    id: rowData.id,
                    repostingData: type === 'repost',
                    userType: user.type,
                    resyncPO: isTypePo
                };

                repost({
                    url: "resync/",
                    body
                })
                    .unwrap()
                    .then(() => {
                        showNotification("Data Queued", 'success')
                        closeSyncDetails({
                            type,
                            id: rowData.id,
                        });
                    })
            }
        };
        return <>
            {isShowingWarning &&
            {/* <RepostWarning
                    closeModal={closeWarning}
                    confirmAction={makeRequest}
                    swName={swName}
                    actionDate={rowData.actionDate}
                /> */}
            }
            {((type !== 'repost')) && <>
                <Flex justifyContent="space-between" alignItems="center">
                    <Text>
                        {t('ResyncDesc', { errorMessage: rowData.errorMessage })}
                    </Text>
                    <Flex>
                        {/* {!hasNoData &&
                <ViewLogs
                  disableActions={disableActions}
                  isDisabledClose={isDisabledClose}
                  rowData={rowData}
                />
              } */}
                        <Button
                            type="primary"
                            onClick={handleButtonClick}
                            loading={isDisabledClose}
                        >{t('Resync')}</Button>
                    </Flex>
                </Flex>
                {!hasNoData && <Divider mt="1.5rem" />}
            </>
            }
        </>;
    };

    const getMetaDetails = () => {

        const transactionDate = new Date(rowData.actionDate + ' 0:0:0');
        const transactionDateText = new Intl.DateTimeFormat(locale, {
            month: 'short',
            day: 'numeric',
            year: 'numeric'
        }).format(transactionDate);

        return (
            <Descriptions labelStyle={{ fontWeight: "700" }} bordered size="small" style={{ margin: "20px 0" }}>
                <Descriptions.Item span={2} label={isTypePo ? 'PO Date' : 'Date'}>{isOrderLoading ? transactionDateText : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                <Descriptions.Item span={2} label="Sync Status">{isOrderLoading ? <SyncStatusBadge type={rowData.success} /> : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                <Descriptions.Item span={2} label="Reference Number">{isOrderLoading ? transactionData && transactionData?.referenceNumber : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                {isTypePo &&
                    <>
                        <Descriptions.Item span={2} label="Memo">{isOrderLoading ? transactionData && transactionData.memo : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Tax amount">{isOrderLoading ? transactionData && transactionData.taxAmount : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Total amount">{isOrderLoading ? transactionData && transactionData.totalAmount : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                        <Descriptions.Item span={2} label="Vendor">{isOrderLoading ? transactionData && transactionData.vendorName : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                    </>
                }
                {!isTypePo && <Descriptions.Item span={2} label="Customer">{isOrderLoading ? transactionData && transactionData.customer : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>}
                <Descriptions.Item span={2} label="Total Amount">{isOrderLoading ? transactionData && toCurrency(transactionData.totalAmount) : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
                <Descriptions.Item span={2} label="Tax Amount">{isOrderLoading ? transactionData && toCurrency(transactionData.taxAmount) : <Skeleton.Input style={{ width: "150px", height: "15px" }} />}</Descriptions.Item>
            </Descriptions>
        )
    }

    return (
        <>
            <Modal title={
                <Text fontWeight="bolder" fontSize="large">Sync Details</Text>
            } onCancel={handleClose} open width={"950px"} okButtonProps={{ style: { display: 'none' } }}>
                {(rowData.success !== 2) &&
                    <ResyncRepost
                        disableActions={setIsDisabledClose}
                        swName={swName}
                        isDisabledClose={isDisabledClose}
                        rowData={rowData}
                        closeSyncDetails={closeModal}
                        hasNoData={hasNoData}
                        isTypePo={isTypePo}
                    />
                }
                {
                    !hasNoData && (
                        <>
                            {getMetaDetails()}
                        </>
                    )
                }
                {isTypePo && <PurchasesTable
                    data={getRows('purchases')}
                    postingType={transactionData && transactionData.postingType}
                    heading="Purchase"
                    isLoading={isOrderLoading}
                    {...props}
                    style={{ marginTop: "20px" }}
                />}
                {!isTypePo && <SalesTable
                    data={getRows('sales')}
                    mappingType={transactionData && transactionData.mappingType}
                    isLoading={isOrderLoading}
                    heading="Sales"
                    {...props}
                    style={{ marginTop: "20px" }}
                />}
                {!isTypePo && <SalesTable
                    data={getRows('refundSales')}
                    heading="Refund Sales"
                    isLoading={isOrderLoading}
                    mappingType={transactionData && transactionData.mappingType}
                    {...props}
                    style={{ marginTop: "20px" }}
                />}
                {!isTypePo && <PaymentsTable style={{ marginTop: "80px" }}
                    isLoading={isOrderLoading} heading="Payments" data={getRows('payments')} {...props} />}
                {!isTypePo && <PaymentsTable style={{ marginTop: "20px" }}
                    isLoading={isOrderLoading} data={getRows('refundPayments')} heading="Refund Payments" {...props} />}
                {!isTypePo && <AdjustmentTable data={parseAdjustments(getRows('adjustments'))} style={{ marginTop: "20px" }} isLoading={isOrderLoading} heading={'Adjustments'} {...props} />}
            </Modal>
        </>
    )

}