import { useField } from 'formik';
import { useSelector } from 'react-redux';
import { Dropdown } from './dropdown';
import { Input } from './input';
import { Checkbox, Radio, Switch } from 'antd';
import Flex from '@react-css/flex'
import { Text } from '../custom/text';
import { CheckOutlined, CloseOutlined } from '@ant-design/icons';

export const RadioField = ({ description, confirmChange, ...props }) => {
  const [field, meta] = useField(props.name);
  let label = props.label;

  const { isGlobalLoading, isAccountLoading, isRefreshAccount } = useSelector(state => state.dashboard);
  const isLoading = isGlobalLoading || isAccountLoading || isRefreshAccount;

  if (meta.error && meta.touched && props.showErrors === true) {
    label = <Text color="red">{props.label}</Text>;
  }

  const handleChange = (event) => {
    /**
     * For react v17, e.persist() will not be needed as the SyntheticEvent is no longer pooled
     */

    if (confirmChange) {
      confirmChange({ event, field, meta }).then(() => {
        field.onChange(event);
      }).catch(() => { });
    } else {
      field.onChange(event);
    }
  };

  return (
    <Flex row>
      <Flex.Item>
        <Radio
          {...field}
          {...props}
          disabled={props.disabled || isLoading}
          onChange={handleChange}
          checked={field.value === props.value}
          description={description}
        />
      </Flex.Item>
      <Flex column>
        <Flex.Item>
          <Text fontSize={"medium"} fontWeight={"bolder"}>
            {label}
          </Text>
        </Flex.Item>
        <Flex.Item>
          <Text fontSize={"medium"} >
            {description}
          </Text>
        </Flex.Item>
      </Flex>
    </Flex>
  )
};

export const CheckboxField = ({ description, confirmChange, ...props }) => {
  const [field, meta] = useField(props.name);
  let label = props.label;
  const { isGlobalLoading, isAccountLoading, isRefreshAccount } = useSelector(state => state.dashboard);
  const isLoading = isGlobalLoading || isAccountLoading || isRefreshAccount;

  if (meta.error && meta.touched && props.showErrors === true) {
    label = <Text color="red">{label}</Text>;
  }

  return <Checkbox
    {...field}
    {...props}
    disabled={props.disabled || isLoading}
    checked={field.value}
    style={{ marginTop: "12px" }}
  >{label}</Checkbox>;
};

export const SwitchField = ({ values, ...props }) => {
  const [field, , helpers] = useField(props.name);
  const { isGlobalLoading, isAccountLoading, isRefreshAccount } = useSelector(state => state.dashboard);
  const isLoading = isGlobalLoading || isAccountLoading || isRefreshAccount;

  const handleChange = (value) => {
    helpers.setValue(value ?? '');
  };
  
  return <Switch
    {...field}
    checked={Boolean(field?.value)}
    {...props}
    disabled={props.disabled || isLoading}
    checkedChildren={<CheckOutlined />}
    unCheckedChildren={<CloseOutlined />}
    onChange={handleChange}
  />;
};

export const Field = (props) => {
  return (
    <Dropdown {...props} />
  )
}

export {
  Input as TextInput,
  Dropdown as Autocomplete,
};
