import { Spin } from 'antd';
import { McHeading } from '../../components/custom/text';
 
// eslint-disable-next-line import/no-anonymous-default-export
export default () => {
    /* TODO: traslation for authorizing ( loading ) */
    
    return (
        <>
            <McHeading level={2}>Authorizing</McHeading>
            <Spin size="40px" />
        </>
    );
}