import useCurrency from '../../hooks/useCurrency';
import { Spin, Table } from 'antd';
import { Text } from '../custom/text';
import { MdOutlineDone } from 'react-icons/md';

export const SalesTable = ({ isLoading, heading, mappingType, isJeLayout, ...props }) => {
  const { data } = props;
  const { toCurrency } = useCurrency();

  let columns = [
    {
      title: "Name",
      dataIndex: "account",
      key: "account"
    },
    {
      title: (mappingType === "item") ? 'Product' : 'Category',
      dataIndex: "name",
      key: "name"
    },
  ]
  
  const jeLayout  = [
    {
      title: "Debits",
      dataIndex: "debits",
      key: "debits",
      render: (_, item) => toCurrency(item.debits)
    },
    {
      title: "Credits",
      dataIndex: "credits",
      key: "credits"
    },
    {
      title: "Tracking",
      dataIndex: "tracking",
      key: "tracking"
    },
  ]
  
  const noJeLayout = [
    {
      title: "Unit price",
      dataIndex: "unitPrice",
      key: "unitPrice",
      render: (_, item) => toCurrency(item.unitPrice)
    },
    {
      title: "Quantity",
      dataIndex: "quantity",
      key: "quantity"
    },
    {
      title: "Amount",
      dataIndex: "amount",
      key: "amount",
      render: (_, item) => toCurrency(item.amount)
    },
    {
      title: "Tax",
      dataIndex: "taxEnabled",
      key: "taxEnabled",
      render: (_, item) => Boolean(item.taxEnabled) ? <MdOutlineDone /> : ""
    },
    {
      title: "Location / Class tracking",
      dataIndex: "class",
      key: "class"
    },
  ]

  if (isJeLayout) {
    columns = [...columns, ...jeLayout]
  } else {
    columns = [...columns, ...noJeLayout]
  }

  return <>
    {
      <Spin spinning={!isLoading}>
        {data !== null && <Table style={{ marginTop: "20px" }} caption={
          <Text fontWeight='bolder' fontSize='large'>{heading}</Text>
        } columns={columns} dataSource={data} pagination={false} />}
      </Spin>
    }
  </>;
};
