import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import _, { random } from 'lodash';
import { useState } from 'react';
import { useEffect } from 'react';
import useAccounts from '../../../hooks/useAccounts';
import { useGetCategoriesQuery } from '../../../services/dashboardService';
import { FullPageWrapper } from '../../custom/IndividualMappingLayout';
import amIVisible from '../../../componentControl/registry';
import radioOptions from "../../mapSales/productLevelOptions.json"

export const IndividualPoProductLevelMapping = ({ formik }) => {

    const { t } = useTranslation()

    const [isLoadingAccounts, inventoryAccounts] = useAccounts('inventory');

    const [, cogsAccounts] = useAccounts('cogs');

    let listOfAccounts = inventoryAccounts

    const [, inventoryCogs] = useAccounts('inventoryCogs');

    if (amIVisible("show expense account in inventory")) {
      listOfAccounts = inventoryCogs
    }
  
    const [key] = useState(random())
  
    const { currentSw, itemsLevelLabels } = useSelector(state => state.dashboard)

    const { poItemsLevel, swapSuperParentCategoryFromProducts, isSentPurchaseOrder, trackInventory, isSentCogs, poItemsLevelMapping } = formik.values;

    const [parentCategory, setCategory] = useState(swapSuperParentCategoryFromProducts);

    const [isCategoryChanged, setChanged] = useState(false);

    const isDynamicMapping = amIVisible("enter field for product and categories individual")

    const [isEmptyLabels, setIfEmptyLabels] = useState(false)

    const { isLoading, isFetching, refetch } = useGetCategoriesQuery({
        recordItems: false,
        productLevel: true,
        superParentCategory: Number(swapSuperParentCategoryFromProducts),
        key
    }, {
        refetchOnMountOrArgChange: false,
        selectFromResult: ({ data, ...rest }) => {
            let labels = data?.map((item) => {
                return {
                    label: item.name,
                    value: item.id
                }
            })

            labels = _.filter(labels, item => {
                return !_.keys(_.pickBy(poItemsLevel, (items) => items !== '')).includes(item?.value + '')
            })

            return {
                data: labels,
                ...rest
            }
        }
    })

    useEffect(() => {
        setIfEmptyLabels(itemsLevelLabels?.length === 0)
    }, [itemsLevelLabels])

    useEffect(() => {

        const labels = itemsLevelLabels ?? []

        if (labels?.length === 0 && !isEmptyLabels) {
            refetch()
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [itemsLevelLabels])

    useEffect(() => {
        if (isCategoryChanged) {
            setChanged(false)
            refetch()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isCategoryChanged])

    const refresh = (value) => {
        setCategory(value)
        setChanged(true)
    }

    let extraProps = {
        column2: "COGS",
        cogsAccounts
    }

    if (isSentPurchaseOrder && !trackInventory && !isSentCogs) {
        extraProps = {}
    }

    return (
        <>
            <FullPageWrapper
                title={t('Map COGS and Inventory By Categories')}
                fullPageDesp={t(`Individual Type/sub-type can be mapped to unique accounts in ${currentSw}. All unassigned and new Type/sub-type will be assigned to the default category account.`, { swName: currentSw })}
                formik={formik}
                formikFieldName={"poItemsLevelMapping"}
                isLoading={isLoading || isFetching}
                isAccountsLoading={isLoadingAccounts}
                heading={"Type/sub-type"}
                column1="Inventory"
                isDynamicMapping={isDynamicMapping}
                parentCategory={parentCategory}
                options={listOfAccounts}
                labelOption={itemsLevelLabels}
                radioOptions={radioOptions}
                refetch={refresh}
                advanceMapping
                isPo
                {...extraProps}
                rowLoaderProps={{
                    box: true,
                    rows: 10
                }}
            />
        </>
    )
}